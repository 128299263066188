import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const YoudGuimelDaleth = () => {

    useEffect(() => {
        document.title = 'Youd Guimel - Youd Daleth | Tsahal Connection';
      }, []);
      
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Services</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Youd Guimel - Youd Daleth</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '4rem', fontWeight: '500', color: '#2f3036'}}>
                Le programme Atouda Technologique (Youd Gimel - Youd Daleth) est un parcours qui permet aux diplômés du baccalauréat de poursuivre des études technologiques jusqu’à 2 ans afin d’obtenir un diplôme de technicien (un an) ou technicien supérieur (deux ans). Par la suite le participant au programme s’engage à l’armée dans son domaine d’étude et prolonge son service. Ce programme est accessible uniquement aux lycéens israéliens qui ont choisi « nativ technologique » et qui ont obtenu un diplôme technologique. Pour les nouveaux immigrants ayant un baccalauréat étranger, ils ont la possibilité de rejoindre de participer à ce parcours via <Link to='/atouda-olim-akademisatzia' style={{color: '#1398aa'}}>Atouda Olim</Link> (selon les besoins de Tsahal).
                Les participants aux programmes peuvent étudier de nombreux domaines comme : électronique, mécanique, architecture ainsi que d’autres études reconnues par le ministère de l’Éducation en partenariat avec Tsahal.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                   Les conditions d'admission
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '2rem'}}>
                    Le programme exige aux participants un baccalauréat complet ou partiel. Cependant il exige 7 « unités (yehidot) dans la matière technologique choisie, car c’est cette matière que le candidat continuera d’étudier dans le cadre du programme. Le candidat qui possède un baccalauréat partiel est dans l’obligation d’avoir eu des résultats positifs (au minimum 55) dans les matières suivantes : mathématiques (3 unités), anglais (3 unités) et hébreu (1 unité). Très souvent, il est possible de rejoindre ce parcours même sans 7 yehidot dans une manière technologique.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Diplôme de fin d’études
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    À la fin de la première année du programme, les candidats recevront un diplôme de technicien et ceux qui poursuivront la deuxième année recevront un diplôme en tant que techniciens supérieurs. Ces études et ces diplômes sont reconnus par le ministère de l’Education.
                    Les candidats méritants ayant terminés l’année scolaire de youd daleth (2ème année) avec de bonnes notes, peuvent éventuellement demander à continuer des études académiques dans le cadre du programme <Link to='/atouda-akademit-atidim' style={{color: '#1398aa'}}>Atouda Akademit</Link>. 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Service militaire
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    L’enrôlement se déroule en juin ou en septembre, en fonction des études effectuées et des besoins de l’armée. Les nouveaux diplômés devront s’engager dans l’année suivant l’obtention de leurs diplômes. L’affectation s’effectue en fonction des demandes du candidat et des besoins de l’armée. Les candidats ont différentes options : Marine, corps des renseignements, corps T.I.C, corps technologique et de maintenance, système technique de l’armée de l’air, etc.<br />
                    Les techniciens (un an d’études supplémentaires) devront signer pour 9 mois supplémentaires tandis que les techniciens supérieurs devront signer pour une période de 15 mois supplémentaire à l’armée (à la fin du service obligatoire), sans aucune différence entre les filles et les garçons peu importe le domaine étudié ou l’affectation reçue. Il arrive fréquemment que lors de l’affectation, l’unité décide d’annuler la signature des 9 ou 15 mois de service supplémentaire (selon les besoins de l’unité). 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Inscription
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                L’inscription au programme s’effectue auprès des différentes écoles technologiques. Lors de la classe de terminale, les étudiants peuvent s’inscrire à l’aide d’un formulaire à remplir dès le mois de janvier.  La liste des écoles disponible pour s’inscrire à ce parcours est disponible sur le site du ministère de l’éducation et se met à jour chaque année.<br />
                Après avoir reçu l’autorisation pour participer au programme et avoir payé les frais d’études, le candidat doit vérifier auprès de l’unité « Meitav » (incorporation des civils à l’armée) que le formulaire d’inscription a bien été enregistré dans le système de l’armée afin de pouvoir repousser l’enrôlement jusqu’à la fin du programme.
                Pour les participants qui souhaitent effectuer la deuxième année d’études afin de devenir technicien supérieur, cette demande s’effectue lors de la première année d’études. Il est nécessaire de demander un nouveau report aux alentours du mois de décembre.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Signature du contrat
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Entre les mois de mai et août de l’année de terminale, les candidats seront convoqués aux différents centres d’incorporation afin de signer un contrat avec l’armée dans lequel seront inscrit : 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    ✔️ Durée du report du service obligatoire.<br />
                    ✔️ Affectation dans un des différents corps de l’armée.<br />
                    ✔️ Signature pour un service supplémentaire de 9 à 15 mois en fonction du nombre d’études.<br />
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                Un candidat qui ne signe pas ce contrat ne sera pas autorisé à participer au programme et devra s’engager dans les semaines suivantes en fonction des besoins de l’armée. Gardez en tête que lors de votre affectation, l’unité peut vous annuler la durée de service supplémentaire.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Sécurité sociale
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                Les participants de ce programme ne doivent pas payer la sécurité sociale jusqu’à leurs enrôlements ou jusqu’à l’âge de 21 ans.  
                À la veille des études, les étudiants recevront un formulaire qui certifie l’autorisation des études au programme. Ce formulaire est à présenter à la sécurité sociale afin d’être exempté de tous frais pendant la durée du programme
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Arrêt des études
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                Si un participant décide d’interrompre ses études (pour diverses raisons), il devra transmettre sa décision au centre Atouda ou à un des centres d’incorporation de l’armée, dans les 48 heures qui suivent à l’aide d’une lettre personnelle ou d’un appel téléphonique. Il devra également par la suite joindre un justificatif d’arrêt d’études. Après cela l’étudiant recevra une date d’enrôlement quelques semaines plus tard.
                </Typography>
                <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#EBEAFF', borderColor: '#EBEAFF', marginBottom: '3rem'}}>
                            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', color: '#2f3036', marginBottom: '1rem', fontWeight: '600', padding: '20px' }}>
                            ⚠️ Une demande envoyée à partir du mois de février n’entraine pas l’annulation au service supplémentaire qui a été signé lors du contrat.<br />
                            ⚠️ Tsahal se réserve le droit d’arrêter les études du participant et de l’enrôler dans ses rangs.
                            </Typography> 
                </div>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Marom Technologique
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Le programme Marom Technologique est un parcours spécial dans Atouda Technologique qui permet aux participants d’être affectés à un poste technologique en rapport avec le domaine d’étude. Ce parcours correspond aux étudiants des branches :
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    <ul>
                        <li>Électronique.</li>
                        <li>Mécatronique.</li>
                        <li>Génie civil.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                Lors de leurs études, les participants au programme sont accompagnés par un représentant du corps de l’armée dans lequel ils vont s’engager. Son but est de renseigner et d’aider le candidat durant les études.
                Les participants aux programmes sont dans l’obligation de signer pour 18 mois supplémentaires (uniquement si les commandants en éprouvent le besoin).
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Les études sont financées à 80% par les corps de l’armée suivant :  
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    <ul>
                        <li><strong>Armée de l’air -</strong> au lendemain des études, les candidats sont affectés dans la branche technique de l’armée de l’air.</li>
                        <li><strong>Marine -</strong> possibilité de servir en tant que combattant/te “hod” dans la marine.</li>
                        <li><strong>Corps des renseignements -</strong> nécessite 4 unités au baccalauréat d’anglais et de mathématique. Il faut aussi avoir un profil de 25 au minimum et 50 de Dapar. Les participants obtiennent un diplôme de technicien supérieur en électronique (2 ans d’études). Les candidats signent donc pour un an et demi de service supplémentaire. Ils auront des formations civiles de HP, Microsoft, John Bryce et d’autres militaires comme Net PowerShell ou Linux. Dans ce parcours, l’accent est sur l’électronique et la communication.</li>
                        <li><strong>Corps T.I.C</strong></li>
                        <li><strong>Corps technologique et maintenance</strong></li>
                        <li><strong>Corps logistique</strong></li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                Il existe deux sessions d’inscriptions :
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                1️⃣ Jusqu’au mois de mars pour ceux dont l’entrée à l’armée est prévue jusqu’au mois de décembre.<br />
                2️⃣ Jusqu’au mois de juin pour ceux dont l’entrée à l’armée est prévue jusqu’au mois d’avril.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                Pour s’inscrire à ce programme, il faut généralement contacter l’armée. Il est possible aussi que les candidats qui correspondent aux profils reçoivent une convocation directe de la part de la Marine, des renseignements ou de l’armée de l’air afin d’assister à une journée d’information. La liste des écoles disponible pour s’inscrire à ce parcours est disponible sur le site du ministère de l’éducation et se met à jour chaque année. 
                Au lendemain des études, les participants aux programmes s’engagent dans le corps de l’armée qui a participé au financement de leurs études.
                De plus, les participants qui souhaitent devenir officiers ne sont pas obligés de signer pour une période supplémentaire aux 18 mois déjà présents. 
                Dans certains cas, les participants sont dans l’obligation d’effectuer une formation en physique, généralement à la veille des études (courant août).
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Rakia
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Le parcours Rakia est un programme qui permet aux jeunes filles d’effectuer des études technologiques et d’obtenir un diplôme de technicienne supérieure (reconnu par le ministère de l’Éducation). Les domaines étudiés sont : 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    <ul>
                        <li>Électrotechnique.</li>
                        <li>Électronique.</li>
                        <li>Mécanique.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                Ces domaines peuvent être étudiés dans 3 écoles : École nationale des techniciens supérieurs au Technion (Haïfa), Campus d’ingénierie et de technologie (Tel Aviv), École académique d’Ashkelon.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                Le coût des études est financé par Tsahal (excepté les frais d’inscription ainsi que les frais d’examens extérieurs). Le diplôme de technicien supérieur est délivré après la réussite des examens de fin d’études et après la remise d’un projet. Pour donner suite à l’obtention du diplôme, les jeunes filles s’engagent à prolonger leurs services de 18 mois, et recevront une affectation en fonction du domaine étudié.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Les filles qui souhaitent intégrer ce programme doivent répondre aux conditions suivantes : 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    <ul>
                        <li>Être diplômé du baccalauréat.</li>
                        <li>Avoir réussi le test d’entrée dans une des différentes écoles.</li>
                        <li>Les filles qui correspondent à ce parcours recevront cette option lors du formulaire de choix des postes sur le site internet de l’armée.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    La durée des études est de 18 mois, avec un report du service militaire au début de chaque année scolaire.
                    Au lendemain de l’enrôlement, les soldates devront effectuer des formations supplémentaires entre 3 et 4 mois, en rapport avec le diplôme technologique obtenu. 
                    Les soldates peuvent être affectées dans des unités en charge du développement et de la maintenance de système technologiques modernes et avancés dans les milieux informatiques variés (forces terrestres ou armée de l’air). 
                    Les soldates correspondantes auront la possibilité d’intégrer la formation d’officier ou de postuler pour différents postes d’instructrices.<br />
                    Les soldates d’excellences pourront continuer leurs études dans le cadre du programme  <Link to='/atouda-akademit-atidim' style={{color: '#1398aa'}}>Atouda Akademit</Link> afin d’obtenir un diplôme d’ingénieur. 
                </Typography>
            </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
  };
  
  export default YoudGuimelDaleth;
  