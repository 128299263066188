import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS  
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const Mechinot = () => {

    

    useEffect(() => {
        document.title = 'Mechinot | Tsahal Connection';
    }, []);
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Annexes</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Mechinot</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '3rem', fontWeight: '500', color: '#2f3036'}}>
                        Les préparations militaires (Mechinot Kdam Tsvaïot) existent depuis les années 1950 et ont pour but de préparer les jeunes israéliens à un service militaire de qualité, en leur transmettant valeurs, autonomie, amour du pays et en les préparant physiquement et mentalement aux difficultés du service militaire et de la vie en générale.
                        Ces programmes se sont améliorés avec le temps et il existe de tout type de préparation militaire : non religieux, mixte, religieux, ultra-orthodoxe.
                        Ces dernières années de nombreux programmes axent leurs programmes d’études sur le leadership et le sionisme actuel. Il existe des Mechinot de 6 mois (programme OFEK) et des Mechinot annuelles (religieuses et sionistes).
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        Le processus d'inscription
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        L’année de préparation militaire fonctionne sur un report du service militaire. Il faut donc avoir une première date d’enrôlement pour participer à ces programmes, exception faite pour les nouveaux immigrants arrivants n’ayant pas encore de date d’enrôlement. De plus il est recommandé d’avoir un bon niveau d’hébreu pour être accepté dans ces programmes. 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        Généralement toutes les Mechinot commencent en août et la plupart d’entre elles nécessitent une voir plusieurs journées de tests (en groupe et en individuel) entre novembre et février. Les nouveaux immigrants ont la possibilité de s’inscrire plus tard, car ils sont considérés comme une population spéciale vis-à-vis de l’armée. Cependant plus l’inscription s’effectue tard, moins il y a de place disponible. 
                        L’inscription se fait donc directement auprès des Mechinot. Après avoir passé les tests entre novembre et février, les directeurs des Mechinot vont envoyer les listes des participants à la branche sociale-sécurité du ministère de la Défense (Agaf Hevrati Bithoni). Ceux-ci vont ensuite faire le lien avec l’unité Meitav (en charge des nouvelles recrues) de Tsahal afin d’avoir une autorisation de report du service.           
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        Il existe deux cas de figure pour s’enrôler dans les Mechinot pour les nouveaux immigrants :
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                        <ul>
                            <li><strong>Avoir fait la aliyah tôt (avant ses 18 ans) et recevoir une date d’enrôlement après le baccalauréat :</strong> le processus est le même que les Israéliens et les candidats doivent idéalement faire les tests pour la Mechina entre novembre et février. Par la suite ils recevront une autorisation et pourront commencer la Mechina (OFEK ou annuelle) en août.</li>
                        </ul>
                        <ul>
                            <li><strong>Avoir effectué la aliyah après ses 18 ans et avoir un baccalauréat étranger :</strong> un nouvel immigrant qui a déjà une date d’enrôlement devra s’inscrire à une Mechina comme les Israéliens et valider les tests (entre novembre et février). Un nouvel immigrant a droit à une année d’intégration par Tsahal dans laquelle le candidat ne recevra pas de convocation et donc n’a pas de date d’enrôlement. Il peut effectuer les tests pour une Mechina pendant cette période à condition que le timing corresponde. </li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        La listes des Mechinot
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        L’équipe de Tsahal Connection à préparer une liste exceptionnelle de toutes les Mechinot en fonction des différentes catégories.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🔵 <Link to="https://1drv.ms/b/s!AgVanePzBHI9gaIy1U0dgYkNNmCp2w?e=qFwNmc" style={{color: '#1398aa'}} target="_blank">Liste Mechinot OFEK-6 mois (Août à février)</Link><br />
                    🔵 <Link to="https://1drv.ms/b/s!AgVanePzBHI9gppdlJPaUizYtONEMw?e=T43mw0" style={{color: '#1398aa'}} target="_blank">Liste Mechinot annuelles générales (Août à juin-Juillet)</Link><br />
                    🔵 <Link to="https://1drv.ms/b/s!AgVanePzBHI9gppnLlyYWubRXJlIgQ?e=pBHz7N" style={{color: '#1398aa'}} target="_blank">Liste Mechinot religieuses (filles <strong>OU</strong> garçons)</Link><br />
                    </Typography>
                </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
};

export default Mechinot;
