import React, { useContext, useEffect, useState } from "react";
import { UidContext } from "./../../components/Log/AppContext";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import { Box, CircularProgress, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper } from '@mui/material';

const TesResulats = () => {
  const uid = useContext(UidContext);
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/api/user/getuserbyid/${uid}`);
        setUser(response.data.user);
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    };

    if (uid) {
      fetchUser();
    }
  }, [uid]);

  if (loading) {
    if (!uid) {
      navigate('/signup');
    }
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '100vh', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleRowClick = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };

  return (
    <Container>
      <Typography variant="h1">Tes résultats</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Results</TableCell>
              <TableCell>Ratio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user.FollowingInstruction2.map((instruction, index) => (
              <React.Fragment key={index}>
                <TableRow onClick={() => handleRowClick(index)} style={{ cursor: 'pointer' }}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <CircularProgress
                      variant="determinate"
                      size={30}
                      thickness={5}
                      value={instruction.ratio * 100}
                      style={{ color: instruction.ratio >= 0.5 ? 'green' : 'red', border: '2px ' }}
                    />
                  </TableCell>
                </TableRow>
                {selectedRow === index && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="h5">Results:</Typography>
                      {Object.keys(instruction.results).map((key) => (
                        <Typography key={key}>
                          {key}: {instruction.results[key].correct ? 'Correct' : 'Incorrect'} - Choice: {instruction.results[key].choice}
                        </Typography>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default TesResulats;
