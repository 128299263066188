import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS  
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const Tironout = () => {

    

    useEffect(() => {
        document.title = 'Tirount | Tsahal Connection';
    }, []);
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Annexes</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Tironout & Qualification Militaire</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '3rem', fontWeight: '500', color: '#2f3036'}}>
                    Les Tironout (classes) représentent la période qui consiste à transformer un civil en soldat.
                    Chaque recrue est dans l’obligation de faire ses classes, en fonction de l’affectation reçue (combattant, soutiens aux combats, non-combattant).<br /> 
                    Les classes se composent de cours de premiers secours, d’éthique, d’histoire d’Israël, d’un théorique et manuel sur les armes, de l’apprentissage de la discipline, de gardes : le tout dans le but de responsabiliser la recrue et lui faire comprendre l’importance et la responsabilité que possède un soldat dans les rangs de Tsahal.<br />
                    Il ne faut pas confondre les tironout avec les qualifications militaires (Rovaï).
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        Le niveaux des classes
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                        Il existe cinq niveaux des classes:
                        <ul>
                        <li><strong>Tirounout 01 -</strong> Il s’agit d’un entraînement de base de courte durée (entre deux semaines et 1 mois) conçu pour les filles majoritairement.</li>
                        <li><strong>Tirounout 02 -</strong> Entraînement de base de courte durée également (deux semaines à deux mois), destiné à l’ensemble des soldats et soldates amenés à servir dans des positions non combattantes. Il s’agit de la formation que les soldats effectuent dans la base de <Link to='/michve-alon' style={{color: '#1398aa'}}>Michve Alon</Link>.  </li>
                        <li><strong>Tirounout 03 -</strong> Cet entraînement de base de deux mois s’adresse à des recrues amenées à servir dans certains rôles combattants. C’est le cas des soldats du corps blindé mécanisé, de l’artillerie ainsi qu’au corps en charge de la collecte de renseignements. </li>
                        <li><strong>Tirounout 04 -</strong> N’existent pas officiellement. Il s’agit des Tironout 03 avancées pour une durée de 2 à 4 mois.</li>
                        <li><strong>Tirounout 05 -</strong> Il s’agit des classes les plus longues (environ 4 mois) et les plus éprouvantes. Seuls les soldats du corps d’infanterie et du génie militaire sont amenés à suivre cette formation. Elle inclut des cours communs à toutes les unités, des cours de premiers soins, de longues marches militaires, l’apprentissage des techniques de survie sur le terrain, des bases du combat de jour comme de nuit, des cours de Krav Maga ainsi qu’un apprentissage aux combats sur les différents terrains possibles.</li>
                    </ul>
                    </Typography>
                    <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#EBEAFF', borderColor: '#EBEAFF', marginBottom: '3rem'}}>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', color: '#2f3036', marginBottom: '1rem', fontWeight: '600', padding: '20px' }}>
                        ⚠️ Les soldats effectuant des Tironout 02 dans la base de Michve Alon et recevant par la suite une affectation dans une unité combattante seront dans l’obligation d’effectuer à nouveau les Tironout 05. Seuls les soldats recevant une affectation nécessitant des Tironout 02 seront exemptés de ceux-ci.
                        </Typography> 
                    </div>

                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        Qualification militaire (Rovaï)
                    </Typography>
                     <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                     Le niveau de qualification militaire représente le niveau de qualification militaire en tant que fantassin. Il existe plusieurs niveaux de Rovai :
                     <ul>
                        <li><strong>Rovaï 00 -</strong> Niveau de qualification pour les soldats se portant volontaire et ayant obtenu une dispense militaire pour raisons médicales ou mentales (non combattant).</li>
                        <li><strong>Rovaï 02 -</strong> Niveau de qualification pour les non-combattants. Obtenir ce niveau de qualification peut prendre entre 3 à 5 semaines. De plus pour certaines unités, il est possible que cela prenne jusqu’à 2 mois. </li>
                        <li><strong>Rovaï 03 -</strong> Premier niveau de qualification pour les combattants. Le temps pour obtenir cette qualification varie entre 3 semaines et 4 mois. Très souvent, ce statut est représenté par le fait d’apprendre à lancer une grenade.</li>
                        <li><strong>Rovaï 05 -</strong> Niveau de qualification pour les combattants ayant terminé 4 mois d’entrainements. </li>
                        <li><strong>Rovaï 07 -</strong> Niveau de qualification pour les combattants après leurs entrainements avancés qui durent généralement 7 mois.</li>
                        <li><strong>Rovaï 08 -</strong> Niveau de qualification pour les combattants d’unités spéciales ou les combattants ayant complété la formation de commandants.</li>
                        <li><strong>Rovaï 10 -</strong> Niveau de qualification pour les sous-officiers combattants ayant effectué une formation spéciale. </li>
                        <li><strong>Rovaï 12 -</strong> Niveau de qualification pour les soldats ayant complété la formation d’officier combattant.</li>
                    </ul>


                    </Typography>
                </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
};

export default Tironout;
