import React, { useState } from 'react';
import axios from 'axios';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router
import { Checkbox, Typography, TextField, Button, Link } from '@mui/material';
import logo from './../../images/Logo Tsahal Conection.png';

const RegisterForm = () => {
  const navigate = useNavigate(); // Initialize navigate from React Router
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);
  const [otpauthUrl, setOtpauthUrl] = useState(''); // State to hold the OTP URI
  const [showQRCode, setShowQRCode] = useState(false); // State to conditionally render QR code



  const handleSignUp = async () => {
    if (isCheckboxChecked === false) {
        alert("Vous devez accepter les conditions générale d'utilisation!")
        return;
    }
    try {
      const response = await axios.post('http://localhost:5000/api/user/createuser', {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
      });

      // Handle successful registration response here, e.g., redirect to a success page.
      console.log(response.data); // This will log the user's ID in the console.
      setOtpauthUrl(response.data.otpauth_url); // Set the OTP URI in state
      setShowQRCode(true); // Show the QR code

      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
      setCheckboxChecked(false); // Reset the checkbox state
      setError(''); // Clear any previous errors

    } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            setError(error.response.data.error);
          } else {
            setError('Une erreur s\'est produite lors du traitement de votre demande.');
          }
        } else {
          setError('Une erreur s\'est produite lors du traitement de votre demande.');
        }
      }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError(''); // Clear the error when the email is changed
      };

  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'flex-end' }}>
      <div style={{ maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '100px' }}>
          <img src={logo} style={{ height: '70px', width: '70px' }} alt="Logo" />
          <Typography style={{ marginLeft: '1.5rem', fontFamily: 'Archivo', fontSize: '24px' }}>
            Tsahal Connection
          </Typography>
        </div>
        <Typography gutterBottom style={{ fontFamily: 'Public Sans', fontWeight: '500', fontSize: '1.25rem', marginTop: '1.5rem', color: 'rgba(50, 71, 92, 0.87)' }}>
          L'aventure commence ici 🚀
        </Typography>
        <Typography gutterBottom style={{ fontFamily: 'Public Sans', fontWeight: '400', fontSize: '1rem', marginTop: '1rem', color: 'rgba(50, 71, 92, 0.6' }}>
          Facilitez votre incorporation à l'armée.
        </Typography>
        {showQRCode ? (
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography gutterBottom style={{ fontFamily: 'Public Sans', fontWeight: '500', fontSize: '1.25rem', marginTop: '1.5rem', color: 'rgba(50, 71, 92, 0.87)' }}>
            Veuillez scanner le QR code via une application de double authentification.
            </Typography>
            <QRCode style={{marginTop: '2rem'}} value={otpauthUrl} />
            <Typography gutterBottom style={{ fontFamily: 'Public Sans', fontWeight: '700', fontSize: '1.5rem', marginTop: '3rem', color: 'rgba(50, 71, 92, 0.87)' }}>
            ⚠️ Attention ! Ce QR Code ne pourra plus être accédé une nouvelle fois.
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '3rem' }}>
            <Link href="/login" style={{ fontSize: '1.5rem', fontFamily: 'Public Sans', fontWeight: '600', color: '#696cff', textDecoration: 'none' }}>
              <Button style={{
                  backgroundColor: 'rgb(105, 108, 255)', 
                  color : 'white',
                  fontFamily: 'Public Sans', 
                  height: '2.4rem', 
                  borderRadius: '9px',
                  
                  }}
                  >
                  Connectez-vous
              </Button>
            </Link>
          </div>
          </div> 
        )
        :
        (
        <div>
        <form style={{ marginTop: '1rem' }}>
          <TextField
            color="primary"
            label="Prénom"
            style={{ marginBottom: '1rem', width: '100%' }}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            color="primary"
            label="Nom"
            style={{ marginBottom: '1rem', width: '100%'}}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}

          />
          <TextField
            color="primary"
            label="Email"
            style={{ marginBottom: '1rem', width: '100%' }}
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            label="Mot de passe"
            type="password"
            style={{ marginBottom: '1rem', width: '100%' }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Typography style={{ color: 'red' }}>{error}</Typography>}
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <Checkbox required color="primary"  onChange={() => setCheckboxChecked(!isCheckboxChecked)}/>
            <Typography variant="body2" style={{ fontFamily: 'Public Sans', fontWeight: '400', color: 'rgba(50, 71, 92, 0.6' }}>J'accepte les</Typography>
            <Link href="/create-account" style={{ fontSize: '0.9rem', fontFamily: 'Public Sans', fontWeight: '400', color: '#696cff', textDecoration: 'none', marginLeft: '4px' }}>
              conditions générales d'utilisation.
            </Link>
          </div>
          <Button
            variant="contained"
            color="primary"
            style={{
              fontFamily: 'Public Sans',
              fontWeight: '600',
              height: '2.4rem',
              width: '100%',
              backgroundColor: 'rgb(105, 108, 255)',
              borderRadius: '9px'
            }}
            onClick={handleSignUp}
          >
            Sign Up
          </Button>
        
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem' }}>
            <Typography style={{ fontSize: '1rem', fontFamily: 'Public Sans', fontWeight: '400', color: 'rgba(50, 71, 92, 0.6' }}>Déjà membre ?</Typography>
            <Link href="/login" style={{ fontSize: '1rem', fontFamily: 'Public Sans', fontWeight: '400', color: '#696cff', textDecoration: 'none' }}>
              Connectez-vous
            </Link>
          </div>
        </form>
        </div>
        )}
      </div>
    </div>
  );
};

export default RegisterForm;
