import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Card, Row, Col } from 'react-bootstrap'; // Import Bootstrap components
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import LogoExpertCo from './../../images/logos/LogoExpertConnection.png';
import LogoInfoCo from './../../images/logos/LogoInfoConnection.png';
import LogoSportConnection from './../../images/logos/LogoSportConnection.png';



const NotreHistoire = () => {

    useEffect(() => {
        document.title = 'Notre Histoire | Tsahal Connection';
      }, []);

    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', textAlign: 'center' }}>Notre Histoire</h1>
                <img src={hr} alt="HR" width="80" height="50" />
            </div>
            <Container maxWidth="md" style={{marginTop: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036'}}>
                    <strong>TSAHAL CONNECTION</strong> est une association fondée en 2015 par d'anciens soldats francophones avec pour objectif de donner les outils nécessaires aux futurs soldats afin que ceux-ci puissent effectuer un service de qualité dans Tsahal et ainsi s'intégrer de la meilleure des façons en Israël. Pour mener à bien ce projet, l'association a centralisé son cahier des charges sur deux axes principaux : les informations et l'accompagnement des futurs soldats.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginTop: '1rem' }}>
                    D’une part il fallait créer une plate-forme en français avec toutes les informations essentielles pour les futurs soldats et d’autre part il fallait aussi donner la possibilité à ces candidats de recevoir un accompagnement de qualité, par des anciens soldats ayant eux-mêmes vécu ces expériences militaires.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginTop: '1rem' }}>
                    Entre novembre 2015 et Mars 2019, ce sont près de 120 conférences qui ont été effectuées par l’association et pas moins de mille francophones qui ont pu recevoir un accompagnement individuel. Tsahal Connection était devenu une référence pour la communauté francophone que ce soit en Israël ou à l’étranger.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginTop: '1rem' }}>
                    Cependant, entre Mars 2019 et juin 2019, l’accompagnement individuel ainsi que la plate-forme en ligne sont mis en pause pour des raisons politiques et financières.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginTop: '1rem' }}>
                    En juillet 2019, l’association Tsahal Connection devient un projet de l’organisation Ach Gadol (grand frère en hébreu). Ses activités se sont élargies afin de permettre d’aider les jeunes francophones à s’engager dans les rangs de Tsahal.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginTop: '1rem' }}>
                    Depuis le 1 juillet 2022, la structure du projet Tsahal Connection change complètement selon le modèle suivant:
                </Typography>
            </Container>
            <Container className="d-flex justify-content-center align-items-center" style={{marginTop: '3rem', marginBottom: '7rem'}}>
      <Row>
        <Col lg={4} style={{marginTop:'2rem'}}>
          <Card className='shadow h-100 mb-3' style={{borderRadius: '20px', borderColor: '#f0f0f0'}}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center">
              <img src={LogoInfoCo} alt="Icon 1" width="200" height="200" style={{marginBottom: '40px', marginTop: '20px'}} /> {/**#610A74 */}
              <Card.Text style={{fontSize: '16px', fontWeight: '500', fontFamily: 'Montserrat', marginRight: '3rem', marginLeft: '3rem', textAlign: 'center', marginBottom: '50px'}}>
              Conférences dans les programmes d’intégration/mairie/école ou séminaire des postes. <br /><strong>Gratuit car subventionné par des donateurs.</strong>              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} style={{marginTop:'2rem'}}>
          <Card className='shadow h-100 mb-3' style={{borderRadius: '20px', borderColor: '#f0f0f0'}}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center">
          <img src={LogoSportConnection} alt="Icon 1"  width="200" height="200" style={{marginBottom: '40px', marginTop: '20px'}} /> {/**#610A74 */}
              <Card.Text style={{fontSize: '16px', fontWeight: '500', fontFamily: 'Montserrat', marginRight: '4rem', marginLeft: '4rem', textAlign: 'center', marginBottom: '50px'}}>
              Activité sportive et simulation des classes militaires. <br /> <strong>Payant à tarif réduit car subventionné en partie par des donateurs.</strong>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} style={{marginTop:'2rem'}}>
          <Card className='shadow h-100 mb-3' style={{borderRadius: '20px', borderColor: '#f0f0f0'}}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center">
              <img src={LogoExpertCo} alt="Icon 1" width="200" height="200" style={{marginBottom: '40px', marginTop: '20px'}} /> {/**#610A74 */}
              <Card.Text style={{fontSize: '16px', fontWeight: '500', fontFamily: 'Montserrat', marginRight: '4rem', marginLeft: '4rem', textAlign: 'center', marginBottom: '50px'}}>
              Acompagnement individuel sur mesure pour les futurs soldats. <br /> <strong>Payant à plein tarif car non subventionné par des donateurs.</strong>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '4rem'}}>
        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginTop: '1rem' }}>
            Le projet est dirigé depuis sa création en 2015 par Raphael Aaron Madar et comprend une quinzaine de volontaires.<br />
            Pour plus d’informations sur <Link to="https://qualita.org.il/" target="_blank" rel="noopener noreferrer">Qualita</Link>.<br />
            Pour plus d’informations sur <Link to="https://www.achgadol.org/fr/home-2/" target="_blank" rel="noopener noreferrer"> Ach Gadol</Link>. 
        </Typography>
    </Container>
    <Footer />    
    </Fade>
    </div>
    );
};

export default NotreHistoire;
