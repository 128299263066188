import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const MiyunLohamot = () => {

    useEffect(() => {
        document.title = 'Miyun Lohamot | Tsahal Connection';
      }, []);
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Convocations</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Miyun Lohamot</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '2rem', fontWeight: '500', color: '#2f3036'}}>
                    Le Miyun Lohamot est une journée pour les filles souhaitant servir dans les unités de combat.
                    Il est possible de faire le Miyun Lohamot soit en ligne via un formulaire à remplir sur le site Mitgaisim soit via une journée entière dans une base militaire. Dans les deux cas, les candidates devront mettre leurs préférences d’unités combattantes.  Il existe de nombreuses unités combattantes accessibles aux filles. La liste est disponible sur le site Mitgaisim (en hébreu) ou via notre plateforme en ligne.<br />
                    Il est possible de renoncer à un poste de combat jusqu’à une semaine après la journée du Miyun Lohamot ou dans les 14 jours suivants l’incorporation dans une unité combattante (pendant la période de préparation). L’affectation finale après l’abandon dépend des besoins de Tsahal uniquement.               
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                   Les conditions pour devenir combattante
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                    Les filles possédant un niveau d’hébreu de 7-8 au Tsav Rishon devront indiquer dans le formulaire des choix d’unités qu’elle souhaite devenir combattante (4 ou 5). Si ce n’est pas fait, il est toujours possible d’effectuer une demande ultérieurement. De plus, elles peuvent recevoir une convocation pour la version physique du Miyun Lohamot. Cependant,si elles ne peuvent pas y participer, elles recevront la version en ligne.<br />
                    Les candidates débutant leurs services par <Link to='/michve-alon' style={{color: '#1398aa'}}>Michve Alon</Link> effectueront le Miyun Lohamot soit en ligne, soit via un officier, soit via la version physique (les filles peuvent être envoyé pour le Miyun Lohamot dans une autre base militaire).
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem',color: '#2f3036' }}>
                    Les candidates au programme <Link to='/mahal' style={{color: '#1398aa'}}>Mahal</Link> étant exemptées de la formation d’hébreu n’auront pas besoin d’effectuer cette journée et feront leurs choix via le coordinateur du programme Mahal.
                    Si les candidates choisissent de servir dans un des postes combattants, il leur sera demandé d’effectuer un service de 32 mois qui est l’équivalent du service militaire des garçons.​
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                La journée du Miyun Lohamot
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                Les candidates effectuant la version physique du Miyun Lohamot auront la possibilité d’échanger avec des combattantes des différentes unités qui pourront partager leurs expériences personnelles sur leur service et expériences, leur formation, ainsi que leur vécu dans l’armée.<br />
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                En outre, avec les autres futures soldates présentes, les candidates assisteront à une explication sur le processus de sélections ainsi que sur leurs devoirs et droits en tant que combattantes. Parfois, des représentantes de certaines unités effectuent des simulations d’exercice pratique (entraînement sportif, ou simulation de tir) afin de faire comprendre au maximum le ressenti d’être combattante. <br />
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                Pendant la journée, les recrues seront soumises à un test IMC (Indice de Masse Corporelle) qui évaluera leurs aptitudes aux postes combattants. À la fin de cette journée, elles recevront par SMS un formulaire dans lequel elles classeront par ordre de préférence, les postes auxquels elles aspirent. L’armée s’efforcera de tenir compte des demandes des candidates, mais en fin de compte, les recrues seront affectées en fonction des besoins de l’armée.<br />
                </Typography>
            </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
  };
  
  export default MiyunLohamot;
  