import React, { useEffect, useState} from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';



const Mahal = () => {

    const [open, setOpen] = useState(true); // State to manage dialog open/close

    //POP UP    
    const handleClose = () => {
        setOpen(false);
    };
    // END POP UP


    useEffect(() => {
        document.title = 'Mahal | Tsahal Connection';
      }, []);
      
    return (
        <div>
            <Navbar />
            <Fade>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Services</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Mahal</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '1rem', fontWeight: '500', color: '#2f3036'}}>
                Mahal est un programme du ministère de la Défense existant depuis 1948. Il permet aux étrangers et aux israéliens n’ayant pas vécu en Israël de s’engager dans Tsahal en tant que volontaire pour une durée de 18 mois. Il existe trois sessions par an (Avril, Août, Décembre) et la présence du participant est requise deux mois avant le début de chaque session pour divers entretiens.<br />
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '18px', marginBottom: '4rem', fontWeight: '500', color: '#2f3036'}}>
                    <span style={{textDecoration: 'underline', fontStyle: 'italic'}}>Touriste</span> - <span style={{fontStyle: 'italic'}}>n’est pas citoyen israélien et aucun de ses parents n’a jamais été citoyen israélien.</span><br />
                    <span style={{textDecoration: 'underline', fontStyle: 'italic'}}>Ben Meager</span> - <span style={{fontStyle: 'italic'}}>enfants de parents israéliens qui sont nés à l’étranger, ou en Israël et qui ont quitté le pays avant ses seize ans. C’est-à-dire qu’un de ses parents à où avait la citoyenneté israélienne et donc il est éligible à recevoir un passeport israélien.</span><br />
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '2rem', fontWeight: '700' }}>
                   Les conditions d'admission
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                1. Touriste ou « Ben Meager » (temps autorisé en Israël) :
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <ul>
                    <li>Touriste <strong>(le décompte s’arrête 45 jours avant la date d’enrôlement prévue) – depuis l’âge de 16 ans :</strong></li>
                    <ul style={{marginBottom: '1rem'}}>
                        <li>Si le candidat n’a pas participé à un programme d’intégration reconnu* il a le droit à 12 mois cumulé en Israël depuis ses 16 ans en prenant en compte qu’il ne doit pas dépasser 120 jours de visite en Israël par année civile et ce, à condition qu’il soit resté à l’étranger plus de 60 jours consécutifs entre les visites en Israël d’une année civile à une autre. ​</li>
                        <li>Si le candidat a participé à un programme d’intégration reconnu*, il a le droit jusqu’à 18 mois cumulé en Israël depuis ses 16 ans. Parmi ces 18 mois, le candidat ne doit pas dépasser 12 mois cumulés en Israël sans cadre reconnu*. De plus, la limite des 120 jours par année civile peut être dérogé une fois uniquement lors de l’année du programme d’intégration. Par exemple un candidat effectuant 6 mois en 2021 et 6 mois en 2022 ne sera pas éligible à Mahal car il aura dépassé deux fois la limite des 120 jours et ce, malgré le fait qu’il n’est pas dépassé 18 mois en cumulé.</li>
                    </ul>
                <span style={{fontWeight: '600'}}>Dans le cas où le candidat n’est pas venu pour plus de 90 jours pendant une année civile ; et sous condition qu’il est resté à l’étranger pendant au moins 60 jours entre une année civile et une autre, alors le décompte de cette année ne sera pas pris en compte.</span>
                </ul>
                <ul>
                    <li>« Ben Méager » <strong>(le décompte s’arrête 45 jours avant la date d’enrôlement prévue) –depuis l’âge de 10 ans :</strong></li>
                    <ul>
                        <li>Le candidat n’ayant participé à aucun programme d’intégration aura le droit jusqu’à 120 jours de visite en Israël par année civile et ce, à condition qu’il soit resté à l’étranger plus de 60 jours consécutifs entre les visites en Israël d’une année civile à une autre.</li>
                        <li>À partir du moment où un candidat n’est pas resté à l’étranger plus de 60 jours consécutifs entre les visites en Israël d’une année civile à une autre OU qu’il a dépassé plus de 120 jours sur une année civile, alors il aura le droit de séjourner en Israël pour une durée de 12 mois à partir de sa date d’arrivée en Israël-sous réserve de justificatif qu’il est dans un programme reconnu (Massa, Yeshiva, études secondaires etc…). Cependant le candidat ne devra pas dépasser sur ces 12 mois plus de 120 jours sans cadre reconnu. Attention, l’armée commence le décompte à partir de la première visite sur l’année civile !</li>
                    </ul>
                    <span style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '15px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>*Un programme reconnu est par exemple- études (lycée, yeshiva), volontariat (préparation à l’armée, etc.) ou programme d’intégration (Massa etc.).</span>
                </ul>
                </Typography>
                <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#EBEAFF', borderColor: '#EBEAFF', marginBottom: '3rem'}}>
                            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', color: '#2f3036', marginBottom: '1rem', fontWeight: '600', padding: '20px' }}>
                            ⚠️ <strong>Attention !</strong> Le fait de quitter le territoire pendant une période de deux mois permet de basculer d’un décompte avec programme a un décompte sans programme. Ainsi un Ben Meager résidant en Israël dans le cadre d’un programme d’intégration entre octobre 2021 et août 2022 sera éligible à Mahal pour la session de décembre 2022 s’il quitte le territoire israélien entre août 2022 à octobre 2022 (durée minimum de 60 jours). De la même façon qu’un Ben Meager ou un touriste qui quitte le territoire pendant plus de deux mois pendant un programme d’intégration, alors lors de sa nouvelle arrivée en Israël, le décompte se fera selon le modèle « sans programme » !

                            </Typography> 
                </div>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '2rem'}}>
                    2. Juif ou Zakai Alyah selon la loi.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                    3.  Âges autorisés :
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <ul>
                    <li>À partir de 18 ans.</li>
                    <li>Jusqu’à 23 ans compris pour les garçons et 20 ans compris pour les filles.</li>
                    <li>Jusqu’à 32 ans compris pour les médecins (avec un diplôme reconnu et certifié par le ministère israélien de la Santé).</li>
                </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                    4. Les parents en charge du participant au programme n’ont pas fait l’alyah avant les 20 ans du candidat <span style={{fontWeight: '500'}}>(c’est-à-dire qu’ils sont restés jusqu’à 180 jours pour chaque année civile avec 60 jours d’écart entre une année civile à une autre). </span>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                    5. Dans le cas où les parents sont divorcés et celui qui n’a pas la charge du candidat habite en Israël et a fait l’alyah avant les 18 ans du candidat, il faudra préparer les papiers suivants par un notaire ou par le consulat israélien (dans le cas où celui-ci accepte). Les documents doivent être rédigés en anglais ou hébreu uniquement : 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <ul>
                    <li>Lettre du notaire certifiant que le candidat est à la charge du parent 1 et qu’il n’est pas en contact avec le parent résidant en Israël (parent 2).</li>
                    <li>Acte de divorce original + traduction en anglais/hébreu signée par un notaire.</li>
                </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '2rem'}}>
                    6. Si les documents sont délivrés originellement en anglais/hébreu par les instances officielles et expliquent ouvertement qui est le parent en charge du candidat ainsi que l’absence de contact entre celui-ci et le second parent, alors il est possible que ces documents suffisent. Dans tous les cas nous conseillons aux candidats de nous envoyer les documents au préalable afin de vérifier directement avec Mahal et Tsahal.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                    7. Le participant s’engage à revenir habiter dans son pays d’origine
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <ul>
                <li>Dans le cas où le candidat touriste effectue son alyah dans les deux années qui suivent son service militaire via Mahal, il devra compléter son service jusqu’à atteindre la durée de <Link to='/service-complet' style={{color: '#1398aa'}}>service complet</Link> en fonction de son âge d’arrivée.</li>
                <li>Dans le cas où le candidat touriste effectue son alyah plus de deux ans après son service militaire via Mahal et que pendant ces deux années il est resté moins de 4 mois en Israël par année civile, alors il sera affecté dans une unité de réservistes en fonction des besoins de Tsahal.</li>
                <li>Les candidats possédant la nationalité israélienne (Ben Meager) n’ont pas le droit de rester sur le territoire israélien plus de 60 jours après la fin de leur service militaire, sous peine de se faire convoquer par Tsahal pour compléter leur service militaire en fonction de leur âge d’arrivée en Israël. De plus, afin d’être exempté d’une convocation de Tsahal pour compléter leur service militaire : ces candidats ne deviendront pas résidents permanents et ne devront pas rester plus de 4 mois en Israël pour les deux années suivant la fin du programme Mahal ou ils feront l’alyah après 26 ans (Tsahal tranchera selon l’âge le plus élevé des deux options).</li>
                </ul>
                </Typography>
                <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#EBEAFF', borderColor: '#EBEAFF', marginBottom: '3rem'}}>
                            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', color: '#2f3036', marginBottom: '1rem', fontWeight: '600', padding: '20px' }}>
                            ❗Les candidats ne respectant pas ces conditions pourront être appelés à compléter leurs services militaires. Le temps de service est calculé en fonction du statut (Ben Meager ou touriste) et surtout leurs âges lors de leurs arrivées en Israël.<br />
                            Jusqu’à aujourd’hui, aucun soldat participant au programme Mahal - qui a décidé de vivre en Israël - n’a complété son service militaire. Nous avons cependant remarqué que d’anciens soldats du programme Mahal ont dû être obligé de servir en tant que réservistes. 
                            </Typography> 
                </div>
                
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '2rem', fontWeight: '700', color: '#2f3036' }}>
                    Les différents parcours
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Il y a trois types de parcours Mahal.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    <ul>
                        <li style={{fontWeight: '700', textDecoration: 'underline'}}>Mahal Nahal</li>
                        <ul>
                            <li><span style={{fontWeight: '600'}}>Parcours Oulpan -</span>  Ce parcours est obligatoire pour tout ce dont le niveau d’hébreu est jugé faible par l’armée lors du <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link>. Il s’agit de trois mois de formation d’hébreu dans la base de <Link to='/michve-alon' style={{color: '#1398aa'}}>Michve Alon</Link>. Après cette formation, le soldat continuera son service dans l’unité qu’il aura reçue. Il est important de prendre note que ces trois mois sont pris en compte dans le service militaire. Ce parcours concerne autant les filles que les garçons.</li>
                            <li><span style={{fontWeight: '600'}}>Parcours général/unité combattante -</span> ce parcours s’adresse aux personnes qui ont un niveau d’hébreu élevé lors du <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link>. Le participant s’engage directement dans une unité qui aura été décidée après un entretien avec un officier de sélection. Ce parcours concerne autant les filles que les garçons.</li>
                        </ul>
                        <li style={{fontWeight: '700', textDecoration: 'underline', marginTop: '1rem'}}>Mahal Haredi</li>
                            <ul>Ce parcours s’applique pour ceux qui sont religieux et qui veulent être affectés dans une unité combattante religieuse. Il y a trois unités de ce type : le bataillon « Tomer » de la brigade Givati, le bataillon 97 (« Netzah Yehuda ») de la brigade Kfir et dernièrement le bataillon « Hetz » de la brigade des parachutistes (à condition de réussir le test d’accès à cette unité). Dans ce parcours la recrue s’engage directement dans l’une de ces unités pour une durée de 18 mois. Pour ceux qui n’ont pas un profil combattant (au minimum 82), il est possible de servir dans d’autres postes avec ce programme, après un entretien avec un officier de sélection.</ul>
                        <li style={{fontWeight: '700', textDecoration: 'underline', marginTop: '1rem'}}>Mahal Hesder </li>
                            <ul>Ce parcours s’applique à ceux qui font partie d’une yeshiva Hesder et qui veulent s’engager avec celle-ci dans au programme. La recrue doit d’abord être approuvée par le Igud Hesder afin de pouvoir s’inscrire dans ce parcours (parcours pour les garçons seulement). Les inscriptions s’effectuent via la Yeshiva. </ul>
                    </ul>

                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '2rem', fontWeight: '700', color: '#2f3036' }}>
                    Inscription et démarches (hormis Mahal Hesder)
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    La première consiste à passer par Mahal via leurs mail <span style={{textDecoration: 'underline'}}>mahal@mod.gov.il</span>. Il faut les obligatoirement les contacter en anglais ou en hébreu.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    La deuxième consiste à passer par nous via notre branche <Link to='/expert-connection' style={{color: '#1398aa'}}>Expert Connection</Link> (moyennant paiement de 499 NIS TTC).
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Notre accompagnement comprend deux entretiens individuels, une préparation aux différents entretiens militaire, une journée de préparation à Mahal et surtout un suivi bienveillant par une équipe passionné pour faciliter votre entrée à l’armée.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Après quelques échanges par mail avec Mahal ou avec un conseiller de Tsahal Connection, les candidats recevront une convocation pour le premier entretien dans les bureaux de Mahal. Cet entretien se fait en Israël (deux mois avant le début de la session choisie). Après cet entretien, les candidats non israéliens recevront une convocation au <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link>. Après avoir effectué cette journée, les candidats devront participer <strong>OBLIGATOIREMENT</strong> à la mechina (préparation pré-armée). Pendant cette période, les candidats effectueront à l’aide de la mechina un visa A2 Mahal. Attention, ce visa ne permet pas de travailler avant ou pendant le service militaire.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    Dans le cas où le candidat à valider la mechina et que tous les entretiens militaires se sont bien déroulés, alors il recevra une autorisation de pouvoir intégrer les rangs de Tsahal.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '2rem', fontWeight: '700', color: '#2f3036' }}>
                    Programme pré-armée (Mechina)
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Avant de commencer le service militaire, le participant au programme doit effectuer une préparation militaire qui est obligatoire. Cette préparation dure entre 3 et 4 semaines et se situe généralement un mois avant la date d’entrée à l’armée. Cette préparation militaire est aux frais du ministère de la Défense israélienne et se déroule dans le centre d’intégration de Carmiel.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Il est impossible de s’engager si cette préparation n’a pas été effectuée ! Si certains participants ont des problèmes de disciplines, ils peuvent donc être renvoyés du programme Mahal.  Cette mechina est obligatoire pour tous les candidats du programme Mahal, y compris les participants des programme pré-armée (Gour Arié, Lévia etc…). 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Le but du programme est de donner tous les outils nécessaires afin de devenir un bon soldat. De nombreux intervenants, officiers, anciens soldats olim viendront raconter leurs parcours et leurs histoires. De nombreux voyages à travers le pays sont organisés, le tout dans une ambiance militaire.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    Grâce à leurs connexions dans la ville de Carmiel, l’équipe directive de la mechina facilite les rendez-vous médicaux dans le cas où un candidat doit compléter des tests supplémentaires après le <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link>. À la fin du programme, chaque participant sera noté par les différents responsables du programme, ces notes seront envoyées à l’armée et celle-ci peut juger selon les notes d’accepter ou de refuser un participant. Il ne faut donc pas négliger le programme et rester sérieux durant l’intégralité du programme.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '2rem', fontWeight: '700', color: '#2f3036' }}>
                    Liste des documents à préparer
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '18px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036', fontStyle: 'italic'}}>
                    Il faut préparer les documents originaux + 2 photocopies de chaque document, y compris les passeports. 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                    1. Pour valider votre dossier médical:
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    <ul>
                        <li><strong><Link style={{color: '#2f3036'}} to="https://onedrive.live.com/?authkey=%21AB9JP46NFbzhzbE&id=3D7204F3E39D5A05%211730&cid=3D7204F3E39D5A05&parId=root&parQt=sharedby&parCid=66C104157FA4FD8E&o=OneUp" target="_blank">Formulaire médical</Link> (à remplir en anglais) -</strong> Ce formulaire peut-être rempli par n'importe quel médecin (Israël ou étranger). Voici un document qui explique comment le remplir</li>
                        <li><strong>Justificatifs d’antécédents médicaux (en anglais) –</strong> dans le cas où des problèmes médicaux sont indiqués sur le formulaire médical, Tsahal demandera des justificatifs médicaux (idéalement en anglais ou hébreu) par les médecins spécialistes en fonction du problème (cardiologue pour problème au cœur, etc.). Tant que le dossier médical n’est pas approuvé par Tsahal, le candidat ne pourra pas commencer la préparation militaire (Mechina) et donc son entrée à l’armée sera fortement compromise.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                    2. Pour l’entretien Mahal :
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    <ul>
                        <li><strong>Touriste</strong></li>
                        <ul>
                            <li>Passeport francophone du candidat valable jusqu’à 30 mois après la session choisie au minimum (il est préférable que le passeport soit valable trois ans).</li>
                            <li>Certificat de judaïcité <strong>ORIGINAL</strong> du candidat (ou certificat reconnu par l’agence juive en Israël <strong>UNIQUEMENT</strong>).</li>
                            <li>Formulaire médical (<Link target='_blank' to="https://drive.google.com/file/d/1-EXaJRB_bGXJwqYDyoZLnkBCdLjhpLa6/view">lien ici</Link>) rempli par le candidat et le médecin traitant (tout médecin peut signer le document, qu’il soit français, israélien ou autre) - ce <Link target='_blank' to="https://drive.google.com/file/d/1-bUWwhHlFrjpw7rGMp3mVIdfE5xNy6oh/view">document</Link> a une durée de validité de trois mois. Voici un document qui explique comment le remplir.</li>
                            <li>Acte de naissance ORIGINAL et apostillé du candidat- cet acte a une durée de validité d’un an à moins d’un changement de statut (décès d’un parent ou divorce par exemple).</li>
                            <li>Casier judiciaire apostillé - ce document à une durée de validité de 6 mois.</li>
                            <li>Formulaire Mahal Touriste (liens en <Link target='_blank' to="https://drive.google.com/file/d/1-FeBwVK0Yux4e9lok1KiIMoJ1iHa3n7N/view">hébreu</Link> ou en <Link target="_blank" to="https://drive.google.com/file/d/1-RxpYdjfpu3t0HXfHRhKMqz7EN61E6Gj/view">anglais</Link>).</li>
                        </ul>
                        <li style={{marginTop: '1rem'}}><strong>Ben Meager (israélien n’ayant pas vécu en Israël):</strong></li>
                        <ul>
                            <li>Passeport francophone du candidat en règle (il est préférable que le passeport soit valable trois ans).</li>
                            <li>Passeport israélien du candidat en règle (il est préférable que le passeport soit valable trois ans).</li>
                            <li>Formulaire médical (<Link target="_blank" to="https://drive.google.com/file/d/1-EXaJRB_bGXJwqYDyoZLnkBCdLjhpLa6/view">lien ici</Link>)​ rempli par le candidat et le médecin traitant (tout médecin peut signer le document, qu’il soit français, israélien ou autre) - ce document a une durée de validité de trois mois. Voici un <Link target="_blank" to="https://drive.google.com/file/d/1-bUWwhHlFrjpw7rGMp3mVIdfE5xNy6oh/view">document</Link> qui explique comment le remplir.</li>
                            <li>Casier judiciaire original et apostillé du candidat (recommandé mais pas obligatoire) - ce document à une durée de validité de 6 mois.</li>
                            <li>Formulaire Mahal Ben Meager (liens en <Link target='_blank' to="https://drive.google.com/file/d/1-SgqkUlrM9b6kS78y8RIvfa1pCQHL_2r/view"></Link>hébreu ou en <Link to="https://drive.google.com/file/d/1-Z-3c2iDRHXLriBduHYTfvM7qj7KR3Hq/view" target="_blank">anglais</Link>)</li>
                        </ul>
                        <li style={{marginTop: '1rem'}}><strong>Zakai Alyah - Le candidat n’est pas juif, mais il a un père ou un grand-parent juif:</strong></li>
                        <ul>
                            <li>Si le grand-père est juif et le père et mère ne sont pas juifs :</li>
                            <ul>
                                <li> Acte de naissance du grand-père.</li>
                                <li>Acte de naissance du père.</li>
                                <li>Certificat de judaïcité du grand père.</li>
                            </ul>
                            <li>Si le père est juif : </li>
                            <ul>
                                <li>Acte de naissance du père.</li>
                                <li>Certificat de judaïcité du père.</li>
                            </ul>
                            <li>Lettre d’un rabbin de la communauté pour prouver la participation aux activités juive et le lien du candidat avec les valeurs juives.</li>
                            <li>Passeport francophone du candidat valable jusqu’à 30 mois après la session choisie au minimum (il est préférable que le passeport soit valable trois ans).</li>
                            <li>Formulaire médical (<Link target="_blank" to="https://drive.google.com/file/d/1-EXaJRB_bGXJwqYDyoZLnkBCdLjhpLa6/view">lien ici</Link>)​ rempli par le candidat et le médecin traitant (tout médecin peut signer le document, qu’il soit français, israélien ou autre) - ce document a une durée de validité de trois mois. Voici un <Link target="_blank" to="https://drive.google.com/file/d/1-bUWwhHlFrjpw7rGMp3mVIdfE5xNy6oh/view">document</Link> qui explique comment le remplir.</li>
                            <li>Casier judiciaire apostillé du candidat - ce document à une durée de validité de 6 mois.</li>
                            <li>Acte de naissance <strong>ORIGINAL</strong> et apostillé du candidat - cet acte a une durée de validité d’un an à moins d’un changement de statut (décès d’un parent ou divorce par exemple).</li>
                            <li>Formulaire Mahal Touriste (liens en <Link target='_blank' to="https://drive.google.com/file/d/1-FeBwVK0Yux4e9lok1KiIMoJ1iHa3n7N/view">hébreu</Link> ou en <Link target="_blank" to="https://drive.google.com/file/d/1-RxpYdjfpu3t0HXfHRhKMqz7EN61E6Gj/view">anglais</Link>).</li>
                        </ul>
                        <li style={{marginTop: '1rem'}}><strong>Le candidat s’est converti (il doit être converti depuis minimum 9 mois):</strong></li>
                        <ul>
                            <li>Passeport francophone du candidat valable jusqu’à 30 mois après la session choisie au minimum (il est préférable que le passeport soit valable trois ans).</li>
                            <li>Acte de naissance <strong>ORIGINAL</strong> et apostillé du candidat - cet acte a une durée de validité d’un an à moins d’un changement de statut (décès d’un parent ou divorce par exemple).</li>
                            <li>Certificat de conversion du candidat (datant de 9 mois minimum).</li>
                            <li>Programme de conversion certifié par le rabbin ou l’organisme en charge de la conversion-exemple.</li>
                            <li>Lettre du candidat qui explique le processus de conversion (où il a étudié, combien d’heures, pendant combien de temps).</li>
                            <li>Lettre certifiant une participation active dans la communauté du candidat depuis la conversion jusqu’à aujourd’hui. Cette lettre doit être délivrée par un organisme juif (rabbin de la communauté, consistoire,) et doit dater de deux mois avant la session choisie.</li>
                            <li>Formulaire médical (<Link target="_blank" to="https://drive.google.com/file/d/1-EXaJRB_bGXJwqYDyoZLnkBCdLjhpLa6/view">lien ici</Link>)​ rempli par le candidat et le médecin traitant (tout médecin peut signer le document, qu’il soit français, israélien ou autre) - ce document a une durée de validité de trois mois. Voici un <Link target="_blank" to="https://drive.google.com/file/d/1-bUWwhHlFrjpw7rGMp3mVIdfE5xNy6oh/view">document</Link> qui explique comment le remplir.</li>
                            <li>Casier judiciaire apostillé du candidat - ce document à une durée de validité de 6 mois.</li>
                            <li>Formulaire Mahal Touriste (liens en <Link target='_blank' to="https://drive.google.com/file/d/1-FeBwVK0Yux4e9lok1KiIMoJ1iHa3n7N/view">hébreu</Link> ou en <Link target="_blank" to="https://drive.google.com/file/d/1-RxpYdjfpu3t0HXfHRhKMqz7EN61E6Gj/view">anglais</Link>).</li>
                        </ul>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                    3. Documents pour prouver que le candidat n’est pas enfant unique (frère/sœur des mêmes parents) – uniquement pour ceux souhaitant servir dans une unité combattante: 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Chaque candidat est automatiquement considéré comme enfant unique. Pour les candidats souhaitant servir dans une unité combattante, il est possible de régulariser ce statut en fournissant les documents selon une des trois options possibles :
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    <ul>
                        <li>Première option :</li>
                        <ul>
                            <li>Acte de naissance du candidat.</li>
                            <li>Acte de naissance du frère/sœur.</li>
                        </ul>
                        <li>Deuxième option :</li>
                        <ul>
                            <li>Acte de naissance du candidat.</li>
                            <li>Acte de naissance du frère/sœur.</li>   
                            <li>Attestation des parents en anglais/hébreu certifiant que le candidat n’est pas enfant unique et que plusieurs enfants sont issues de leurs unions.</li>
                            <li>Attestation du candidat en anglais/hébreu devant un avocat/notaire certifiant qu’il n’est pas enfant unique et qu’il possède des frères/sœurs issues des mêmes parents.</li>                    
                        </ul>
                        <li>Troisième option :</li>
                        <ul>Attestation des parents en anglais/hébreu devant un notaire certifiant que le candidat n’est pas enfant unique et que plusieurs enfants sont issues de leurs unions. </ul>
                    </ul>
                </Typography>
                <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#EBEAFF', borderColor: '#EBEAFF', marginBottom: '3rem'}}>
                            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', color: '#2f3036', marginBottom: '1rem', fontWeight: '600', padding: '20px' }}>
                                Les candidats détenteurs de la nationalité israélienne et ayant des frères/sœurs des mêmes parents inscrits au consulat israélien de leurs pays de résidence seront exemptés de préparer ces documents-là <strong>UNIQUEMENT</strong> si les deux parents sont israéliens. Dans le cas contraire, ces mêmes candidats seront considérés comme enfants uniques et devront prouver par une des options ci-dessus qu’ils ne sont pas enfants uniques.
                            </Typography> 
                </div>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '2rem'}}>
                    4. Document pour enfant unique (pas de frère/sœur des mêmes parents) pour les candidats souhaitant servir en tant que combattant - <span style={{fontSize: '20px', fontWeight: '500'}}>Il devra donc remplir ce document (lien en <Link to="https://drive.google.com/file/d/10SdTEYqWYWGm58CajoDQBhubC0_ZuWx4/view" target="_blank">anglais</Link> ou en <Link to="https://drive.google.com/file/d/10IK8TiucQWw3GZN3CZFnVLfabjGq9ARM/view" target="_blank">hébreu</Link>). Ce formulaire pour enfant unique sert à servir dans des unités combattantes, il doit être signé par un avocat ainsi que par les parents du futur soldat. Il est préférable de signer le formulaire pour enfant unique au consulat israélien en France (via l’avocat qui travaille là-bas). Il est toutefois possible de faire signer ce document par un avocat privé ; cependant il faut prendre en compte que pour des raisons éthiques, beaucoup d’entre eux refusent de signer ce document. Afin de comprendre et savoir comment remplir cette attestation, nous avons mis en place ce <Link to="https://drive.google.com/file/d/10U7VG_vEc6cRQ8cPLLhvSg_DogsFA3-m/view" target="_blank">document</Link>.</span>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '2rem'}}>
                    5. Autorisation de participation à un programme d’intégration en hébreu (dans le cas où le candidat a participé à un programme Massa, Yeshiva, Mechina ou autre) – <span style={{fontSize: '20px', fontWeight: '500'}}>doit-être indiquer sur ce document vos noms complets, vos dates de début et de fin du programme ainsi que vos numéros de passeports si touristes/numéro d’identité israélienne si israélien (numéro à 9 chiffres sur le passeport israélien). Ce document doit être rédigé par les directeurs/ressources humaines des instances en charge de vos programmes en anglais ou hébreu.</span>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '2rem', fontWeight: '700', color: '#2f3036' }}>
                    Coût du programme et assurance
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    L’inscription au programme via Mahal est gratuite cependant moyennant un paiement de 499 NIS, vous recevrez un accompagnement en français et vos démarches seront facilité grâce à notre équipe.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Il faut toutefois noter que tous les frais pour arriver en Israël ainsi que les frais de transport pour les rendez-vous avec Mahal et la préparation militaires seront à votre charge. 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    De plus, les candidats sont assurés pendant la durée de la préparation militaire. Cependant nous conseillons de s’assurer individuellement avant le programme et entre la période de la fin du programme jusqu’à l’enrôlement, car Tsahal peut demander des examens supplémentaires avant ou après la préparation militaire.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Il existe l’assurance classique pour <strong>1,95 dollar/jour</strong> ou l’assurance plus avancée (conseillé) d’Harel Clalit pour <strong>2,40 dollar/jour</strong>. Pour s’inscrire, il faut remplir ce <Link to="https://drive.google.com/file/d/1-DoKM8UBacNO3_7umE-9LpZKF-4_AEul/view" target="_blank">formulaire</Link> et l’envoyer au mail <span style={{textDecoration: 'underline'}}>fax7930@harel-ins.co.il</span> dans les 48h avant la date d’application. Les candidats seront assurés pendant leurs services par l’armée comme tout soldat de Tsahal. Nous recommandons de prendre l’assurance en Israël et nous une assurance internationale.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    La majorité des soldats de Mahal auront un statut et des avantages en tant que <Link to='/hayal-boded' style={{color: '#1398aa'}}>soldat seuls</Link> – tant qu’ils ne sont pas en contact avec leurs parents habitant en Israël OU que ceux-ci habitent en Israël. Cependant, ils ne recevront leurs aides de soldats seuls uniquement le 1er du mois qui suit le début du service militaire. Ainsi, nous conseillons les candidats d’avoir un endroit où loger et d’avoir de l’argent de côté pour subvenir à leurs besoins avant de recevoir les aides de Tsahal.  
                </Typography>
            </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
  };
  
  export default Mahal;
  