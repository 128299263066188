import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import logoExpertCo from './../../images/logos/LogoExpertConnection.png';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import axios from 'axios';

const navItems = [
  { text: 'Accueil', link: '/profile' },
  { text: 'Tes Resultats', link: '/resultats' },
  { text: 'Tsahal Connection', link: '/' },
];

function NavbarLog() {
  const location = useLocation();

  const handleLogout = async () => {
    try {
      // Make a request to logout endpoint
      await axios.get('http://localhost:5000/api/user/logout', { withCredentials: true });

      // Redirect to login page (replace '/login' with your actual login page route)
      window.location = '/login';
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <Navbar expand="lg" className="bg-white fixed-top" style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)' }}>
      <Container>
        <NavLink to='/profile'>
          <img src={logoExpertCo} alt="Logo" height="80" />
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {navItems.map((item, index) => (
              <Nav.Link
                key={index}
                as={NavLink}
                to={item.link}
                isActive={() => location.pathname === item.link}
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: '500',
                  marginRight: '0.6rem',
                  textDecoration: location.pathname === item.link ? 'underline' : 'none',
                  textDecorationColor: location.pathname === item.link ? '#549BFF' : 'none', // Set the color of the underline
                  textDecorationStyle: location.pathname === item.link ? 'solid' : 'none', // Set the style of the underline
                  textDecorationThickness: location.pathname === item.link ? '3px' : 'none', // Set the thickness of the underline
                  textUnderlineOffset:  location.pathname === item.link ? '5px' : 'none',
                  textDecorationLine: location.pathname === item.link ? 'underline' : 'none',
                }}
              >
                {item.text}
              </Nav.Link>
            ))}
            <Nav.Link onClick={handleLogout}>
            <ExitToAppIcon
              style={{
                color: '#549BFF',
                outline: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                },
              }}
            />
          </Nav.Link>
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarLog;

