import React, { useEffect, useState } from 'react';
import Navbar from "../components/Features/Navbar";
import landingPageImage from "../images/Facebook Cover-06.jpg"
import Cards from "../components/Home/Cards";
import ActionsSection from '../components/Home/Actions';
import PartnerCarousel from '../components/Home/PartnersCaroussel';
import Footer from '../components/Home/Footer';

const Home = () => {
    const [imageScale, setImageScale] = useState(1);
    const [marginContainer, setMarginContainer] = useState(0);
    const [padBottom, setPadBottom] = useState(0);
    const [open, setOpen] = useState(true); // State to manage dialog open/close

    useEffect(() => {
        const handleResize = () => {
            const newScale = window.innerWidth < 576 ? 3 : (window.innerWidth <= 768 ? 2 : 1);
            setImageScale(newScale);
            const newMargin = window.innerWidth < 450 ? 14 : (window.innerWidth <= 576 ? 18 : (window.innerWidth <= 768 ? 10 : 2));
            setMarginContainer(newMargin);
            const newPadBottom = window.innerWidth < 450 ? 3 : window.innerWidth < 576 ? 4 : 0;
            setPadBottom(newPadBottom);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        document.title = 'Acceuil | Tsahal Connection';
    }, []);

    const handleClose = () => {
        setOpen(false);
    };


    const imageStyles = {
        width: '100%',
        height: 'auto',
        maxWidth: '100%',
        objectFit: 'cover',
        objectPosition: 'center top',
        transform: `scale(${imageScale})`,
        paddingBottom: `${padBottom}em`
    };

    const containerStyles = {
        paddingTop: `${marginContainer}rem`, // Adjust this to match your Navbar's height
    };

    return (
        <div style={{ overflowX: 'hidden' }}>
            <Navbar />
            <div className="landing-page" style={containerStyles}>
                <img
                    src={landingPageImage}
                    alt="landing-page-pic"
                    style={imageStyles}
                />
            </div>
            <Cards />
            <ActionsSection />
            <PartnerCarousel />
            <Footer />
        </div>
    );
};

export default Home;
