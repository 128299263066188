import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const Haredim = () => {

    useEffect(() => {
        document.title = 'Haredim | Tsahal Connection';
      }, []);
      
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Services</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Haredim</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '4rem', fontWeight: '500', color: '#2f3036'}}>
                    Une personne ayant étudié au minimum deux ans dans un cadre Haredi reconnu par la loi a la possibilité de servir dans un cadre haredi dans les rangs de Tsahal. La procédure de recrutement est la même que pour les soldats non-orthodoxes. La différence est que dans chaque centre de recrutement se trouvent un bureau de la banche Haredi de Tsahal dans laquelle les soldats ultra-orthodoxes peuvent se retrouver.  
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                Procédure de recrutement
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '2rem'}}>
                1️⃣ Annuler le statut de « Torato émounato » permettant de repousser l’entrée à l’armée en tant qu’étudiant dans des yeshivot gvohot.<br />
                2️⃣ <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Rishon</Link><br />
                3️⃣ Régulariser le statut social et potentiellement obtenir des aides dans l’un des centres de recrutement. <br />
                4️⃣ Inscription dans un des parcours ultra-orthodoxes. Pour les nouveaux immigrants venant de l’étranger, il est possible de rejoindre certains parcours religieux académiques (au Machon Lev par exemple) tout comme il est possible de rejoindre les parcours combattants. Cependant tout dépendra des entretiens avec les rabbins de la branche Haredi car ils ont la possibilité de vous refuser s’ils trouvent que vous n’êtes pas assez religieux.<br />
                5️⃣ Placement dans une unité<br />
                6️⃣ Incorporation.<br />
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Parcours Herev - Unités combattantes
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                Parcours unique dans l’armée israélienne dans une des unités combattantes religieuse. Le service militaire effectué dans une unité de combat donnera au combattant les notions de leadership, indépendance, responsabilité et confiance en soi. Le parcours est composé de vingt-quatre mois de service militaire et une partie « Méof » (excellence, futur et développement personnel) pendant huit mois pour des études professionnelles, des études préparatoires à l’ingénierie pratiques ou des études préparatoires à un diplôme universitaire.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Le parcours est destiné aux diplômés des établissements d’enseignement ultra-orthodoxes âgés de 18 ans et plus avec un profil militaire de combattant (97, 82, 72) :
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    <ul>
                        <li><strong>Ceux avec un profil de 82 ou plus -</strong> serviront de combattants dans la piste dans les Brigades Kfir, Parachutistes ou Givati ​​et seront entrainés pour les classes 0-7.</li>
                        <li><strong>Ceux avec du profil 72 -</strong> serviront comme combattants de l’armée de l’air, « Magen Néguev », et seront entrainés pour les classes 0-3.</li>
                        <li><strong>Ceux qui ont un profil de 64 et moins -</strong>  peuvent accepter divers postes dans l’armée de l’air, la division TIC et la division technologie et logistique, en tant que supporteurs de combat et à des postes technologiques (programme Magen).</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Magen
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Le parcours Magen a été créée en 2007 afin de donner aux diplômés ultra-orthodoxes âgés et aux hommes mariés la possibilité d’accomplir un service militaire important en combinaison avec l’acquisition d’une profession de qualité à travers laquelle ils pourront subvenir aux besoins de leur famille avec dignité.​
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Les conditions pour intégrer le parcours Magen sont les suivantes :
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    <ul>
                        <li>Diplômés d’établissements d’enseignement ultra-orthodoxes âgés de 21 ans et plus.</li>
                        <li>Diplômés d’établissements d’enseignement ultra-orthodoxes mariés.</li>
                        <li>Diplômés d’établissements d’enseignement ultra-orthodoxes de moins de 21 ans, avec un profil médical 64 ou 45.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                Les candidats éligible au programme Magen ont la possibilité d’effectuer des études technologiques et d’utiliser leurs acquis dans Tsahal. Il faut effectuer une prépa après les classes militaires 02. À la fin du service obligatoire de deux ans, les soldats doivent effectuer un service supplémentaire de 18 mois (annulable selon les besoins de l’unité). Les types d’études possible dans ce parcours sont : 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    <ul>
                        <li>Technicien supérieur en industriel et gestion.</li>
                        <li>Technicien supérieur en construction.</li>
                        <li>Technicien supérieur en électricité.</li>
                        <li>Technicien supérieur en architecture.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Atouda Technologique
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Programme pour ceux qui souhaitent suivre une formation de technicien supérieur après le baccalauréat israélien dans les établissements d’enseignement reconnus par le ministère de l’Éducation.
                    Les diplômés recevront un diplôme de technicien supérieur et ensuite, sans engagement et conformément aux normes, exerceront la profession apprise.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    Les candidats doivent s’inscrire dans une école reconnue par l’office des études supérieures en Israël (MALAG) jusqu’au mois de mai de la classe de terminale (youd beth). Ils repousseront ensuite leurs entrées à l’armée le temps des études. 
                    Il est possible de recevoir une subvention complète de Tsahal. Dans ce cas-là, le candidat devra signer pour un service supplémentaire de 18 mois.   
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Atouda Académique
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    L’académisation ultra-orthodoxe est un parcours qui permet le service militaire au plus haut niveau, après avoir obtenu un diplôme dans l’une des universités ou écoles du pays.
                    Le parcours vise un service significatif et professionnel, et offre une option de service de qualité dans la profession étudiée, ce qui garantira au participant une intégration immédiate dans la profession étudiée, une expérience professionnelle et un horizon économique – le tout avec une licence B.A. en main.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Les conditions pour intégrer le parcours Atouda Académique sont les suivantes :
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    <ul>
                        <li>Diplômés d’établissements d’enseignement ultra-orthodoxes ayant réussi un cours préparatoire académique avant l’âge de 23 ans.</li>
                        <li>En cas de service dans la profession, signature d’une extension de service (carrière).</li>
                        <li>Dans le cas où le soldat n’est pas affecté au service dans la profession acquise, ou abandonne les études, il sera reconduit vers les parcours Herev et Magen.</li>
                        <li>Études possibles dans une université/école reconnu par l’office des études supérieures en Israël (MALAG).</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Hesder - Mossadot
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                Les yeshivot « Derech Chaim » sont des yeshivot technologiques ultra-orthodoxes dans lesquelles, par amour de la Torah, les étudiants acquièrent un métier et servent dans une grande variété de postes à l’armée, et ce dans des conditions adaptées aux ultra-orthodoxes.Le diplômé du parcours sortira avec un certificat de technicien supérieur + une expérience professionnelle.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                Il existe 4 yeshivot :
                    <ul>
                        <li>Yeshiva “Hedvata” Gan Yavne.</li>
                        <li>Beit Midrash « Barkaï ».</li>
                        <li>Beit Midrash « Derech Haim » Gan Yavne.</li>
                        <li>Torah véMada Herev Le’et (Torah et Sciences) : Le programme Derech Chaim au collège Torah et Sciences est destiné aux étudiants issus d’institutions ultra-orthodoxes et ayant terminé un bac technologique. Le programme dure 4 ans, dont deux ans sont consacrés à l’étude d’une formation pratique (électricité / communication) en combinaison avec des cours de Torah et un environnement social ultra-orthodoxe, et les deux autres années sont consacrées à servir à des postes technologiques dans l’unité de renseignements selon les conditions de service de Magen.</li>
                    </ul>
                </Typography>
            </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
  };
  
  export default Haredim;
  