import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS  
import Footer from '../../components/Home/Footer';
import { Fade } from 'react-awesome-reveal';


const ConditionService = () => {

    

    useEffect(() => {
        document.title = 'Conditions de Service | Tsahal Connection';
    }, []);
    return (
        <div>
            <Navbar />
            <Fade>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Annexes</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Conditions de Service</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '3rem', fontWeight: '500', color: '#2f3036'}}>
                    Les allègements aux conditions de service (Tnaé Chirout-TACH) pour un soldat vont dépendre du statut socio-économique du soldat et de sa famille ou encore d’une situation médicale compliquée. Ces allègements peuvent influencer sur le type de service militaire possible, le nombre de fois où le soldat rentre à la maison par semaine ou encore si le soldat peut servir dans une base militaire proche de son lieu de résidence.<br /> 
                    Il existe plusieurs niveaux d’allègement des conditions de service et pour basculer de l’un à l’autre il faut présenter diverses autorisations certifiant la situation difficile du soldat. 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        La liste des types d'aides
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        Il existe sept types d'aide au service:
                    </Typography>
                    <div style={{marginLeft: '20px'}}>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                            🫱🏻‍🫲🏽 <strong>TACH 1 -</strong> Le soldat ne peut pas être combattant.
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                            🫱🏻‍🫲🏽 <strong>TACH 2 -</strong> Le soldat dormira deux nuits/semaine au minimum dans son lieu de résidence. Le soldat peut servir dans tous les postes possibles.
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                            🫱🏻‍🫲🏽 <strong>TACH 3 -</strong> Le soldat dormira trois nuits/semaine au minimum dans son lieu de résidence et ne servira pas dans une base dépassant 100 kilomètres de son lieu de résidence.
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                            🫱🏻‍🫲🏽 <strong>TACH 4 -</strong> Peut s’obtenir uniquement via l’officier en charge de la santé mentale (KABAN) - Le soldat dormira quatre nuits/semaine au minimum dans son lieu de résidence et ne servira pas dans une base dépassant 100 kilomètres de son lieu de résidence. Pendant une formation, il dormira deux nuits/semaine au minimum dans son lieu de résidence.
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                            🫱🏻‍🫲🏽 <strong>TACH 5 -</strong> Le soldat dormira dans son lieu de résidence et sa base se trouvera à 100 kilomètres maximum de son lieu de résidence. Le soldat peut cependant effectuer de garde la nuit ou en fin de semaine (vendredi et samedi).
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                            🫱🏻‍🫲🏽 <strong>TACH 7 -</strong> Le soldat dormira dans son lieu de résidence chaque jour de la semaine. Il n’effectuera pas des gardes la nuit ou en fin de semaine et sa base ne dépassera 100 kilomètres de son lieu de résidence.​
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                            🫱🏻‍🫲🏽 <strong>TACH 99 -</strong> Le soldat recevra une autorisation pour être à la maison lors de chaque fête religieuse.
                        </Typography>
                    </div>
                </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
};

export default ConditionService;
