import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS  
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const MichveAlon = () => {

    

    useEffect(() => {
        document.title = 'Michve Alon | Tsahal Connection';
    }, []);
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Annexes</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Michve Alon</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '3rem', fontWeight: '500', color: '#2f3036'}}>
                        Michve Alon est une base du corps de l’éducation, se situant dans le nord du pays.
                        Chaque année sont recensés environ 6000 soldats et soldates dans les différentes formations que propose cette base.
                        La majorité des formations se déroule au début du service et les soldats effectuent des classes 02. Par la suite, les soldats reçoivent une formation qui est comprise dans la période du service. 
                        Pendant les différentes formations, la majorité des soldats vont rencontrer un officier de sélection qui les affectera à de nouvelles positions jusqu’à la fin de leurs services militaires.
                        Il est important de noter que les soldats olim volontaires (arrivés après 22 ans en Israël) doivent signer pour six mois supplémentaires s’ils souhaitent servir dans une unité combattante.    
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        La formation Oulpan
                    </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                        Cette formation s’adresse aux nouveaux immigrants et aux volontaires qui ont reçu une note d’hébreu faible/moyenne (5 ou 6) lors du <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link>. La durée est de trois mois (compris les classes 02). Les soldats suivent des cours d’hébreu, des cours de sionisme, d’histoire. 
                        De nombreux soldats viennent représenter leurs unités afin de pouvoir orienter les jeunes recrues, ainsi que des représentants de plusieurs organisations qui aident les soldats. Il existe trois sessions (Avril, Août et décembre).
                        Pendant la formation, ceux qui le souhaitent peuvent effectuer les tests d’entrées à l’unité des parachutistes. Les soldats participant aux formations d’août et de décembre peuvent même effectuer le <Link to='/yom-sayerot' style={{color: '#1398aa'}}>Yom Sadé</Link>. Lors du troisième mois, les soldats rencontrent un officier de sélection afin de recevoir une affectation pour le reste de leurs services.
                        </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        La formation Moledet
                    </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        Cette formation s’adresse aux nouveaux immigrants et israéliens ayant vécu la majorité de leur vie à l’étranger, et qui possède un niveau d’hébreu élevé. Il existe deux formations Mouledet : une adaptée aux combattants (Octobre et février pour une durée d’un mois), ainsi qu’une autre adaptée aux autres soldats (deux mois). Les soldats découvrent le pays et suivent des cours sur le sionisme et sur la culture israélienne, sur l’histoire et les différentes guerres. Certains soldats ont déjà reçu leurs affectations avant le début de la formation. Sinon, les soldats peuvent rencontrer un officier de sélection pendant la formation.
                        </Typography>
                </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
};

export default MichveAlon;
