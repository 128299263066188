import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';
import './../../styles/footer.css'; // Import the CSS file
import facebookLogo from './../../images/icons/facebook.png'
import instagramLogo from './../../images/icons/instagram.png'
import linkedinLogo from './../../images/icons/linkedin.png'

const Footer = () => {


  return (
    <div className="footer">
          <div className="left-section">
          <div>
          <Typography className="accroche" variant="h6" style={{ fontFamily: 'Montserrat', fontWeight: '600', color: 'white', fontSize: '2rem'}}>
            L'équipe Tsahal Connection est là pour vous accompagner.
          </Typography>
          </div>
        <Link to="https://zfrmz.com/FTnbHM1LHl4BWoGiMEqo" target="_blank" rel="noopener noreferrer">
          <Button className='button' variant="contained">Contactez nous → </Button>
        </Link>
        </div>
      <div className="section">
        <Typography variant="h6" style={{ fontFamily: 'Montserrat', fontWeight:'600', color:'rgba(255, 255, 255, 0.5)', fontSize: '16px' }}>A propos</Typography>
        <ul className="list">
          <li className="list-item">
            <Link to="/notre-histoire" className="link">
              Notre Histoire
            </Link>
          </li>
          <li className="list-item">
            <Link to="/nos-actions" className="link">
              Nos Actions
            </Link>
          </li>
          <li className="list-item">
            <Link to="/mentions-legales" className="link">
              Mentions Legales
            </Link>
          </li>
        </ul>
      </div>
    
      <div className="section">
        <Typography variant="h6" style={{ fontFamily: 'Montserrat', fontWeight:'600', color:'rgba(255, 255, 255, 0.5)', fontSize: '16px' }}>Services</Typography>
        <ul className="list">
          <li className="list-item">
            <Link to="/mahal" className="link">
              Mahal
            </Link>
          </li>
          <li className="list-item">
            <Link to="/service-complet" className="link">
              Service Complet
            </Link>
          </li>
          <li className="list-item">
            <Link to="/garin-tzabar" className="link">
              Garin Tzabar
            </Link>
          </li>
          <li className="list-item">
            <Link to="/atouda-olim-akademisatzia" className="link">
              Atoudat Olim & Akademisatzia
            </Link>
          </li>
          <li className="list-item">
            <Link to="/atouda-akademit-atidim" className="link">
              Atoudat Akademit & Atidim
            </Link>
          </li>
          <li className="list-item">
            <Link to="/youdguimdel-dalet" className="link">
              Youd Guimel - Youd Daleth
            </Link>
          </li>
          <li className="list-item">
            <Link to="/hardeim" className="link">
              Hardeim
            </Link>
          </li>
        </ul>
      </div>
      

      <div className="section" id="convocations">
        <Typography variant="h6" style={{ fontFamily: 'Montserrat', fontWeight:'600', color:'rgba(255, 255, 255, 0.5)', fontSize: '16px' }}>Convocations</Typography>
        <ul className="list">
          <li className="list-item">
            <Link to="/tsav-richon" className="link" style={{}}>
              Tsav Richon
            </Link>
          </li>
          <li className="list-item">
            <Link to="/yom-hamea" className="link">
              Yom Hamea
            </Link>
          </li>
          <li className="list-item">
            <Link to="/miyunim" className="link">
              Miyunim
            </Link>
          </li>
          <li className="list-item">
            <Link to="/yom-sayerot" className="link">
              Yom Sayerot
            </Link>
          </li>
          <li className="list-item">
            <Link to="/miyun-lohamot" className="link">
              Miyun Lohamot
            </Link>
          </li>
          <li className="list-item">
            <Link to="/giyus" className="link">
              Giyus
            </Link>
          </li>
        </ul>
      </div>
      <div className="section">
        <Typography variant="h6" style={{ fontFamily: 'Montserrat', fontWeight:'600', color:'rgba(255, 255, 255, 0.5)', fontSize: '16px' }}>Annexes</Typography>
        <ul className="list">
          <li className="list-item">
            <Link to="/mechinot" className="link">
              Mechinot
            </Link>
          </li>
          <li className="list-item">
            <Link to="/status-resident" className="link">
              Status de résident
            </Link>
          </li>
          <li className="list-item">
            <Link to="/enfant-unique" className="link">
              Enfant unique
            </Link>
          </li>
          <li className="list-item">
            <Link to="/tironout" className="link">
              Tironout & Qualification
            </Link>
          </li>
          <li className="list-item">
            <Link to="/michve-alon" className="link">
              Michve Alon
            </Link>
          </li>
          <li className="list-item">
            <Link to="/conditions-service" className="link">
              Conditions de service
            </Link>
          </li>
          <li className="list-item">
            <Link to="/religion" className="link">
              Pratique de la Religion
            </Link>
          </li>
          <li className="list-item">
            <Link to="/hayal-boded" className="link">
              Hayal Boded & Salaires
            </Link>
          </li>
          <li className="list-item">
            <Link to="/carnet-forms" className="link">
              Carnet d'adresses & Forms
            </Link>
          </li>
        </ul>
          <div className="icon-logos">
          <Link to="https://www.facebook.com/TsahalConnection/" target="_blank" rel="noopener noreferrer">
            <img src={facebookLogo} alt="Facebook" className="icon-logo" />
          </Link>
          <Link to="https://www.instagram.com/tsahalco/?hl=fr" target="_blank" rel="noopener noreferrer">
            <img src={instagramLogo} alt="Instagram" className="icon-logo" />
          </Link>
            <Link to="https://www.linkedin.com/company/tsahal-connection/?originalSubdomain=il" target="_blank" rel="noopener noreferrer">
              <img src={linkedinLogo} alt="Facebook" className="icon-logo" />
            </Link>
        </div>
      </div>
      </div>
  );
};

export default Footer;
