import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const Giyus = () => {

    useEffect(() => {
        document.title = 'Giyus | Tsahal Connection';
      }, []);
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Convocations</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Giyus</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '1rem', fontWeight: '500', color: '#2f3036'}}>
                    Le Tsav Giyus correspond à la convocation pour la date d’enrôlement dans les rangs de Tsahal et le Giyus correspond à la journée d’incorporation.
                    Un Israélien ou recevra sa convocation pour intégrer directement l’unité, à la suite des différents tests et entretiens avec l’armée.
                    Pour un nouvel immigrant (y compris les participants au programme <Link to='/garin-tzabar' style={{color: '#1398aa'}}>Garin Tzabar</Link>), cela va dépendre de son niveau d’hébreu.
                    Pour les participants au programme <Link to='/mahal' style={{color: '#1398aa'}}>Mahal</Link>, tout dépendra de la session et des indications reçues sur place par les officiers en charge et par les conseillers de Tsahal Connection.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                    Un nouvel immigrant peut incorporer Tsahal selon plusieurs parcours : <br />
                    <div style={{marginLeft: '10px'}}>
                        ➡️ Formation d’hébreu à Michve Alon (avril, août ou décembre).<br />
                        ➡️ Formation Mouledet classique à Michve Alon (septembre, janvier, mars).<br />
                        ➡️ Formation Nativ (dans le cas où le nouvel immigrant n’est pas juif selon les lois religieuses).<br />
                        ➡️ Afin d’accéder à son unité immédiatement.<br />
                        ➡️ Classes générales (Tironout Klalit).<br />
                    </div>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '2rem', fontWeight: '500', color: '#2f3036'}}>
                Cette journée est très longue et très éprouvante, car elle signifie le passage de la vie civile à la vie militaire. Les recrues doivent passer par plusieurs étapes afin de rejoindre leurs unités. 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                   Comment se préparer pour cette journée ?
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                    Il est recommandé de préparer des affaires pour deux semaines afin d’anticiper la pire situation possible. De plus, chaque futur soldat reçoit via SMS une carte virtuelle appelée “Kartis Kohavim” (carte étoile). Du fait de la bureaucratie complexe du programme <Link to='/mahal' style={{color: '#1398aa'}}>Mahal</Link>, il est possible que les candidats au programme ne reçoivent pas le SMS leur permettant d’utiliser les Kohavim. Auquel cas il faudra se présenter après l’enrôlement auprès de l’assistante sociale de l’unité (Mashakit Tach) afin de recevoir l’aide rétroactivement.   
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                    L’équipe de Tsahal Connection à préparer un document à télécharger <Link to='https://1drv.ms/b/s!AgVanePzBHI9gaJh7nTnVukZVHOzvg' style={{color: '#1398aa'}} target="_blank">ICI</Link> qui explique le principe de la carte étoile et aussi la liste des affaires reçues par Tsahal le jour de l’incorporation. La liste d’affaires à préparer est très complète, mais peut ne pas convenir à tous les candidats. Ainsi, il est recommandé d’apprendre des premières semaines dans Tsahal pour savoir quelles affaires sont-utiles.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                La déroulement de la journée
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                    Le matin du jour d’incorporation, chaque recrue se présentera au bureau de recrutement le plus proche de chez soi ou à un des points de rencontres décidés par Tsahal. Après une courte inscription, il sera envoyé au centre de recrutement de Tel Hashomer, où il passera par la “chaîne d’incorporation” accompagnée des commandants de la base où il effectuera ses classes.                
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                Pendant la journée, les nouvelles recrues devront passer par plusieurs étapes :
                <ul>
                    <li><strong>Barbier –</strong> Une coupe de cheveux standard doit être observée selon les ordres de l’armée. Il est conseillé de se raser à la maison et d’éviter cette étape.</li>
                    <li><strong>Photographe –</strong> photo pour votre carte militaire.</li>
                    <li><strong>Photographie de cavité orale et radiographie –</strong> photographie de la mâchoire supérieure, inférieure et externe. Les recrues doivent enlever leurs montres, bracelets, bagues et boucles d’oreilles.</li>
                    <li><strong>Empreintes digitales –</strong> prise des empreintes digitales pour identification en cas de catastrophe. Les recrues doivent enlever leurs montres, bracelets et bagues.</li>
                    <li><strong>Vérification des données –</strong> assurez-vous d'avoir une carte d'identité (passeport pour Mahal) et des coordonnées bancaires. De plus, les recrues devront signer 4 documents : formulaire de sécurité des informations, déclaration de confidentialité, déclaration d’allégeance à Tsahal, formulaire pour transférer les fonds de subventions en cas de décès.</li>
                    <li><strong>Vaccins contre le tétanos, la méningite ou encore grippe en hiver –</strong> prélèvement de sang et réception des vaccins. Si vous avez été vacciné au cours de l’année écoulée, l’équipe paramédicale doit être mise au courant. De plus, vous devez arriver avec un livret de vaccination valide.</li>
                    <li><strong>Réception de la carte de matricule –</strong> Carte d’identité militaire, et de plaques militaires avec votre numéro de militaire.</li>
                    <li><strong>Officier de recrutement –</strong> entretien personnel avec un officier de sélection qui donnera une affectation définitive à la recrue si celle-ci n’en a pas reçu.</li>
                    <li><strong>Entreposage –</strong> Réception de matériel militaire et civil qui vous servira pour le service dans l’armée israélienne.</li>
                    <li><strong>Don de la moelle osseuse -</strong> des soldats demanderont aux recrues si ceux-ci souhaitent effectuer un don de moelle osseuse (non-obligatoire).</li>
                    <li><strong>Médecin -</strong> dans certains cas (surtout pour les unités combattantes) les recrues doivent voir un médecin qui peut effectuer une dernière vérification avant l’affectation définitive de la recrue (non-obligatoire).</li>
                </ul>

                </Typography>

            </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
  };
  
  export default Giyus;
  