import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const GarinTzabar = () => {

    useEffect(() => {
        document.title = 'Garin Tzabar | Tsahal Connection';
      }, []);
      
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Services</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Garin Tzabar</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '1rem', fontWeight: '500', color: '#2f3036'}}>
                Garin Tzabar est un programme créé par les scouts israéliens « Tzofim ». Son but est d’aide les jeunes Juifs du monde entier à émigrer en Israël afin d’effectuer un service militaire de qualité dans l’armée. Une des conditions du programme est d’être <Link to="/mahal" target="_blank" style={{color: '#1398aa'}}>soldat seul</Link>. De plus, les candidats effectuent un service complet, car ils effectuent leur alyah.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '0.5rem', fontWeight: '500', color: '#2f3036'}}>
                    Ce programme s’adresse à : 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '2rem', fontWeight: '500', color: '#2f3036'}}>
                    <ul>
                        <li>Garçons âgés de 18 à 24 ans compris.</li>
                        <li>Filles âgées de 18 à 23 ans compris.</li>
                        <li>Israéliens ayant vécu la majorité de leurs vis en dehors Israël.</li>
                        <li>Nouveaux immigrants depuis deux ans maximums.</li>
                    </ul>  
                    Le programme a un coup variant entre 3600 à 5000 NIS (en fonction du lieu d’inscription).
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                   Inscription
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem'}}>
                    Tout d’abord, le candidat doit vérifier qu’il répond aux conditions suivantes : 
                    <ul>
                        <li>Diplôme d’études secondaires.</li>
                        <li>Casier judiciaire vide.</li>
                        <li>Engagement à participer à toutes les étapes du programme.</li>
                        <li>Visites – avoir séjourné en Israël en vacances pour un temps cumulé de deux mois minimums.</li>
                        <li>Participation à des séminaires de préparation.</li>
                        <li>Avoir un niveau basique d’hébreu.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                    Le programme Garin Tzabar nécessite de faire la alyah. Le candidat doit donc préparer son dossier d’alyah avec l’Agence Juive et expliquer qu’il participe aux démarches du programme Garin Tzabar.
                    Après avoir effectué la alyah, le candidat sera logé dans un kibboutz/centre d’intégration avec d’autres participants au programme. Il devra participer à des cours d’hébreu (session d’été uniquement) ainsi qu’à des cours de préparations à l’armée, jusqu’à l’enrôlement.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                    Les différents responsables du programme s’occupent des démarches pour l’armée et les candidats seront convoqués quelques semaines après la alyah pour participer aux divers entretiens avec Tsahal.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                    Il est important de noter que les participants au programme sont dans l’obligation de vivre dans un Kibboutz ou dans le centre d’intégration de Ra’ananna pendant au moins la moitié du service militaire. S’ils décident de changer de moyen d’habitation avant la fin de cette période, ils devront rembourser Garin Tzabar. 
                    Dans le cas où le participant réside en Israël, il peut effectuer tous les séminaires sur place puis se joindre à la suite des démarches. Cette option peut se révéler très efficace pour les francophones provenant des programmes d’intégrations comme Massa. 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Sessions
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', marginBottom: '0.5rem', fontWeight: '700'}}>
                    Été
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                🟢 Séminaires de préparation : Janvier – Juin.<br/>
                🟢 Pré-Oulpan : Juin – Juillet (obligatoire pour ceux dont le niveau d’hébreu est faible).<br/>
                🟢 Période d’accueil : Août – Novembre<br/>
                🟢 Incorporation dans Tsahal : Novembre-Décembre<br/>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', marginBottom: '0.5rem', fontWeight: '700'}}>
                    Hiver
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                🟢 Séminaires de préparation : Septembre – Novembre.<br/>
                🟢 Période d’accueil : Décembre- Mars.<br/>
                🟢 Incorporation dans Tsahal : Mars<br/>
                </Typography>
            </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
  };
  
  export default GarinTzabar;
  