import React, { useContext, useEffect, useState } from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import {Box, CircularProgress, Container, Grid, Paper, Typography, Button } from '@mui/material';
import { Row, Col, Card } from 'react-bootstrap';
import NavbarLog from './NavbarLog';
import './../../styles/profile.css'
import mathsIMG from './../../images/member-space/testmath.jpg'
import visuIMG from './../../images/member-space/testlogique.jpg'
import logiqueIMG from './../../images/member-space/testvisu.jpg'
import geometrieIMG from './../../images/member-space/testgeometri.jpg'
import verbaleIMG from './../../images/member-space/testverbale.jpg'


const Consignes = () => {

return (
  
  <div className='consignes'>
    <NavbarLog />
    <ParallaxProvider>
      <Parallax y={[-20, 20]} tagOuter="figure">
    <Container className='home-container' component="main" maxWidth="xxl" sx={{ marginTop: 10, marginLeft: 0, marginRight: 0}} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center'}}>
      <h2>Categorie</h2>
      <h1>Compréhension des consignes</h1>
    <Container maxWidth='lg'>
    <Row xs={1} md={3} className="g-4" style={{marginBottom: '10rem', display: 'flex', justifyContent: 'center'}}>
  <Col>
      <Card style={{ background: `url(${visuIMG})`, backgroundSize: 'cover', height: '200px', color: 'white', position: 'relative', border: '0px', borderRadius: '20px' }}>
        <Card.Body style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
          <Typography variant="h5" component="div" align="left" style={{
            backgroundColor: 'white',
             color:'black', 
             width: 'fit-content', 
             borderRadius: '20px', 
             padding: '10px',
             fontFamily: 'DM Sans',
             fontSize: '18px',
             fontWeight: '600'
             }}>
            Test 1
          </Typography>
        </Card.Body>
      </Card>
    </Col>
    <Col>
  <Card style={{ background: `url(${geometrieIMG})`, backgroundSize: 'cover', height: '200px', color: 'white', position: 'relative', border: '0px', borderRadius: '20px' }}>
    <Card.Body style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
      <Typography variant="h5" component="div" align="left" style={{
        backgroundColor: 'white',
        color: 'black',
        width: 'fit-content',
        borderRadius: '20px',
        padding: '10px',
        fontFamily: 'DM Sans',
        fontSize: '18px',
        fontWeight: '600'
      }}>
        Test 2
      </Typography>
      <Typography variant="body1" style={{ backgroundColor: 'white', color: 'black', borderRadius: '20px', padding: '10px', fontFamily: 'DM Sans', fontSize: '18px', fontWeight: '600' }}>
        ✅
      </Typography>
    </Card.Body>
  </Card>
</Col>
 <Col>
  <Card style={{ background: `url(${mathsIMG})`, backgroundSize: 'cover', height: '200px', color: 'white', position: 'relative', border: '0px', borderRadius: '20px' }}>
    <Card.Body style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
      <Typography variant="h5" component="div" align="left" style={{
        backgroundColor: 'white',
        color: 'black',
        width: 'fit-content',
        borderRadius: '20px',
        padding: '10px',
        fontFamily: 'DM Sans',
        fontSize: '18px',
        fontWeight: '600'
      }}>
        Test 3
      </Typography>
      <Typography variant="body1" style={{ backgroundColor: 'white', color: 'black', borderRadius: '20px', padding: '10px', fontFamily: 'DM Sans', fontSize: '18px', fontWeight: '600' }}>
        ✅
      </Typography>
    </Card.Body>
  </Card>
</Col> <Col>
  <Card style={{ background: `url(${geometrieIMG})`, backgroundSize: 'cover', height: '200px', color: 'white', position: 'relative', border: '0px', borderRadius: '20px' }}>
    <Card.Body style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
      <Typography variant="h5" component="div" align="left" style={{
        backgroundColor: 'white',
        color: 'black',
        width: 'fit-content',
        borderRadius: '20px',
        padding: '10px',
        fontFamily: 'DM Sans',
        fontSize: '18px',
        fontWeight: '600'
      }}>
        Test 4
      </Typography>
      <Typography variant="body1" style={{ backgroundColor: 'white', color: 'black', borderRadius: '20px', padding: '10px', fontFamily: 'DM Sans', fontSize: '18px', fontWeight: '600' }}>
        
      </Typography>
    </Card.Body>
  </Card>
</Col> <Col>
  <Card style={{ background: `url(${verbaleIMG})`, backgroundSize: 'cover', height: '200px', color: 'white', position: 'relative', border: '0px', borderRadius: '20px' }}>
    <Card.Body style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
      <Typography variant="h5" component="div" align="left" style={{
        backgroundColor: 'white',
        color: 'black',
        width: 'fit-content',
        borderRadius: '20px',
        padding: '10px',
        fontFamily: 'DM Sans',
        fontSize: '18px',
        fontWeight: '600'
      }}>
        Test 5
      </Typography>
      <Typography variant="body1" style={{ backgroundColor: 'white', color: 'black', borderRadius: '20px', padding: '10px', fontFamily: 'DM Sans', fontSize: '18px', fontWeight: '600' }}>
        ✅
      </Typography>
    </Card.Body>
  </Card>
</Col> <Col>
  <Card style={{ background: `url(${logiqueIMG})`, backgroundSize: 'cover', height: '200px', color: 'white', position: 'relative', border: '0px', borderRadius: '20px' }}>
    <Card.Body style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
      <Typography variant="h5" component="div" align="left" style={{
        backgroundColor: 'white',
        color: 'black',
        width: 'fit-content',
        borderRadius: '20px',
        padding: '10px',
        fontFamily: 'DM Sans',
        fontSize: '18px',
        fontWeight: '600'
      }}>
        Test 6
      </Typography>
      <Typography variant="body1" style={{ backgroundColor: 'white', color: 'black', borderRadius: '20px', padding: '10px', fontFamily: 'DM Sans', fontSize: '18px', fontWeight: '600' }}>
        ✅
      </Typography>
    </Card.Body>
  </Card>
</Col>
  </Row>
    </Container>
    </Container>
    </Parallax>
    </ParallaxProvider>
  </div>
);
}

export default Consignes;