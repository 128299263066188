import React, { useEffect } from 'react';
import Navbar from "../components/Features/Navbar";
import hr from "./../images/icons/hr.png";
import { Container, Typography, Grid, useMediaQuery, useTheme} from '@mui/material';
import { Card, Row, Col } from 'react-bootstrap'; // Use react-bootstrap for Card
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import LogoExpertCo from './../images/logos/LogoExpertConnection.png'
import LogoTsahalCo from './../images/Logo Tsahal Conection.png'
import Footer from '../components/Home/Footer';
import { Link } from 'react-router-dom';
import AchGadolLogo from "./../images/logos/AchGadol.png";
import { Fade } from 'react-awesome-reveal';


const AchGadol = () => {


    useEffect(() => {
        document.title = 'Ach Gadol | Tsahal Connection';
      }, []);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', textAlign: 'center' }}>Ach Gadol</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <h2 style={{ fontFamily: 'Montserrat', fontSize: '25px' ,  fontWeight: '600', textAlign: 'center', marginTop: '2rem' }}>Pour soutenir les soldats seuls via l'association Ach Gadol, cliquez <Link target="_blank" to="https://achgadol.org/en/donation/" style={{color: 'black'}}>ICI</Link>.</h2>
            </div>
            <Container maxWidth="lg" style={{ marginTop: '4rem', marginBottom: '4rem' }}>
                <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '18px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                    Il y a 13 ans, d’anciens soldats seuls ont lancé une initiative sociale unique qui répond au besoin de soutien, d’orientation et de conseil individuel pour les personnes qui s’intègrent dans la société israélienne de manière indépendante. Ils ont créé une «adresse» pour ce type de soutien, et nous l’avons appelé Ach Gadol.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '18px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                    Un Ach Gadol – littéralement, un grand frère – est un volontaire qui était autrefois un soldat seul et a accompli son service militaire. Il peut s’appuyer sur ses expériences personnelles pour fournir les outils nécessaires à chaque soldat seul : offrir un soutien pour développer des sentiments de résilience et de compétence et fournir des informations pertinentes et un aperçu sur la façon d’utiliser ses droits en tant que soldat seul dans Tsahal. Un Ach Gadol peut servir de médiateur culturel et fournir un soutien émotionnel dans les situations où les soldats seuls peuvent se sentir très seuls. 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '18px', fontWeight: '500',color: '#2f3036',marginBottom: '1rem' }}>
                    L’association a commencé avec 15 volontaires. Aujourd’hui, grâce aux efforts de chacun, il existe 400 volontaires. Ils fournissent un soutien continu à plus de 1 000 soldats seuls et préparent environ 500 jeunes adultes au service militaire chaque année. 
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}  style={isSmallScreen ? { order: -1, marginBottom: '3rem'} : {marginTop: '3rem'}}>
                    <img
                    src={AchGadolLogo}
                    alt="Your Alt Text"
                    style={isSmallScreen ? {width: '100%' , height: "auto"} : {width: '100%', height: 'auto'} }
                    />
                </Grid>
                </Grid>
            </Container>
            <Container maxWidth="xl" style={{marginBottom: '3rem' }}>
                    <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'stretch'}}>
                            <Col md={4} className='mb-4'>
                                <Card className="text-center border-1 shadow d-flex flex-column h-100" style={{borderRadius: '20px'}}>
                                <Card.Body className="d-flex flex-fill flex-column">
                                    <Card.Title style={{fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', fontWeight: '700',color: '#2f3036', marginBottom: '2rem'}}>Avant le service</Card.Title>
                                    <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '16px', fontWeight: '500',color: '#2f3036'}}>
                                            ✅ <strong>Préparation de 500 futurs soldats seuls -</strong> au service militaire grâce à des interventions dans les programmes d’intégration en français (projet Tsahal Connection), russe, anglaise et espagnol.
                                    </Card.Text>
                                    <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '16px', fontWeight: '500',color: '#2f3036'}}>
                                            ✅ <strong>Achla Safa -</strong> programme pré-armée de 3 mois dont l’objectif est d’améliorer significativement le niveau d’hébreu.
                                    </Card.Text>
                                </Card.Body>    
                                </Card>
                            </Col>
                            <Col md={4} className='mb-4'>
                                <Card className="text-center border-1 shadow d-flex flex-column h-100" style={{borderRadius: '20px'}}>
                                <Card.Body className="d-flex flex-fill flex-column">
                                    <Card.Title style={{fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', fontWeight: '700',color: '#2f3036', marginBottom: '2rem'}}>Pendant le service</Card.Title>
                                    <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '16px', fontWeight: '500',color: '#2f3036'}}>
                                        ✅ <strong>"Ach LeShichror" -</strong> Séminaire pour les soldats seuls ayant fini leurs services militaires afin de les préparer au retour à la vie civile (travail, études, bourses, etc..).
                                    </Card.Text>
                                    <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '16px', fontWeight: '500',color: '#2f3036'}}>
                                        ✅ <strong>Assistance académique -</strong> tutorats pour des dizaines de nouveaux immigrants dans les programmes pré-académiques.
                                    </Card.Text>
                                    <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '16px', fontWeight: '500',color: '#2f3036'}}>
                                        ✅ <strong>Club des anciens -</strong> Services d'entraide entre 500 anciens volontaires de l'association afin d'optimiser leur intégration en Israël.
                                    </Card.Text>
                                </Card.Body>        
                                </Card>
                            </Col>
                            <Col md={4} className='mb-4'>
                                <Card className="text-center border-1 shadow d-flex flex-column h-100" style={{borderRadius: '20px'}}>
                                <Card.Body className="d-flex flex-fill flex-column">
                                    <Card.Title style={{fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', fontWeight: '700',color: '#2f3036', marginBottom: '2rem'}}>Après le service</Card.Title>
                                    <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '16px', fontWeight: '500',color: '#2f3036'}}>
                                        ✅ <strong>Accompagnement des soldats seuls -</strong> 400 anciens soldats seuls accompagnent et aident au quotidien plus de 1000 soldats seuls en service.
                                    </Card.Text>
                                    <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '16px', fontWeight: '500',color: '#2f3036'}}>
                                        ✅ <strong>Appartements Alach -</strong> nos volontaires sont présents dans plus de 40 appartements pour soldats (de l'association du bien-être des soldats) afin de supporter et d'accompagner les 100 soldats seuls vivants dans ces locaux.
                                    </Card.Text>
                                    <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '16px', fontWeight: '500',color: '#2f3036'}}>
                                        ✅ <strong>Les clubs pour soldats -</strong> accompagnement de 300 soldats seuls par an via les clubs des maisons des soldats à travers le pays.
                                    </Card.Text>

                                </Card.Body>    
                                </Card>
                            </Col>
                        </Row>
                    </Container>
            <Footer />
            </Fade>
        </div>
    );
};

export default AchGadol;
