import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import LogoTsahalCo from './../../images/Logo Tsahal Conection.png'
import Footer from '../../components/Home/Footer';
import { Fade } from 'react-awesome-reveal';
import LogoExpertCo from './../../images/logos/LogoExpertConnection.png';
import LogoInfoCo from './../../images/logos/LogoInfoConnection.png';
import LogoSportConnection from './../../images/logos/LogoSportConnection.png';

const NosActions = () => {
    const bulletListStyle = {
        listStyleType: 'circle',
        marginLeft: '1.5rem',
    };

    useEffect(() => {
        document.title = 'Nos Actions | Tsahal Connection';
      }, []);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', textAlign: 'center' }}>Nos Actions</h1>
                <img src={hr} alt="HR" width="80" height="50" />
            </div>
            <Container maxWidth="md" style={{ marginTop: '3rem', marginBottom: '4rem' }}>
                <Grid container spacing={3}>
                    {/* Logo to the left and text to the right */}  
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={4} style={isSmallScreen ? { display: 'flex', justifyContent: 'center', marginBottom: '2rem'} : null}>
                                <img src={LogoInfoCo} alt="Logo 1" style={{ width: '250px', height: '250px' }} />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '18px', fontWeight: '500',color: '#2f3036', marginTop: '1rem' }}>
                                    <ul style={bulletListStyle}>
                                        <li>Plateforme en ligne : expliquant toutes les possibilités dans Tsahal et expliquant de manière claire le système militaire israélien.</li>
                                        <li>Ateliers de préparation : à Tsahal sont déployés dans les programmes de l'Expérience Israélienne, dans certaines yeshivot et dans les écoles franco-israéliennes avec un total de 50 interventions par an dans 10 programmes.</li>
                                        <li>Salon des postes : Création du salon des postes dans Tsahal (Kenes tafkidim) où les futurs soldats ont la possibilité de rencontrer d'anciens soldats francophones ayant servi dans diverses unités de Tsahal.</li>
                                    </ul>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Text to the left and logo to the right */}
                    <Grid item xs={12} style={{ marginTop: '4rem' }}>
                        <Grid container>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '18px', fontWeight: '500',color: '#2f3036', marginTop: '1rem' }}>
                                    <ul style={bulletListStyle}>
                                        <li>Accompagnement individuel jusqu’à l’entrée à l’armée (moyennant paiement).</li>
                                        <li>Partenariat avec le programme Mahal du ministère de la Défense israélien permettant de simplifier tout le processus d’intégration à Tsahal (moyennant paiement).</li>
                                        <li>Préparation individuelle aux différents entretiens militaires (moyennant paiement).</li>
                                        <li>Orientation individuelle sur les différents postes dans Tsahal selon le profil du candidat (moyennant paiement).</li>
                                    </ul>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} style={isSmallScreen ? { order: -1,  display: 'flex', justifyContent: 'center', marginBottom: '2rem'} : {display: 'flex', justifyContent: 'flex-end'}}>
                                <img src={LogoExpertCo} alt="Logo 2" style={{ width: '250px', height: '250px' }} />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Logo to the left and text to the right */}
                    <Grid item xs={12} style={{ marginTop: '4rem' }}>
                        <Grid container>
                            <Grid item xs={12} sm={4} style={isSmallScreen ? {display: 'flex', justifyContent: 'center', marginBottom: '2rem'} : null}>
                                <img src={LogoSportConnection} alt="Logo 1" style={{ width: '250px', height: '250px' }} />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '18px', fontWeight: '500',color: '#2f3036', marginTop: '1rem' }}>
                                    <ul style={bulletListStyle}>
                                        <li>Préparation mentale and physique à Netanya, Tel Aviv and Ashdod (moyennant paiement et un minimum de 15 participants par semaines).</li>
                                        <li>Programme d'entraînement adapté à tous les niveaux and se déroulant sur une période de 6 mois minimum.</li>
                                        <li>Entraînements s'adressant à des garçons and des filles ainsi que des groupes mixtes.</li>
                                    </ul>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700' }}>Nos Partenaires</h1>
                <img src={hr} alt="HR" width="80" height="50" />
            </div>
            <Container maxWidth="md" style={{marginTop: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '18px', fontWeight: '500',color: '#2f3036', marginTop: '1rem' }}>
                <ul>
                    <li><strong>Accompagnement des soldats seuls :</strong> Pour les soldats seuls en service, nous leur offrons la possibilité de recevoir un grand frère ou une grande sœur qui les accompagnera pendant toute la durée de leurs services militaires (grâce à notre association mère Ach Gadol).</li><br />
                    <li><strong>Volontariat :</strong> Les soldats seuls libérés ont la possibilité de se porter volontaires dans l'association Ach Gadol et d'être affectés dans le projet Tsahal Connection ou dans divers projets visant à aider les soldats seuls (grâce à notre association mère Ach Gadol). Le volontariat est reconnu par la majorité des bourses israéliennes (office des étudiants, Impact, Heseg, Atidim etc.…).</li><br />
                    <li><strong>QUALITA :</strong> Partenariat avec l'organisation Qualita et participation à différentes émissions de radio Qualita.</li><br />
                    <li><strong>Agence Juive :</strong> Participation plusieurs fois dans l'année aux salons de l'alyah de l'Agence Juive en France ainsi qu'à l'évènement du Bac Bleu Blanc en Israël.</li><br />
                    <li><strong>Mairies :</strong> Partenariat avec les mairies de Haïfa, Tel Aviv, Netanya, et Ashdod avec de nombreuses interventions chaque année.</li><br />
                    <li><strong>Préparation militaire :</strong> Nous avons grandement participé à la création de l'unique préparation militaire mélangeant francophones et israéliens qui se déroulait à Netanya entre 2017 and 2019.</li><br />
                </ul>
                </Typography>
            </Container>
            <Footer />
            </Fade>
        </div>
    );
};

export default NosActions;
