import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Grid, Typography} from '@mui/material';

function ActionsSection() {
  const actionNumberStyle = {
    fontSize: '5rem',
    marginRight: '3rem',
    marginLeft: '3rem',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: 'white',
  };

  const plusStyle = {
    fontSize: '4rem',
  };

  const descriptionStyle = {
    fontSize: '0.8em',
    whiteSpace: 'pre-wrap',
    fontFamily: 'Montserrat',
    fontWeight: '500',
    color: 'white',
  };

  // State to manage the animation
  const [animated, setAnimated] = useState(false);

  // Initialize refs
  const numberRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  // Define the final numbers
  const finalNumbers = [400, 50, 50, 10];

  useEffect(() => {
    const handleScroll = () => {
      // Check if the section is in the viewport and animation hasn't run yet
      if (!animated && isInViewport()) {
        numberRefs.forEach((ref, index) => {
          animateNumber(ref.current, finalNumbers[index]);
        });
        setAnimated(true);
      }
    };

    const isInViewport = () => {
      // Helper function to check if the element is in the viewport
      const element = document.querySelector('.actions-section');
      const rect = element.getBoundingClientRect();
      return rect.top >= 0 && rect.bottom <= window.innerHeight;
    };

    const animateNumber = (element, finalNumber) => {
      // Function to animate the number counting
      let currentNumber = 0;
      const increment = finalNumber / 80; // Adjust the speed of the animation

      const updateNumber = () => {
        if (currentNumber < finalNumber) {
          currentNumber += increment;
          element.innerHTML = Math.round(currentNumber);
          requestAnimationFrame(updateNumber);
        } else {
          element.innerHTML = finalNumber;
        }
      };

      updateNumber();
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [animated, finalNumbers]);

  const descriptions = [
    'Futures soldats francophones accompagnés / an',
    'Conférences d\'information / an',
    'Demandes traitées par jour à travers la Hotline',
    'Ateliers de préparation à l\'armée/an dans les programmes d\'intégration',
  ];

  const getDescriptionChunks = (description) => {
    // Split the description into chunks of 3-4 words
    const words = description.split(' ');
    const chunks = [];
    for (let i = 0; i < words.length; i += 3) {
      chunks.push(words.slice(i, i + 3).join(' '));
    }
    return chunks;
  };

  return (
    <div style={{ width: '100%', backgroundColor: '#682594', minHeight: '500px', paddingBottom: '100px'}}>
      <Typography
        variant="h4"
        component="h1"
        style={{
          color: 'white',
          fontFamily: 'Montserrat',
          fontWeight: '600',
          textAlign: 'center',
          paddingTop: '5rem',
          marginBottom: '3rem',
          textTransform: 'uppercase'
        }}
      >
        Nos Actions
      </Typography>

      <Container
        className="actions-section"
        style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#682594', padding: '0' }}
      >
        <Grid container spacing={2}>
          {finalNumbers.map((number, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h2" style={actionNumberStyle}>
                  <span style={plusStyle}>+</span>
                  <span ref={numberRefs[index]}>0</span>
                </Typography>
                {getDescriptionChunks(descriptions[index]).map((chunk, i) => (
                  <Typography key={i} variant="body1" style={descriptionStyle}>
                    {chunk}
                  </Typography>
                ))}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default ActionsSection;
