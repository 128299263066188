import React, { useEffect } from 'react';
import Navbar from "../components/Features/Navbar";
import hr from "./../images/icons/hr.png";
import { Container, Typography, Grid, useMediaQuery, useTheme} from '@mui/material';
import { Card, Row, Col } from 'react-bootstrap'; // Use react-bootstrap for Card
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import LogoExpertCo from './../images/logos/LogoExpertConnection.png'
import LogoTsahalCo from './../images/Logo Tsahal Conection.png'
import Footer from '../components/Home/Footer';
import { Link } from 'react-router-dom';
import AchGadolLogo from "./../images/logos/AchGadol.png";
import { Fade } from 'react-awesome-reveal';


const Postes = () => {


    useEffect(() => {
        document.title = 'Postes | Tsahal Connection';
      }, []);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', textAlign: 'center' }}>Postes</h1>
                <img src={hr} alt="HR" width="80" height="50" />            </div>
            <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '1rem', fontWeight: '500', color: '#2f3036'}}>
                    Il existe dans Tsahal officiellement près de 300 affectations possible. Cependant, il existe officieusement près de 1200 positions possibles dans ces différentes affectations. 
                    Tsahal définit deux modes d’accès à ces différents postes : sans tests et avec tests.
                    Les candidats étant originellement exemptés par Tsahal pour raisons médicales ou mentales peuvent dans certains cas se porter volontaire.<br />
                    Les postes sans tests sont accessibles via les notes du <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link> et éventuellement du <Link to='/yom-hamea' style={{color: '#1398aa'}}>Yom Hamea</Link> (pour ceux qui n’effectuent pas l’Oulpan militaire de <Link to='/michve-alon' style={{color: '#1398aa'}}>Michve Alon</Link>) tandis que les postes avec tests comprennent des <Link to='/miyunim' style={{color: '#1398aa'}}>Miyunim</Link> (sélections) propres à chaque unité.<br />
                </Typography>
                <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#eafcff', borderColor: '#eafcff', marginBottom: '2rem', marginTop: '1rem'}}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1', fontSize: '20px', color: '#2f3036', fontWeight: '600', padding: '20px'}}>
                    ⬇️ Pour télécharger la liste des postes dans Tsahal, cliquez <Link to="https://1drv.ms/b/s!AgVanePzBHI9gplRvmg5mAyOccar3w?e=xxWwmI" target="_blank">ICI</Link>.
                    </Typography> 
                </div>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                   Le système d'affectation
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                De manière générale, les affectations dans Tsahal se font selon le « Mechulash hachiboutzim » (triangle des affectations) via trois points principaux : 
                <div style={{marginLeft: '10px'}}>
                        ➡️ <strong>Besoin de l’armée (70%) -</strong> dépend des besoins et des postes disponible le moment T.<br />
                        ➡️ <strong>Dossier du candidat (20%) -</strong> notes du Tsav Rishon, Yom Hamea, recommandation des programme pré-armée si participation il y a, recommandation des officier à Mihva Alon et nouvelle note d’hébreu (si les candidats s’enrôlent via Mihva Alon). <br />
                        ➡️ <strong>Envies du candidat (10%).</strong>
                </div>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '0.5rem',color: '#2f3036' }}>
                    Les envies du candidat peuvent se déclarer à plusieurs moments : 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                    <ul>
                        <li>Via le Cheelon Aadafot.</li>
                        <li>Via des demandes pendant le processus d’incorporation dans Tsahal.</li>
                        <li>Via la rencontre avec l’officier de sélection pendant Michve Alon.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                    Les candidats possédant un niveau d’hébreu élevé et n’ayant pas reçu d’affectation pour les formations à Mihva Alon recevront le formulaire de préférence d’unités appelé Cheelon Aadafot.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '2rem' }}>
                    Pour les personnes s’engageant via une formation à Michve Alon, ils rencontreront un officier de sélection à la fin de leurs formations et celui-ci leur transmettra le formulaire de préférence de manière écrite.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                    Cheelon Aadafot
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                    Comme indiqué plus haut, cette option d’affectation s’adresse aux candidats et candidates possédant un niveau de 7 ou 8 et donc étant exempté de la formation d’hébreu de Mihva Alon. Il existe deux types de formulaires : celui pour les combattants et celui pour les non-combattants.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                    De manière générale, un garçon possédant un profil combattant et sans note du Kahas visible sur le site Mitgaisim, recevra un formulaire avec uniquement des postes combattants, des postes de conducteur, ou des postes de soutiens aux combats dans lesquels Tsahal ne possèdent pas assez de soldats.
                    Les filles recevront automatiquement un formulaire non-combattant mais, si elles souhaitent devenir combattante, elles devront l’indiquer afin de recevoir un Miyun Lohamot par la suite.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '2rem' }}>
                    Dans les formulaires, il faut que les candidats classent les postes de 1 (faible) à 5 (élevé) en fonction de leurs envies. Cependant, il faut garder en tête que la moyenne générale du classement doit être proche de 3. Il ne faut donc pas mettre 1 partout ou 5 partout sinon Tsahal décidera de l’affectation.<br />
                    <strong>L’importance de ce formulaire est primordiale !</strong>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                    Katsin Miyun
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                    Cette option s’applique aux candidats passant par la formation d’hébreu de Mihva Alon et dans certains cas à ceux qui intègrent l’armée via la formation Mouledet de Mihva Alon (même si en général l’affectation dans ce cas est définie avant). Ce rendez-vous s’effectue à la fin des formations de Mihva Alon.
                    L’avantage d’une rencontre en face à face est de pouvoir mettre en avant sa motivation. Cependant, il faut impérativement que le dossier du candidat soit bon et surtout que le nouveau niveau d’hébreu soit élevé autrement l’officier proposera des postes sans véritable responsabilité.                </Typography>
            </Container>
            <Footer />
            </Fade>
        </div>
    );
  };

export default Postes;
