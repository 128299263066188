import React,{useEffect} from 'react';
import { Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import RegisterForm from './../components/Log/RegisterForm';
import image from './../images/girl-with-laptop-light.png';

const LoginPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    document.title = 'Inscription | Tsahal Connection';
  }, []);
  return (
    <div style={{ backgroundColor: "#F5F5F9" }}>
      <Box display="flex" style={{ backgroundColor: "##F5F5F9" }}>
        {isSmallScreen ? null : (
          <Box flex="1.5" style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={image} alt="Background" style={{ padding: '4rem', width: '90%' }} />
          </Box>
        )}
        <Box flex="1" style={{ backgroundColor: 'white'}}>
          <Paper elevation={0}>
            <RegisterForm />
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

export default LoginPage;
