import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const ServiceComplet = () => {

    const firstTable = [
        {
            type: '18 - 19 ans',
            col1: '32 mois',
            col2: '24 mois',
            col3: '24 mois',
            col4: ''
        },
        { 
            type: '20 ans compris',
            col1: '24 mois',
            col2: '24 mois',
            col3: '24 mois',
            col4: 'Exempté/e (peut se porter volontaire pour une période minimale de 24 mois)'
        },
        { 
            type: '21 ans compris',
            col1: '24 mois',
            col2: '24 mois',
            col3: '24 mois',
            col4: 'Exempté/e (peut se porter volontaire pour une période minimale de 24 mois)'
        },
        { 
            type: '22-27 ans compris',
            col1: 'Exempté/e (peut se porter volontaire pour une période minimale de 24 mois)',
            col2: 'Exempté/e (peut se porter volontaire pour une période minimale de 24 mois)',
            col3: 'Exempté/e (peut se porter volontaire pour une période minimale de 24 mois)',
            col4: 'Exempté/e (peut se porter volontaire pour une période minimale de 24 mois)',
        },
        { 
            type: '28 ans et plus',
            col1: 'Exempté',
            col2: 'Exempté',
            col3: 'Exemptée',
            col4: 'Exempté/e'
        },
    ];

    const secondTable = [
        {
            type: '18 - 19 ans',
            col1: '32 mois',
            col2: '24 mois',
            col3: 'Exempté (peut se porter volontaire pour une période minimale de 18 mois)',
            col4: '24 mois'
        },
        { 
            type: '21 ans compris',
            col1: '24 mois',
            col2: '18 mois',
            col3: 'Exempté (peut se porter volontaire pour une période minimale de 18 mois)',
            col4: 'Exemptée (peut se porter volontaire pour une période minimale de 24 mois)'
        },
        { 
            type: '22-27 ans compris',
            col1: 'Exempté (peut se porter volontaire pour une période minimale de 24 mois)',
            col2: 'Exempté (peut se porter volontaire pour une période minimale de 24 mois)',
            col3: 'Exempté (peut se porter volontaire pour une période minimale de 24 mois)',
            col4: 'Exemptée (peut se porter volontaire pour une période minimale de 24 mois)',
        },
        { 
            type: '28 ans et plus',
            col1: 'Exempté et ne peut plus se porter volontaire',
            col2: 'Exempté et ne peut plus se porter volontaire',
            col3: 'Exempté et ne peut plus se porter volontaire',
            col4: 'Exemptée et ne peut plus se porter volontaire',

        },
    ];

    const thirdTable = [
        {
            type: '18 - 19 ans',
            col1: '31-97',
            col2: '30 mois',
            col3: '18 mois',
            col4: '24 mois'
        },
        { 
            type: '20-21 ans compris',
            col1: '65-97 / 31-64',
            col2: '24 mois / 24 mois',
            col3: '6 mois / Sous réserve',
            col4: 'Exemptée'
        },
        { 
            type: '22-25 ans compris',
            col1: '65-97 / 31-64',
            col2: '6 mois / Sous réserve',
            col3: '6 mois / Sous réserve',
            col4: 'Exemptée'
        },
        { 
            type: '26-29 ans et plus',
            col1: '31-97',
            col2: 'Sous réserve',
            col3: 'Sous réserve',
            col4: 'Sous réserve',

        },
        { 
            type: '30 ans et plus',
            col1: 'Exempté et ne peut plus se porter volontaire',
            col2: 'Exempté et ne peut plus se porter volontaire',
            col3: 'Exempté et ne peut plus se porter volontaire',
            col4: 'Exemptée et ne peut plus se porter volontaire',

        },
    ];

    const fourthTable = [
        {
            type: 'Jusqu\'au 30 décembre 2021',
            col1: 'Jusqu’à 32 ans compris',
            col2: 'Médecin',
            col3: 'Masculin',
            col4: '18 mois en condition de soldat de carrière*'
        },
        {
            type: 'Jusqu\'au 30 décembre 2021',
            col1: 'Jusqu’à 29 ans compris',
            col2: 'Dentiste',
            col3: 'Masculin',
            col4: '18 mois en condition de soldat de carrière'
        },
        {
            type: 'Depuis le 1 janvier 2022',
            col1: 'Jusqu’à 26 ans compris',
            col2: 'Médecin et dentiste',
            col3: 'Masculin',
            col4: '24 mois en condition de soldat de carrière*'
        },
        {
            type: 'Depuis le 1 janvier 2022',
            col1: 'Jusqu’à 26 ans compris',
            col2: 'Médecin et dentiste',
            col3: 'Féminin',
            col4: '24 mois en condition de soldat de carrière'
        },
        {
            type: 'Depuis le 1 janvier 2022',
            col1: 'Entre 27 et 33 ans compris',
            col2: 'Médecin et dentiste',
            col3: 'Masculin',
            col4: '20 mois en condition de soldat de carrière'
        },
        {
            type: 'Depuis le 1 janvier 2022',
            col1: 'Entre 27 et 33 ans compris',
            col2: 'Médecin et dentiste',
            col3: 'Féminin',
            col4: '18 mois en condition de soldat de carrière'
        },
    ];


    useEffect(() => {
        document.title = 'Service Complet | Tsahal Connection';
      }, []);
      
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Services</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Service Complet</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '1rem', fontWeight: '500', color: '#2f3036'}}>
                    Le service obligatoire connu sous le nom de « chirout sadir » est un devoir pour tous les israéliens et israéliennes. Le service obligatoire actuel est de 2 ans et 8 mois pour les garçons et de 2 ans pour les filles (certaines unités demandent de signer plus). Cependant pour les nouveaux immigrants ou pour les Israéliens ayant résidé à l’étranger la majorité de leurs vies, le temps de service va dépendre de plusieurs critères. Les filles religieuses peuvent être exemptées de ce service en demandant une dispense à la rabbanout et en l’envoyant à l’armée. La demande doit se faire après que la jeune fille a dépassé 16 ans et demi et au plus tard 90 jours avant l’enrôlement. Tsahal indique qu’il faut que la demande se fasse soit via “doar rashoum” (colis recommandé) soit en main propre dans le centre de recrutement (qui dépends du lieu d’habitation indiqué sur la carte d’identité israélienne).
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '1rem', fontWeight: '500', color: '#2f3036'}}>
                    Les israéliens commencent généralement les démarches avec l’armée après 16 ans et 4 mois. Les nouveaux immigrants seront eux automatiquement exemptés de tout contact direct avec l’armée pour une période comprise entre 6 mois et un an.  En effet, l’armée considère qu’un nouvel immigrant doit s’intégrer à la mentalité du pays et apprendre la langue avant de commencer les premières démarches avec le système militaire. Cependant, il est possible d’annuler cette période d’intégration en envoyant des mails à l’armée expliquant les raisons de l’annulation de cette période. Par la suite l’armée traitera la demande et le nouvel immigrant sera convoqué pour le <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link>. Il est important de comprendre que malgré l’annulation de cette année d’intégration, l’incorporation dans Tsahal prend du temps et il faut profiter de ce temps pour se préparer et s’adapter à la culture israélienne.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '2rem', fontWeight: '500', color: '#2f3036'}}>
                    De plus, il est important de comprendre que tant qu’une personne reste avec le statut de touriste et ne fait pas la aliyah, alors elle ne sera pas appelée pour effectuer son service militaire dont la durée est calculée en fonction des différents paramètres cités sur cette page.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                Date d’arrivée en Israël et statut du futur soldat
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem'}}>
                Dans le cas où le candidat est un nouvel immigrant qui a l’origine n’avait pas la nationalité israélienne, le décompte se fait de deux façons : 

                    <ul>
                        <li>Le candidat a passé 4 mois consécutifs ou plus en Israël, même s’il rentre à l’étranger de temps en temps. Le décompte se fera à partir du premier jour lors de son arrivée en Israël (au début des 4 mois).</li>
                        <li>Le candidat a passé 4 mois consécutifs ou plus en Israël, même s’il rentre à l’étranger de temps en temps. Le décompte se fera à partir du premier jour lors de son arrivée en Israël (au début des 4 mois).</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                    Ainsi, il est important de noter que si un touriste décide de faire sa alyah après un programme Massa ou autres, son temps de service militaire sera décidé en fonction de son arrivée dans le territoire en tant que touriste !
                    L’unique moyen pour changer la date d’arrivée est de quitter le territoire pour une durée de 12 mois (si la personne était en Israël dans un programme d’intégration ou pour des études académiques reconnues par le Ministère de la aliyah et de l’Intégration) ou 24 mois (si la personne était en Israël sans aucun cadre). Ainsi la nouvelle date d’arrivée sera prise en compte.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                    Dans le cas où le candidat est Ben Meager c’est-à-dire un citoyen israélien né en dehors d’Israël ou ayant quitté Israël avec ses parents avant l’âge de 16 ans et résidant à l’étranger, il a plusieurs options pour effectuer son service militaire. La première est Mahal (18 mois de service) dans le cas où il répond aux conditions d'admission et la deuxième signifie de faire un service complet selon le tableau plus bas. 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                À la différence d'un nouvel immigrant, un Israélien d'origine n'a pas d'année d'intégration et peut se présenter directement au centre de recrutement (Lishkat Giyus) le plus proche, régulariser son statut et faire son <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link> directement sur place. De ce fait son enrôlement se fait beaucoup plus rapidement qu'un nouvel immigrant.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Durée du service des Olims et des Ben Meagrim
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                    Il existe trois tableaux qui expliquent la durée du service obligatoire pour un nouvel immigrant arrivé en Israël dès 18 ans.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', marginBottom: '2rem', fontWeight: '700'}}>
                    Arrivée en Israël après le 01 juin 2020 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                <TableContainer sx={{borderRadius: '15px'}} component={Paper} style={{marginBottom: '3rem'}}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight: '600',fontFamily: 'Montserrat',textAlign: 'center', border: '0px solid white'  }}>Âge lors de l'arrivée en Israel</TableCell>
                            <TableCell style={{ backgroundColor: '#499BF8', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Homme célibataire</TableCell>
                            <TableCell style={{ backgroundColor: '#FFC804', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Homme marié sans enfant</TableCell>
                            <TableCell style={{ backgroundColor: '#9E4AFF', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Femme</TableCell>
                            <TableCell style={{ backgroundColor: '#FF94CD', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Homme/Femme marié/e avec un enfant</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {firstTable.map((row, index) => (
                            <TableRow key={index}>
                            <TableCell style={{ fontWeight: '600', fontFamily: 'Montserrat', border: '0px solid white' }}>{row.type}</TableCell>
                            <TableCell style={{ backgroundColor: '#DBEAFF', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col1}</TableCell>
                            <TableCell style={{ backgroundColor: '#FFF8E1', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col2}</TableCell>
                            <TableCell style={{ backgroundColor: '#E4D5FC', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col3}</TableCell>
                            <TableCell style={{ backgroundColor: '#FFD5EF', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col4}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                    <ul>
                        <li>Les femmes mariées ne sont pas obligées de servir dans les rangs de Tsahal.</li>
                        <li>Les demandes de volontariat dans Tsahal par des hommes ou des femmes qui ont au minimum 28 ans seront traitées au cas par cas.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', marginBottom: '2rem', fontWeight: '700'}}>
                Arrivée en Israël après entre le 01 juin 2015 et le 31 mai 2020 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                <TableContainer sx={{borderRadius: '15px'}} component={Paper} style={{marginBottom: '3rem'}}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight: '600',fontFamily: 'Montserrat',textAlign: 'center', border: '0px solid white'  }}>Âge lors de l'arrivée en Israel</TableCell>
                            <TableCell style={{ backgroundColor: '#499BF8', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Homme célibataire</TableCell>
                            <TableCell style={{ backgroundColor: '#FFC804', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Homme marié sans enfant</TableCell>
                            <TableCell style={{ backgroundColor: '#9E4AFF', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Homme marié avec un enfant</TableCell>
                            <TableCell style={{ backgroundColor: '#FF94CD', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Femme</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {secondTable.map((row, index) => (
                            <TableRow key={index}>
                            <TableCell style={{ fontWeight: '600', fontFamily: 'Montserrat', border: '0px solid white' }}>{row.type}</TableCell>
                            <TableCell style={{ backgroundColor: '#DBEAFF', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col1}</TableCell>
                            <TableCell style={{ backgroundColor: '#FFF8E1', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col2}</TableCell>
                            <TableCell style={{ backgroundColor: '#E4D5FC', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col3}</TableCell>
                            <TableCell style={{ backgroundColor: '#FFD5EF', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col4}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                    <ul>
                        <li>Les femmes mariées ne sont pas obligées de servir dans les rangs de Tsahal.</li>
                        <li>Les femmes qui ont un ou plusieurs enfants sont exemptées du service militaire.</li>
                        <li>Les hommes qui ont un ou plusieurs enfants et sont arrivés en Israël après leurs 18 ans sont exemptés du service militaire.</li>
                        <li>Les demandes de volontariat dans Tsahal par des hommes ou des femmes qui ont au minimum 28 ans seront traitées au cas par cas.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', marginBottom: '2rem', fontWeight: '700'}}>
                    Arrivée en Israël jusqu’au 31 Mai 2015 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                <TableContainer  sx={{borderRadius: '15px'}} component={Paper} style={{marginBottom: '3rem'}}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight: '600',fontFamily: 'Montserrat',textAlign: 'center', border: '0px solid white'  }}>Âge lors de l'arrivée en Israel</TableCell>
                            <TableCell style={{ backgroundColor: '#499BF8', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Profil médical</TableCell>
                            <TableCell style={{ backgroundColor: '#FFC804', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Homme célibataire</TableCell>
                            <TableCell style={{ backgroundColor: '#9E4AFF', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Homme marié sans enfant</TableCell>
                            <TableCell style={{ backgroundColor: '#FF94CD', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Femme</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {thirdTable.map((row, index) => (
                            <TableRow key={index}>
                            <TableCell style={{ fontWeight: '600', fontFamily: 'Montserrat', border: '0px solid white' }}>{row.type}</TableCell>
                            <TableCell style={{ backgroundColor: '#DBEAFF', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col1}</TableCell>
                            <TableCell style={{ backgroundColor: '#FFF8E1', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col2}</TableCell>
                            <TableCell style={{ backgroundColor: '#E4D5FC', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col3}</TableCell>
                            <TableCell style={{ backgroundColor: '#FFD5EF', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col4}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem',color: '#2f3036' }}>
                    <ul>
                        <li>Les femmes mariées ne sont pas obligées de servir dans les rangs de Tsahal.</li>
                        <li>Les hommes qui ont un ou plusieurs enfants sont exemptés du service militaire.</li>
                        <li>Sous-réserve : cela signifie que les candidats ne s’enrôleront à Tsahal qu’en cas d’urgence et selon les besoins de l’armée israélienne.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Durée du service des médecins et dentistes
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem',color: '#2f3036' }}>
                    Voici un tableau résumant le temps de service des médecins et dentistes en fonction de plusieurs critères :
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '0.5rem', color: '#2f3036'}}>
                <TableContainer sx={{borderRadius: '15px'}} component={Paper} style={{marginBottom: '3rem'}}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight: '600',fontFamily: 'Montserrat',textAlign: 'center', border: '0px solid white'  }}>Âge lors de l'arrivée en Israel</TableCell>
                            <TableCell style={{ backgroundColor: '#499BF8', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Âge</TableCell>
                            <TableCell style={{ backgroundColor: '#FFC804', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Médecin/Dentiste</TableCell>
                            <TableCell style={{ backgroundColor: '#9E4AFF', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Sexe</TableCell>
                            <TableCell style={{ backgroundColor: '#FF94CD', fontWeight: '600',fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white' }}>Temps de service</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {fourthTable.map((row, index) => (
                            <TableRow key={index}>
                            <TableCell style={{ fontWeight: '600', fontFamily: 'Montserrat', border: '0px solid white' }}>{row.type}</TableCell>
                            <TableCell style={{ backgroundColor: '#DBEAFF', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col1}</TableCell>
                            <TableCell style={{ backgroundColor: '#FFF8E1', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col2}</TableCell>
                            <TableCell style={{ backgroundColor: '#E4D5FC', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col3}</TableCell>
                            <TableCell style={{ backgroundColor: '#FFD5EF', fontFamily: 'Montserrat', textAlign: 'center', border: '0px solid white'}}>{row.col4}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '15px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                * Les médecins et dentistes censés effectuer un service obligatoire selon le tableau des olim devront donc servir la durée de service nécessaire selon le tableau et avec un statut de « soldat en service obligatoire » et non « soldat de carrière ». 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                Les femmes (médecins ou dentistes) mariées ou mères d'enfant ne sont pas obligées de servir dans les rangs de Tsahal.
                </Typography>
            </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
  };
  
  export default ServiceComplet;
  