import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

const Admin = () => {
  const [users, setUsers] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/user/getUsers');
      setUsers(response.data.users);
    } catch (error) {
      console.error('Error fetching users:', error.message);
    }
  };

  useEffect(() => {
    fetchData();  // Call fetchData when the component mounts
  }, []); // Empty dependency array to run useEffect only on mount

  const handleToggleVerify = async (id, isVerified) => {
    try {
      const endpoint = isVerified ? 'setUnverify' : 'setVerify';
      await axios.put(`http://localhost:5000/api/user/${endpoint}`, { id });
      fetchData();  // Refresh the user data after verification/unverification
    } catch (error) {
      console.error(`Error ${isVerified ? 'unverifying' : 'verifying'} user:`, error.message);
    }
  };

  return (
    <div>
      <div>
        <h1 style={{ fontFamily: 'Montserrat', fontWeight: '600', marginTop: '50px', marginLeft: '3rem' }}>
          Bonjour Raphael 👋
        </h1>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '4rem'}}>
          <TableContainer component={Paper} style={{ marginTop: '20px', width: '80%' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Prénom</TableCell>
                  <TableCell>Nom</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Verifié</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.verify ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color={user.verify ? "secondary" : "primary"}
                        onClick={() => handleToggleVerify(user._id, user.verify)}
                      >
                        {user.verify ? 'Unverify' : 'Verify'}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Admin;
