import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Checkbox, Typography, TextField, Button, Link, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; // Import icons for password visibility
import logo from './../../images/Logo Tsahal Conection.png';
import OTPInput from 'react-otp-input'
import './../../styles/login.css';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to track password visibility
  const [show2FA, setShow2FA] = useState(false);
  const [otp, setOTP] = useState('');
  const [userId, setUserId] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/api/user/login', {
        email,
        password
      });

      console.log(response.data);
      setShow2FA(true);
      setUserId(response.data.userId);
      setError('');
      console.log(show2FA)
    } catch (err) {
      console.log(err);
      setError('Échec de la connexion. Veuillez vérifier votre adresse e-mail et votre mot de passe.');
    }
  };

  const handle2FALogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/api/user/2fa', {
        userId,
        secretOTP: otp,
        rememberMe,
      }, {
        withCredentials: true,
      });
      setOTP('');
      window.location = '/profile';
  } catch (error) {
        console.log(error);
        setError('Échec de la connexion. Veuillez vérifier votre code OTP.');
        setOTP('');
    }
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError('');
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'flex-end' }}>
      <div style={{ maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto' }}>
        <div className='presentation'>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '100px' }}>
            <img src={logo} style={{ height: '70px', width: '70px' }} alt="Logo" />
            <Typography style={{ marginLeft: '1.5rem', fontFamily: 'Archivo', fontSize: '24px' }}>
              Tsahal Connection
            </Typography>
          </div>
          <Typography gutterBottom style={{ fontFamily: 'Public Sans', fontWeight: '500', fontSize: '1.25rem', marginTop: '1.5rem', color: 'rgba(50, 71, 92, 0.87)' }}>
            Bienvenue sur Expert Connection! 👋🏻
          </Typography>
          <Typography gutterBottom style={{ fontFamily: 'Public Sans', fontWeight: '400', fontSize: '1rem', marginTop: '1rem', color: 'rgba(50, 71, 92, 0.6)' }}>
            Veuillez vous connecter à votre compte et commencer l'aventure.
          </Typography>
        </div>
        {show2FA ? (
          <div className="OTP-form">
          <Typography variant="h6" style={{marginBottom: '2rem', marginTop: '2rem'}}>Entrer le code de confirmation.</Typography>
          <div className='OTP-inputs'>
            <OTPInput
              value={otp}
              onChange={setOTP}
              numInputs={6}
              renderSeparator={<span> </span>}
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus
              skipDefaultStyles
            />
          </div>
          <Button
            id="envoyerButton"
            variant="contained"
            color="primary"
            onClick={handle2FALogin}
            style={{marginTop: '2rem', 
                  backgroundColor: otp.length === 6 ? 'rgb(105, 108, 255)' : 'lightgrey', 
                  color : 'white',
                  fontFamily: 'Public Sans', 
                  height: '2.4rem', 
                  borderRadius: '9px',
                  }}
            disabled={!(otp.length === 6)}
          >
            Envoyer
          </Button>
          {error && <Typography style={{ color: 'red', marginTop: '1.5rem' }}>{error}</Typography>}

        </div>
        )
         : 
        (
          <form style={{ marginTop: '1rem' }} onSubmit={handleLogin}>
          <TextField
            color="primary"
            label="Email"
            style={{ marginBottom: '1rem', width: '100%' }}
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            label="Mot de passe"
            type={showPassword ? 'text' : 'password'} // Show/hide password based on state
            style={{ marginBottom: '1rem', width: '100%' }}
            value={password}
            onChange={handlePasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handlePasswordVisibilityToggle}
                    edge="end"
                    style={{color: '#c2c3c4', transform: 'scale(0.9)'}}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && <Typography style={{ color: 'red' }}>{error}</Typography>}
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <Checkbox color="primary" onChange={handleRememberMeChange} />
            <Typography variant="body2" style={{ fontFamily: 'Public Sans', fontWeight: '400', color: 'rgba(50, 71, 92, 0.6' }}>Remember Me</Typography>
          </div>
          <Button
            variant="contained"
            color="primary"
            style={{
              fontFamily: 'Public Sans',
              fontWeight: '600',
              height: '2.4rem',
              width: '100%',
              backgroundColor: 'rgb(105, 108, 255)',
              borderRadius: '9px'
            }}
            type="submit"
          >
            Sign In
          </Button>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem' }}>
            <Typography style={{ fontSize: '1rem', fontFamily: 'Public Sans', fontWeight: '400', color: 'rgba(50, 71, 92, 0.6' }}>Nouveau ici ?</Typography>
            <Link href="/register" style={{ fontSize: '1rem', fontFamily: 'Public Sans', fontWeight: '400', color: '#696cff', textDecoration: 'none' }}>
              Créez un compte
            </Link>
          </div>
        </form>
        )}
      </div>
    </div>
  );
};

export default LoginForm;
