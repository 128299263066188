import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import Navbar from '../../components/Features/Navbar';
import './../../styles/expert-co.css'

const SuiviExpert = () => {
  const [open, setOpen] = useState(true); // Dialog opens automatically on component mount
  const [acceptedGCU, setAcceptedGCU] = useState(false);

  useEffect(() => {
    document.title = 'Suivi Expert | Tsahal Connection';
  }, []);

  const handleAcceptGCU = () => {
    setAcceptedGCU(true);
    setOpen(false); // Close dialog only when GCU is accepted
  };



  return (
    <div>
      <Navbar />
      {acceptedGCU ? (
        <iframe
          src='https://mrng.to/XbgysAYXQt'
          style={{ width: '100%', minHeight: '100vh', border: 'none', marginTop: '1em' }}
        ></iframe>
      ) : (
        <div>
          <Dialog
            open={open}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                setOpen(false);
              }
            }} // Only allow dialog to close for reasons other than clicking outside or pressing escape
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth
          >
            <DialogContent>
              <iframe
                src="https://1drv.ms/b/s!AgVanePzBHI9gakzoesZywhXdYsdkw?embed=1&em=2"
                style={{ width: '100%', height: '500px', border: 'none' }}
                title="GCU Document"
              ></iframe>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAcceptGCU}>Accepter le contrat</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default SuiviExpert;
