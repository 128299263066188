import React from 'react';
import {Typography} from '@mui/material';
import achGadol from './../../images/logos/AchGadol.png';
import KKL from './../../images/logos/KKL.png';
import Nefesh from './../../images/logos/nefeshBeNefesh.png';
import AgafBithoni from './../../images/logos/AgafBithoni.png';
import Masa from './../../images/logos/Masa.png';
import MairieNetanya from './../../images/logos/MairieTLV.svg';
import MairieTLV from './../../images/logos/MairieNetanya.png';
import Qualita from './../../images/logos/Qualita.png';
import GarinTzabar from './../../images/logos/GarinTzabar.png';
import Cnef from './../../images/logos/CNEF.png';


const logosStyle = {
    height: '150px',
    margin: '0 40px',
    aspectRatio: '3/2',
    objectFit: 'contain',
    mixBlendMode: 'color-burn'
}

const logoSlideStyle = {
    display: 'inline-block',
    animation: '25s slide infinite linear'
}

const divlogosStyle = {
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    padding: '60px 0',
    marginTop: '20px'
}

const keyFrameStyle = `
    @keyframes slide {
        from {
            transform: translateX(0)
        }
        to {
            transform: translateX(-100%)
        }
    }
`;


function PartnerCarousel() {
  return (
    <div style={{marginBottom: '90px'}}>
        <div>
        <Typography
        variant="h4"
        component="h1"
        style={{
          fontFamily: 'Montserrat',
          fontWeight: '600',
          textAlign: 'center',
          paddingTop: '5rem',
          marginBottom: '5rem',
          textTransform: 'uppercase'
        }}
      >
        Nos Partenaires
      </Typography>
        </div>
        <div className="logos" style={divlogosStyle}>
            <style>{keyFrameStyle}</style>
            <div className="logo-slide" style={logoSlideStyle}>
                <img src={achGadol} style={logosStyle} alt='ach-gadol-logo' />
                <img src={KKL} style={logosStyle} alt='kkl-logo' />
                {/* <img src={Nefesh} style={logosStyle}/> */}
                {/* <img src={AgafBithoni} style={logosStyle}/> */}
                <img src={Masa} style={logosStyle} alt='masa-logo' />
                {/* <img src={MairieNetanya} style={logosStyle}/>
                <img src={MairieTLV} style={logosStyle}/>
                <img src={GarinTzabar} style={logosStyle}/> */}
                <img src={Qualita} style={logosStyle} alt='qualita-logo'/>
                <img src={Cnef} style={logosStyle}alt='cnef-logo' />
            </div>
            <div className="logo-slide" style={logoSlideStyle}>
                <img src={achGadol} style={logosStyle} alt='ach-gadol-logo' />
                <img src={KKL} style={logosStyle} alt='kkl-logo' />
                {/* <img src={Nefesh} style={logosStyle}/> */}
                {/* <img src={AgafBithoni} style={logosStyle}/> */}
                <img src={Masa} style={logosStyle} alt='masa-logo' />
                {/* <img src={MairieNetanya} style={logosStyle}/>
                <img src={MairieTLV} style={logosStyle}/>
                <img src={GarinTzabar} style={logosStyle}/> */}
                <img src={Qualita} style={logosStyle} alt='qualita-logo'/>
                <img src={Cnef} style={logosStyle}alt='cnef-logo' />
            </div>
            <div className="logo-slide" style={logoSlideStyle}>
                <img src={achGadol} style={logosStyle} alt='ach-gadol-logo' />
                <img src={KKL} style={logosStyle} alt='kkl-logo' />
                {/* <img src={Nefesh} style={logosStyle}/> */}
                {/* <img src={AgafBithoni} style={logosStyle}/> */}
                <img src={Masa} style={logosStyle} alt='masa-logo' />
                {/* <img src={MairieNetanya} style={logosStyle}/>
                <img src={MairieTLV} style={logosStyle}/>
                <img src={GarinTzabar} style={logosStyle}/> */}
                <img src={Qualita} style={logosStyle} alt='qualita-logo'/>
                <img src={Cnef} style={logosStyle}alt='cnef-logo' />
            </div>
        </div>
    </div>
  );
}

export default PartnerCarousel;
