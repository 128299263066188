import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const Miyunim = () => {

    useEffect(() => {
        document.title = 'Miyunim | Tsahal Connection';
      }, []);
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Convocations</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Miyunim</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '2rem', fontWeight: '500', color: '#2f3036'}}>
                    L’étape des entretiens avancés (miyunim) est une étape cruciale du processus d’enrôlement à Tsahal. Les futurs soldats sont invités à des sélections en fonction des résultats du <Link to="/tsav-rishon" style={{color: '#1398aa'}}>Tsav Rishon</Link>, du <Link to="/yom-hamea" style={{color: '#1398aa'}}>Yom Hamea</Link> et du <Link to="/cheelon-aadafot" style={{color: '#1398aa'}}>Cheelon Aadafot</Link> (si les candidats n’intègrent pas l’armée via Mihva Alon).
                    Ces tests varient en fonction des différentes unités et peuvent comprendre des entretiens informatisés, des tests de personnalité, des tests de groupe et autre.
                    Après avoir rempli le Cheelon Aadafot, les candidats recevront un formulaire sur les hobbies que nous recommandons fortement de répondre.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                   Les sessions de tests
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036' }}>
                    Il existe deux sessions de tests (appelé Hatsion) :
                    <ul>
                        <li>Hatsion Alef : incorporation entre juillet et novembre.</li>
                        <li>Hatsion Beth : incorporation entre décembre et avril.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                    En général, chaque test ou chaque parcours dans Tsahal qui nécessite un entretien à sa chronologie et il est recommandé de vérifier directement les conditions du poste en question.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                    Les demandes pour repousser l’entrée dans les rangs de Tsahal se font jusqu’au 31 mars de la classe de youd beth et pour les programme pré-armée comme les Mechinot, Chnat Chirout ou Yeshiva, cela permet de décaler les options de tests pour une année supplémentaire.<br />
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                    La sélection pour le poste de pilote est une sélection obligatoire (pour les garçons uniquement) et dans le cas où les candidats manquent à l’appel, ils ne pourront pas se présenter à d’autres sélections pour d’autres postes. Pour les candidats ne souhaitant pas servir en tant que pilote, il faut donc se déplacer à la première journée d’entretien et faire une demande d’abandon pour le poste.<br />
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    De manière générale, la sélection pour <Link to="/atouda-academique" style={{color: '#1398aa'}}>Atouda Académique</Link> ne bloque aucune autre sélection.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                   Le calendrier des tests
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', marginBottom: '0.5rem', fontWeight: '700'}}>
                    Branche des renseignements
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    <strong>Hatsion Alef -</strong> il faut effectuer la demande pas plus tard que le 14/08 soit juste après la fin de la classe de youd alef. Les tests s’effectueront entre le mois de juin et octobre (entre la classe de youd alef et de youd beth).
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    <strong>Hatsion Beth -</strong> il faut effectuer la demande entre janvier et mars de la classe de youd beth. Les tests s’effectueront entre le mois de décembre et d’avril de la classe de youd beth.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1.5rem', color: '#2f3036'}}>
                    Il est possible de recevoir une convocation pour Modiin si le parcours scolaire est adéquat mais dans le cas où un candidat a des connaissances (en informatique par exemple) et cela n’est pas référé via le niveau scolaire, il peut légitimement effectuer une demande pour intégrer Modiin.<br />
                    Depuis 2021, les tests pour Modiin ne gèlent pas la possibilité d’effectuer les tests du Yom Sayerot, la chorale militaire, la radio militaire et les parcours Atouda.                  
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', marginBottom: '0.5rem', fontWeight: '700'}}>
                    Postes non-combattants
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem'}}>
                    <strong>Hatsion Alef -</strong> possibilité de recevoir des tests entre décembre et avril de la classe de youd beth.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1.5rem', color: '#2f3036' }}>
                    <strong>Hatsion Beth -</strong> possibilité de recevoir des tests entre juillet et septembre qui suit la classe de youd beth.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', marginBottom: '0.5rem', fontWeight: '700'}}>
                Postes combattants garçons
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '0.5rem',color: '#2f3036' }}>
                    <strong>Janvier -</strong> Yom Sayerot & Gibbush Hovlim/Tsolelot.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '0.5rem', color: '#2f3036'}}>
                    <strong>Février -</strong> Kenes & gibbush Tays, gibbush Snapir, journée de découverte & gibbush Oketz, tests combattants marins, gibbush Hovlim/Tsolelot.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '0.5rem', color: '#2f3036'}}>
                    <strong>Mars -</strong> Gibbush Oketz, gibbush Shayetet 13, gibbush Matkal, Draft Commando.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '0.5rem', color: '#2f3036'}}>
                    <strong>Avril -</strong> Shavoua Reayonot (unités d’élites), gibbush Tsanhanim, tests combattants marins, gibbush Snapir, gibbush Hovlim/Tsolelot.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '0.5rem', color: '#2f3036'}}>
                    <strong>Mai -</strong> Gibbushon Hovlim, Gibbushon Tsolelot.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '0.5rem', color: '#2f3036'}}>
                    <strong>Aout -</strong> Tests combattants marins.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '0.5rem', color: '#2f3036'}}>
                    <strong>Octobre -</strong> Yom Sayerot, gibbush Tsolelot, gibbush Hovlim/Tsolelot, tests combattants marins.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '0.5rem', color: '#2f3036'}}>
                    <strong>Novembre -</strong> Gibbush Matkal, Gibbush Shayetet 13, tests combattants marins, gibbush Tays, gibbush Snapir.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '0.5rem', color: '#2f3036'}}>
                    <strong>Decembre -</strong> Shavoua Reayonot (unités d’élites), gibbush Tsanhanim, gibbushon Hovlim, gibbushon Tsolelot.
                </Typography>
            </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
  };
  
  export default Miyunim;
  