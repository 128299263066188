import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import { UidContext } from "./../../components/Log/AppContext"
import axios from 'axios';
import {Box, CircularProgress, Container, Grid, Paper, Typography, Button } from '@mui/material';
import { Row, Col, Card } from 'react-bootstrap';
import NavbarLog from './NavbarLog';
import mathsIMG from './../../images/member-space/testmath.jpg'
import visuIMG from './../../images/member-space/testlogique.jpg'
import logiqueIMG from './../../images/member-space/testvisu.jpg'
import geometrieIMG from './../../images/member-space/testgeometri.jpg'
import verbaleIMG from './../../images/member-space/testverbale.jpg'
import illutest from './../../images/member-space/illutest.jpg'
import './../../styles/profile.css'


const Profile = () => {
    const uid = useContext(UidContext);
    console.log(uid);
    const navigate  = useNavigate();

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeSet, setActiveSet] = useState(1);

  const handleSetChange = (setNumber) => {
    setActiveSet(setNumber);
  };


  const handleClickComp = () => {
    window.location = '/test-comprehension'
  }
    

  useEffect(() => {
    const fetchUser = async () => {
      try {
        console.log(uid);
        const response = await axios.get(`http://localhost:5000/api/user/getuserbyid/${uid}`);
        setUser(response.data.user);
        console.log(user)
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (uid) {
      console.log(uid)
      fetchUser();
    }
  }, [uid]);

  if (loading) {
    if (!uid) {
        navigate('/signup');
    }
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '100vh', alignItems: 'center'}}>
          <CircularProgress />
        </Box>
      );    
}

if (!uid) {
    setLoading(false);
    navigate('/signup');
    return;
}


return (
  
  <div className='profile'>
    <NavbarLog />
    <ParallaxProvider>
      <Parallax y={[-20, 20]} tagOuter="figure">
    <Container className='home-container' component="main" maxWidth="xxl" sx={{ marginTop: 4, marginLeft: 0, marginRight: 0}} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
      <h1>Bienvenue sur l'espace membre d'Expert Connection</h1>
      <Button>Tes resultats</Button>
    </Container>
    </Parallax>
    <Parallax y={[-20, 20]} speed={10} tagOuter="figure">
    <Container className='tests-container' component="main" maxWidth="xl" sx={{ marginTop: -2}}>
    <Typography variant='body1' style={{
      color:'#a8a8a8k', 
      padding: '10px',
      fontFamily: 'DM Sans',
      fontSize: '2rem',
      fontWeight: '500',
      marginBottom: '0',
      paddingBottom: '0',
      letterSpacing: '1.5px'
    }}>
      Bonjour {user.firstName}👋<br />
      Quels tests veux-tu faire aujourd'hui ?
    </Typography>
    <hr />
    <Container maxWidth="md" style={{ marginTop: '3rem' }}>
                <Row>
                    <Col md={12} xs={12} className="d-flex flex-row flex-wrap align-items-center justify-content-lg-center" style={{marginBottom: '3rem'}}>
                        <Button
                            variant="outline-secondary"
                            style={{
                                borderRadius: '20px',
                                border: '1px solid #6c757d',
                                margin: '5px',
                                color: activeSet === 1 ? 'black': '#e0e0e0',
                                backgroundColor: activeSet === 1 ? '#f8f9fa' : 'transparent',
                                }}
                            onClick={() => handleSetChange(1)}
                        >
                            🇫🇷 Francais
                        </Button>
                        <Button
                            variant="outline-secondary"
                            style={{
                                borderRadius: '20px',
                                border: '1px solid #6c757d',
                                margin: '5px',
                                color: activeSet === 2 ? 'black': '#e0e0e0',
                                backgroundColor: activeSet === 2 ? '#f8f9fa' : 'transparent',
                                }}
                            onClick={() => handleSetChange(2)}
                        >
                            🇬🇧 English
                        </Button>
                        <Button
                            variant="outline-secondary"
                            style={{
                                borderRadius: '20px',
                                border: '1px solid #6c757d',
                                margin: '5px',
                                color: activeSet === 3 ? 'black': '#e0e0e0',
                                backgroundColor: activeSet === 3 ? '#f8f9fa' : 'transparent',

                                }}
                            onClick={() => handleSetChange(3)}
                        >
                            🇮🇱 עברית
                        </Button>
                    </Col>
                </Row>
            </Container>  
              <Row xs={1} md={3} className="g-4" style={{marginBottom: '4rem', display: 'flex', justifyContent: 'center'}}>
    { activeSet === 3 ? 
    (<Col>
      <Card style={{ background: `url(${verbaleIMG})`, backgroundSize: 'cover', height: '200px', color: 'white', position: 'relative', border: '0px', borderRadius: '20px' }}>
        <Card.Body style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
          <Typography variant="h5" component="div" align="left" style={{
            backgroundColor: 'white',
             color:'black', 
             width: 'fit-content', 
             borderRadius: '20px', 
             padding: '10px',
             fontFamily: 'Rubik',
             fontSize: '18px',
             fontWeight: '600'
             }}>
              אנלוגיות מילוליות          
          </Typography>
        </Card.Body>
      </Card>
    </Col>) : null}
    <Col>
      <Card style={{ background: `url(${mathsIMG})`, backgroundSize: 'cover', height: '200px', color: 'white', position: 'relative', border: '0px', borderRadius: '20px' }} onClick={handleClickComp}>
        <Card.Body style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
          <Typography variant="h5" component="div" align="left" style={{
            backgroundColor: 'white',
             color:'black', 
             width: 'fit-content', 
             borderRadius: '20px', 
             padding: '10px',
             fontFamily: activeSet === 3 ? 'Rubik' : 'DM Sans',
             fontSize: '18px',
             fontWeight: '600'
             }}>
            {activeSet === 1 ? "Compréhension des consignes" : activeSet === 2 ? "Following instructions" : "מבחן הוראות"}
          </Typography>
        </Card.Body>
      </Card>
    </Col>
    <Col>
      <Card style={{ background: `url(${illutest})`, backgroundSize: 'cover', height: '200px', color: 'white', position: 'relative', border: '0px', borderRadius: '20px' }}>
        <Card.Body style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
          <Typography variant="h5" component="div" align="left" style={{
            backgroundColor: 'white',
             color:'black', 
             width: 'fit-content', 
             borderRadius: '20px', 
             padding: '10px',
             fontFamily: activeSet === 3 ? 'Rubik' : 'DM Sans',
             fontSize: '18px',
             fontWeight: '600'
             }}>
            {activeSet === 1 ? "Analogies géometriques" : activeSet === 2 ? "Figural Reasoning" : "אנלוגיות צורניות"}
          </Typography>
        </Card.Body>
      </Card>
    </Col>
  </Row>
  <Row xs={1} md={3} className="g-4" style={{marginBottom: '4rem', display: 'flex', justifyContent: 'center'}}>
  <Col>
      <Card style={{ background: `url(${visuIMG})`, backgroundSize: 'cover', height: '200px', color: 'white', position: 'relative', border: '0px', borderRadius: '20px' }}>
        <Card.Body style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
          <Typography variant="h5" component="div" align="left" style={{
            backgroundColor: 'white',
             color:'black', 
             width: 'fit-content', 
             borderRadius: '20px', 
             padding: '10px',
             fontFamily: activeSet === 3 ? 'Rubik' : 'DM Sans',
             fontSize: '18px',
             fontWeight: '600'
             }}>
            {activeSet === 1 ? "Réflexion spatiale" : activeSet === 2 ? "Spatial analogies" : "חשיבה צורנית"}
          </Typography>
        </Card.Body>
      </Card>
    </Col>
    <Col>
      <Card style={{ background: `url(${geometrieIMG})`, backgroundSize: 'cover', height: '200px', color: 'white', position: 'relative', border: '0px', borderRadius: '20px' }}>
        <Card.Body style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
          <Typography variant="h5" component="div" align="left" style={{
            backgroundColor: 'white',
             color:'black', 
             width: 'fit-content', 
             borderRadius: '20px', 
             padding: '10px',
             fontFamily: activeSet === 3 ? 'Rubik' : 'DM Sans',
             fontSize: '18px',
             fontWeight: '600'
             }}>
              {activeSet === 1 ? "Problèmes arithmétiques" : activeSet === 2 ? "Quantitative reasoning" : "כמותי"}
          </Typography>
        </Card.Body>
      </Card>
    </Col>
  </Row>
  <hr />
  <Row xs={1} md={2} className="g-4" style={{marginTop: '5rem', marginBottom: '5rem', display: 'flex', justifyContent: 'center'}}>
  <Col>
      <Card style={{ background: `url(${logiqueIMG})`, backgroundSize: 'cover', height: '300px', color: 'white', position: 'relative', border: '0px', borderRadius: '20px' }}>
        <Card.Body style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
          <Typography variant="h5" component="div" align="left" style={{
            backgroundColor: 'white',
             color:'black', 
             width: 'fit-content', 
             borderRadius: '20px', 
             padding: '10px',
             fontFamily: activeSet === 3 ? 'Rubik' : 'DM Sans',
             fontSize: '18px',
             fontWeight: '600'
             }}>
              {activeSet === 1 ? "Simulation" : activeSet === 2 ? "Simulation" : "סימולציה"}
          </Typography>
        </Card.Body>
      </Card>
    </Col>
    <Col>
    <Typography variant='body1' style={{
      color:'#a8a8a8k', 
      padding: '10px',
      fontFamily: activeSet === 3 ? 'Rubik' : 'DM Sans',
      fontSize: '1.5rem',
      fontWeight: '500',
      marginBottom: '0',
      paddingBottom: '0',
      letterSpacing: '1.5px'
    }}>
    {activeSet === 1 ? 
        "Plongez dans une expérience authentique avec notre simulation de test psychotechnique, reproduisant fidèlement le défi du Tsav Richon. Évaluez vos compétences sous pression, affinez vos réflexes et maximisez votre préparation pour exceller le jour de l'examen. Prêt à relever le défi ? Testez-vous maintenant ! 🚀 " 
    : activeSet === 2 ? "Dive into an authentic experience with our psychometric test simulation, faithfully replicating the challenge of the Tsav Richon. Assess your skills under pressure, hone your reflexes, and maximize your preparation to excel on exam day. Ready to take on the challenge? Test yourself now! 🚀" 
    : "התעממו בחוויית סימולציה מקצועית עם הסימולציה שלנו למבחן הפסיכומטרי, המדמה באופן נאמן את אתגר הצב רישון. הערכו את היכולות שלכם תחת לחץ, תפלו את הרפלקסים שלכם ומקסימיזו את ההכנה שלכם כדי להצליח ביום המבחן. מוכנים לקבל את האתגר? בדקו את יכולתכם עכשיu" }
      </Typography>
    </Col>
  </Row>

    </Container>
    </Parallax>
    </ParallaxProvider>
  </div>
);
};

export default Profile;