import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS  
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const YomHamea = () => {

    

    useEffect(() => {
        document.title = 'Yom Hamea | Tsahal Connection';
    }, []);
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Convocations</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Yom Hamea</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '4rem', fontWeight: '500', color: '#2f3036'}}>
                    Le Yom Hamea est une journée de tests en ligne qui concerne la majorité des futurs soldats. Elle se déroule après le <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link>  pour les candidats qui ont reçues une note en hébreu de 7 ou 8. Dans certains cas, les candidats possédant 6 peuvent recevoir une convocation pour le Yom Hamea s’ils sont plus d’un certain temps en Israël cependant il est conseillé de vérifier s’il n’y a pas une erreur de Tsahal.<br />
                    Les candidats incorporant Tsahal via la formation d’hébreu de <Link to='/michve-alon' style={{color: '#1398aa'}}>Michve Alon</Link> peuvent éventuellement faire cette journée pendant cette période.<br />
                    Le but du Yom Hamea est de jauger de manière plus approfondie les différentes recrues. Celles-ci sont évaluées sur plusieurs points et recevront par la suite une note comprise entre 1 (minimum) et 5 (maximum).<br />
                    Cette journée est très importante, spécialement pour ceux ou celles souhaitant servir dans des unités non-combattantes. Il est possible de recevoir une préparation individuelle via notre branche <Link to='/expert-connection' style={{color: '#1398aa'}}>Expert Connection</Link> .<br />
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                    Le programme de la journée
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        Il existe quatres épreuves lors de cette journée:
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        ✅ Test sur l’ordinateur.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        ✅Test d’instruction.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        ✅ Test de groupe.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        ✅ Simulation de situation réelle.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '4rem' }}>
                        Les candidats recevront un lien d’inscription à confirmer la veille du Yom Hamea. Le jour même, un lien sera envoyé et les candidats commenceront par le test sur l’ordinateur. Par la suite, les candidats effectueront le reste des tests et simulations en groupe via Zoom.<br />
                        Il est recommandé de bien dormir la veille, d’avoir à manger et à boire pendant la journée à porter de main et surtout d’effectuer cette journée dans un cadre calme et avec une connexion internet de qualité. Cette journée commence vers 8h30 et termine en moyenne vers 15h.<br />
                        Pour les candidats ne possédant pas un cadre idéal pour effectuer cette journée, il est possible d’effectuer cette journée dans des salles informatisées à Tel Aviv, Haïfa, Beer Shéva ou Jérusalem.                    
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        Decryptage des notes
                    </Typography>
                    <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#EBEAFF', borderColor: '#EBEAFF', marginBottom: '3rem'}}>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', color: '#2f3036', marginBottom: '1rem', fontWeight: '600', padding: '20px' }}>
                        ⚠️ Dans le cas où les notes ne représenteraient pas les capacités du candidat, il est possible d’effectuer une demande via le site Mitgaisim pour effectuer de nouveau cette journée. 
                        Les documents à fournir sont disponible sur le site Mitgaisim et la réévaluation concernera certaines notes uniquement. 
                        </Typography> 
                    </div>
                     <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                     Les candidats recevront quelques jours plus tard treize notes différentes variant entre <strong>1</strong> (minimum) à <strong>5</strong> (maximum).
                     <ul>
                        <li><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>הדרכה -</strong></span> Instruction.</li>
                        <li><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>הפעלת טכנית -</strong></span> Maitrise technique.</li>
                        <li><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>ניהול וארגון -</strong></span> Gestion et organisation.</li>
                        <li><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>פיקוד -</strong></span> Commandement.</li>
                        <li><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>טיפול באדם -</strong></span> Empathie et relation inter-personelle.</li>
                        <li><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>עיבוד מידע -</strong></span> Traitement de l’information.</li>
                        <li><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>עבודת צוות -</strong></span> Travail d’équipe.</li>
                        <li><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>קשב מתמשך -</strong></span> Attention soutenue.</li>
                        <li><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>השקעה והתמדה -</strong></span> Investissement et persévérance.</li>
                        <li><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>התנהגות מסגרתית -</strong></span> Comportement dans un groupe.</li>
                        <li><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>בגרות -</strong></span> Maturité.</li>
                        <li><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>בשלות -</strong></span> Sérénité.</li>
                        <li><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>תפיסת מרחב -</strong></span> Perception spatiale.</li>
                    </ul>


                    </Typography>
                </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
};

export default YomHamea;
