import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS  
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const StatutResident = () => {

    

    useEffect(() => {
        document.title = 'Statuts de résident | Tsahal Connection';
    }, []);
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Annexes</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Statuts de résident</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '3rem', fontWeight: '500', color: '#2f3036'}}>
                    Il existe plusieurs types de statuts en tant que résident en Israël, voici les principaux. Pour plus d’informations sur les droits et les aides, contactez le ministère de l’intérieur Israélienne ou les associations de la communauté comme le CNEF ou Qualita.
                    Le statut de chaque résident en Israël influence sur l'obligation et la durée du service militaire dans les rangs de Tsahal.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>      
                        Egibilité à la nationalité israélienne​
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        L’année de préparation militaire fonctionne sur un report du service militaire. Il faut donc avoir une première date d’enrôlement pour participer à ces programmes, exception faite pour les nouveaux immigrants arrivants n’ayant pas encore de date d’enrôlement. De plus il est recommandé d’avoir un bon niveau d’hébreu pour être accepté dans ces programmes. 
                        La loi du retour stipule que tout juif, son conjoint, ses enfants et ses petits-enfants ont le droit de faire l’Alya (sauf exception énumérée dans la loi du retour).
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    Une personne dont les parents, les deux ou l’un d’entre eux  sont Israéliens, a droit à la citoyenneté israélienne, tant qu’elle est née en Israël. Si cette personne est née hors d’Israël, elle aura toujours droit à la citoyenneté israélienne, mais si elle donne naissance à des enfants hors d’Israël, ses enfants n’auront pas droit à la citoyenneté israélienne. En d’autres termes, la ״loi du sang” est limitée à une génération. Ces enfants obtiendront facilement la citoyenneté israélienne, grâce à la loi du retour.  Souvent, une personne qui est un citoyen étranger et l’un de ses parents était israélien, est considérée comme un citoyen israélien à son insu. Cela pose des problèmes lorsqu’il entre en Israël, car un citoyen israélien doit présenter un passeport israélien à son entrée dans le pays et qu’il doit réglementer son service militaire.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        Il est donc important de comprendre son propre statut avant de savoir quel type de service effectuer. De plus, les personnes possédant la nationalité israélienne via les parents devront régulariser leurs statuts au consulat israélien de leur pays de résidence. Il faut idéalement régulariser cela à 16 ans et 4 mois pour être en règle avec Israël et Tsahal (même si la personne concernée ne souhaite pas faire la alyah).
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    Les Israéliens quittant Israël avec des enfants et dont ceux-ci ont moins de 16 devront régulariser immédiatement la situation auprès du consulat israélien du nouveau pays de résidence. Il sera sûrement demandé des autorisations d’études afin de prouver que le but de ce départ n’était pas d’éviter aux enfants de servir dans Tsahal. Si les Israéliens ayant des enfants quittent le territoire après les 16 ans, les enfants peuvent être considérés comme déserteurs et ainsi engendrer des problèmes juridiques importants. 
                    L’inscription se fait donc directement auprès des Mechinot. Après avoir passé les tests entre novembre et février, les directeurs des Mechinot vont envoyer les listes des participants à la branche sociale-sécurité du ministère de la Défense (Agaf Hevrati Bithoni). Ceux-ci vont ensuite faire le lien avec l’unité Meitav (en charge des nouvelles recrues) de Tsahal afin d’avoir une autorisation de report du service.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    Il existe deux cas de figure pour s’enrôler dans les Mechinot pour les nouveaux immigrants :
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                        <ul>
                            <li><strong>Avoir fait la aliyah tôt (avant ses 18 ans) et recevoir une date d’enrôlement après le baccalauréat :</strong> le processus est le même que les Israéliens et les candidats doivent idéalement faire les tests pour la Mechina entre novembre et février. Par la suite ils recevront une autorisation et pourront commencer la Mechina (OFEK ou annuelle) en août.</li>
                            <li><strong>Avoir effectué la aliyah après ses 18 ans et avoir un baccalauréat étranger :</strong> un nouvel immigrant qui a déjà une date d’enrôlement devra s’inscrire à une Mechina comme les Israéliens et valider les tests (entre novembre et février). Un nouvel immigrant a droit à une année d’intégration par Tsahal dans laquelle le candidat ne recevra pas de convocation et donc n’a pas de date d’enrôlement. Il peut effectuer les tests pour une Mechina pendant cette période à condition que le timing corresponde. </li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        Les différents status de résidents en Israël​
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '1.5rem', fontWeight: '700' }}>
                        Touriste
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1.5rem' }}>
                        Le statut de touriste concerne les personnes qui ne possèdent pas la nationalité israélienne ou ne sont pas éligibles à recevoir la nationalité israélienne via les parents. Ce statut permet de visiter en Israël jusqu’à 3 mois (sans visa particulier) ou plus (visa Massa/études etc.).                 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '1rem', fontWeight: '700' }}>
                        Ben Chaliah
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        Un Ben Chaliah est un Israélien résidant à étranger avec au moins un de ses deux parents et dont celui-ci représente une organisation d’état uniquement. Des demandes spéciales seront à soumettre à l’unité Meitav, qui évaluera la demande. Il faut faire attention aux choses suivantes : 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        <ul>
                            <li>Si le futur soldat (Ben Chaliah) réside avec ses parents en dehors d’Israël, dans le cadre d’une représentation ou d’une organisation d’état, il lui sera possible de repousser son service militaire jusqu’à l’âge de 20 ans ou jusqu’à la fin de leur mission (le plus tôt des deux).</li>
                            <li>Le futur soldat doit nécessairement apporter une preuve de résidence principale en dehors d’Israël (pour lui et sa famille). </li>
                            <li>Il est interdit au futur soldat d’effectuer des études supérieures en dehors d’Israël. </li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        Afin de régulariser son statut auprès de l’armée, ce futur soldat devra s’adresser aux services destinés aux futurs soldats vivant à l’étranger, à l’ambassade/consulat israélien le plus proche de son lieu de résidence, ou bien s’adresser au bureau de recrutement le plus proche (dans le cas où il est en Israël).
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '1.5rem', fontWeight: '700' }}>
                        Nouvel immigrant – Olé Hadash
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1.5rem' }}>
                        Un nouvel immigrant est une personne n’ayant jamais eu la nationalité israélienne et qui obtient la nationalité israélienne via la loi du retour. Ce statut permet de recevoir des aides et il faut contacter l’agence juive ou les associations comme Qualita afin de savoir ces aides. La durée de service militaire dépend de l’âge d’arrivée et du statut marital.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '1.5rem', fontWeight: '700' }}>
                        Le citoyen immigrant – Ezrah Olé
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1.5rem' }}>
                        Le citoyen immigrant est celui qui est né à l’étranger d’un parent israélien, et qui aurait eu droit au statut d’Olé selon la loi du Retour, si ce n’était qu’il était déjà israélien.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '1.5rem', fontWeight: '700' }}>
                        Mineur de retour – Katin Hozer
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1.5rem' }}>
                        Un jeune est considéré comme mineur de retour s’il a quitté Israël avec un de ses parents, ou qui a rejoint un de ses parents habitant à l’étranger avant l’âge de 14 ans, et revient définitivement après l’âge de 17 ans révolus, en ayant séjourné à l’étranger au moins 4 ans et qui au moment de son retour aurait eu droit à un visa d’Olé au titre de la Loi du retour de 1950 s’il n’était pas déjà israélien.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '1.5rem', fontWeight: '700' }}>
                        Le citoyen de retour – Tochav Hozer
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                        C’est un citoyen israélien ayant résidé à l’étranger pendant au moins deux ans.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>      
                        Le cas du Ben Meager
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '1.5rem', fontWeight: '500', color: '#2f3036'}}>
                        Un Ben Meager est un citoyen israélien né en dehors d’Israël ou ayant quitté Israël avec ses parents avant l’âge de 16 ans. Il réside à l’étranger.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '1.5rem', fontWeight: '700' }}>
                        Règle pour le Ben Meager
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        ✔️ Si l’un des parents vit en Israël, le futur soldat devra prouver qu’il est sous la tutelle du parent vivant en dehors d’Israël.                 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        ✔️ Si les deux parents vivent en Israël, le candidat ne sera pas reconnu comme Ben Meager. 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        ✔️ Un futur soldat n’étant pas éligible au statut de Ben Meager aura la possibilité de déposer une demande de report service militaire afin de finir ses études secondaires (lycée). 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1.5rem' }}>
                        ✔️ Un futur soldat qui n’est pas reconnu Ben Meager n’aura pas la possibilité de solliciter une demande de report et sera obligé de rentrer en Israël. 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '1.5rem', fontWeight: '700' }}>
                        ​​​Durée de visites en Israël en tant que Ben Meager 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1.5rem' }}>
                    Un Ben Meager ne perdra pas son statut en cas de visite en Israël à condition que la durée de son séjour ne dépasse pas les 120 jours par an, à l’exception d’une visite unique appelée « Année de séjour ». Cette « Année de séjour » représente un séjour en Israël continu d’une durée allant de 121 à 365 jours. Cette année de séjour est conditionnée par le séjour du Ben Meager d’une durée de 60 jours en dehors d’Israël avant son « année de séjour » et qu’il séjourne également 60 jours en dehors d’Israël à la suite de cette « Année de séjour ».
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '1.5rem', fontWeight: '700' }}>
                    Études secondaires en dehors d’Israël (Lycée) 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        Un Ben Meager ne perdra pas son statut s’il effectue ses études secondaires en Israël aux conditions suivantes : 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        Un Ben Meager ne perdra pas son statut s’il effectue ses études secondaires en Israël aux conditions suivantes : 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        ✔️ Ses parents vivent en dehors d’Israël, et il émigre avec eux avant ses 13 ans.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        ✔️ ll n’a pas séjourné en Israël plus de deux ans pour des études secondaires.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '600', color: '#2f3036', marginBottom: '1.5rem' }}>
                    ⚠️ Attention, les études secondaires en Israël sont considérées comme une « année de séjour » (Voir ci-dessus). 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '1.5rem', fontWeight: '700' }}>
                        Études supérieures ou études en Yeshiva Gvoa en Israël 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '0.5rem' }}>
                        Un Ben Meager ne perdra pas son statut s’il effectue ses études secondaires en Israël aux conditions suivantes : 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '0.5rem' }}>
                        Un Ben Meager peut effectuer des études supérieures en Israël sans perdre son statut selon les années d’études à effectuer ainsi que jusqu’à une année de mechina (préparation académique) si besoin, qui ne dépasseront pas 4 ans en tout, selon les conditions suivantes : 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '0.5rem' }}>
                        ✔️ Si ses parents vivent en Israël.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '0.5rem' }}>
                        ✔️ Il a commencé ses études à 17 ans minimum.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '0.5rem' }}>
                        ✔️ S’il est Ben Meager et qu’il est né en dehors d’Israël ou qu’il a émigré avec ses parents avant ses 10 ans.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '0.5rem' }}>
                        ✔️ À vécu en dehors d’Israël et n’a pas utilisé son « année de séjour », à moins qu’il l’ait utilisé dans le cadre d’un programme connu du ministère de l’Alyah et de l’intégration. 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '0.5rem' }}>
                        ✔️ S’il a été reçu dans un établissement académique reconnu par l’État ou une yeshiva reconnue par le conseil des yeshivot. 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '0.5rem' }}>
                        ✔️ Il faut envoyer à l’unité Meitav un document de l’établissement académique contenant les dates de début et de fin précises des études. 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1.5rem' }}>
                        ✔️ Jusqu’à 4 ans d’études académiques (licence ou master) ou 4 ans d’études en yeshiva en Israël.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '1.5rem', fontWeight: '700' }}>
                    ​Service réduit – Mahal
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1.5rem' }}>
                        Un Ben Meager qui est né à l’étranger et a émigré avec ses parents avant l’âge de 10 ans pourra se porter volontaire à un service militaire réduit de 18 mois (<Link to="/mahal" style={{color: '#1398aa'}} target="_blank">Mahal</Link>). S’il décide de rester en Israël ou s’il quitte Israël, mais revient dans les 2 ans à partir de la fin de son service, il est contraint de compléter un service militaire complet. 
                    </Typography>
    
                </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
};

export default StatutResident;
