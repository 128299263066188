import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography, Container } from "@mui/material";
import axios from "axios";
import { UidContext } from "../../components/Log/AppContext";

const ConsignesTest2 = () => {
  const [showDialog, setShowDialog] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [userAnswers, setUserAnswers] = useState([
            {"questionNumber":"1", "choice": ""},
            {"questionNumber": "2", "choice" :""},
            {"questionNumber":"3", "choice": ""},
            {"questionNumber":"4", "choice":""},
            {"questionNumber": "5","choice": ""},
            {"questionNumber": "6", "choice": ""},
            {"questionNumber": "7", "choice": ""},
            {"questionNumber": "8", "choice": ""},
            {"questionNumber": "9", "choice": ""},
            {"questionNumber": "10", "choice": ""},
            {"questionNumber": "11", "choice": ""},
            {"questionNumber": "12", "choice": ""}
  ]);
  const [showResults, setShowResults] = useState(false);


  const uid = useContext(UidContext);


  const handleClose = async () => {
    setShowDialog(false);
    const category = "Following Instruction";
    const number = 2;

    try {
      const response = await axios.get(`http://localhost:5000/api/quizzes/gettest/${category}/${number}`);
      const sortedQuestions = response.data.questions.sort((a, b) => a.questionNumber - b.questionNumber);
      setQuestions(sortedQuestions);
    } catch (error) {
      console.error("Erreur lors de la requête au serveur", error);
    }
  };

  const handleDialogClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    handleClose();
  };

  const handleNextQuestion = () => {
    if (selectedAnswer !== null) {
      setUserAnswers((prevAnswers) =>
        prevAnswers.map((answer) =>
          answer.questionNumber === questions[currentQuestionIndex].questionNumber
            ? { ...answer, choice: selectedAnswer }
            : answer
        )
      );
    }
  
    setSelectedAnswer(null);
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handleAnswerClick = (answer) => {
    setUserAnswers((prevAnswers) =>
      prevAnswers.map((prevAnswer) =>
        prevAnswer.questionNumber === questions[currentQuestionIndex].questionNumber
          ? { ...prevAnswer, choice: answer }
          : prevAnswer
      )
    );
    setSelectedAnswer(answer);
  };
  

  const handlePreviousQuestion = () => {
    setSelectedAnswer(null);
    setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };
  
  const handleSubmit = async () => {
    try {
      const response = await axios.post('http://localhost:5000/api/quizzes/checktest', {
        category: 'Following Instruction',
        number: 2,
        answers: userAnswers,
      });
  
      // Handle the response as needed
      console.log(response.data);
  
      // Make another POST request if the first one succeeds
      const secondResponse = await axios.post('http://localhost:5000/api/user/addquizz', {
        id: uid,
        results: response.data.results,
        ratio: response.data.ratio,
      });
  
      // Handle the second response as needed
      console.log('Second submission successful:', secondResponse.data);
      setShowResults(true);
      setCurrentQuestionIndex(0);
    } catch (error) {
      console.error('Error submitting the quiz:', error);
    }
  };

  console.log(userAnswers);

  return (
    <div>
      {showDialog === true ? (
        <div>
          <Dialog open={showDialog} onClose={handleDialogClose}>
            <DialogTitle>Bienvenue sur le test n°2 de la categorie Compréhension des Consignes</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Ce test est composé de 12 questions.
              </DialogContentText>
              <DialogContentText>
                Pour chaque question, seulement une réponse est correcte.
              </DialogContentText>
              <DialogContentText>
                Voulez-vous commencer ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Oui
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : 
      showResults === true ?
      (<div>
            <Container maxWidth='md' style={{
                display: 'flex',
                justifyContent: 'center', 
                alignItems: 'center', 
                marginTop: '2rem',
                backgroundColor: '#DFD4ED',
                borderRadius: '30px',
                padding: '40px'}}>
            {questions.length > 0 && currentQuestionIndex < questions.length ? (
                <div>
                <h3> Question {questions[currentQuestionIndex].questionNumber} / 12{' '} {questions[currentQuestionIndex].correctAnswer === userAnswers[currentQuestionIndex].choice ? '\u2705' : '\u274C'}</h3>
                {questions[currentQuestionIndex].question.split('\n').map((paragraph, index) => (
                    <Typography
                    key={index}
                    variant="body1"
                    style={{
                        fontFamily: 'Montserrat',
                        fontSize: '18px',
                        fontWeight: '600',
                        color: '#2f3036',
                        marginTop: '2rem',
                        marginBottom: '2rem',

                        width: '100%', 
                    }}
                    >
                    {paragraph}
                    </Typography>
                ))}
                <div style= {{width: 'min-content'}}>
                    <ul style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marinTop: '2rem' }}>
                        <Button
                        style={{ 
                            backgroundColor: (questions[currentQuestionIndex].correctAnswer === questions[currentQuestionIndex].answer1 )  ? '#1BD47D' : (userAnswers[currentQuestionIndex].choice === questions[currentQuestionIndex].answer1) ? 'red' :  'white',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            borderRadius: '30px',   
                            width: '100%', 
                            marginBottom: '1rem',
                            color: 'black'
                            }}
                        >
                        {questions[currentQuestionIndex].answer1}
                        </Button>
                        <Button
                        style={{ 
                            backgroundColor: (questions[currentQuestionIndex].correctAnswer === questions[currentQuestionIndex].answer2 )  ? '#1BD47D' : (userAnswers[currentQuestionIndex].choice === questions[currentQuestionIndex].answer2) ? 'red' :  'white',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            borderRadius: '30px',    
                            width: '100%', 
                            marginBottom: '1rem',
                            color: 'black'

                            }}                
                            >
                        {questions[currentQuestionIndex].answer2}
                        </Button>
                        <Button
                        style={{ 
                            backgroundColor: (questions[currentQuestionIndex].correctAnswer === questions[currentQuestionIndex].answer3 )  ? '#1BD47D' : (userAnswers[currentQuestionIndex].choice === questions[currentQuestionIndex].answer3) ? 'red' :  'white',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            borderRadius: '30px',   
                            width: '100%', 
                            marginBottom: '1rem',
                            color: 'black'
                            }}              
                            >
                        {questions[currentQuestionIndex].answer3}
                        </Button>
                        <Button
                        style={{ 
                            backgroundColor: (questions[currentQuestionIndex].correctAnswer === questions[currentQuestionIndex].answer4 )  ? '#1BD47D' : (userAnswers[currentQuestionIndex].choice === questions[currentQuestionIndex].answer4) ? 'red' :  'white',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            borderRadius: '30px',
                            width: '100%', 
                            marginBottom: '1rem',
                            color: 'black'


                            }}                
                            >
                        {questions[currentQuestionIndex].answer4}
                        </Button>
                        <Button
                        style={{ 
                            backgroundColor: (questions[currentQuestionIndex].correctAnswer === questions[currentQuestionIndex].answer5)  ? '#1BD47D' : (userAnswers[currentQuestionIndex].choice === questions[currentQuestionIndex].answer5) ? 'red' :  'white',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            borderRadius: '30px', 
                            width: '100%', 
                            marginBottom: '1rem',
                            color: 'black'
                            }}               
                            >
                        {questions[currentQuestionIndex].answer5}
                        </Button>
                    </ul>
                    </div>
                    <Typography
                    variant="body1"
                    style={{
                        fontFamily: 'Montserrat',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#2f3036',
                        marginTop: '2rem',
                        marginBottom: '1rem',
                        textDecoration: 'underline',
                        width: '100%', 
                    }}
                    >
                    Explanation
                    </Typography>
                    {questions[currentQuestionIndex].explanation.split('\n').map((paragraph, index) => (
                    <Typography
                    key={index}
                    variant="body1"
                    style={{
                        fontFamily: 'Montserrat',
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#2f3036',
                        marginTop: '1rem',
                        marginBottom: '2rem',

                        width: '100%', 
                    }}
                    >
                    {paragraph}
                    </Typography>
                ))}
                    <div style= {{ display: 'flex', justifyContent: 'space-between', marginTop: '3rem'}}>
                    <Button
                        onClick={handlePreviousQuestion}
                        style={{
                        backgroundColor: 'black',
                        borderRadius: '30px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        color: 'white',
                        }}
                    >
                        Question précédente
                    </Button>
                    <Button
                        disabled= {selectedAnswer === null  && userAnswers[currentQuestionIndex].choice === ''}
                        onClick={(questions[currentQuestionIndex].questionNumber === '12' ? () => {window.location = '/profile'} : handleNextQuestion)}
                        style={{
                        backgroundColor: selectedAnswer === null && userAnswers[currentQuestionIndex].choice === '' ? 'lightgrey' : 'rgb(105, 108, 255)',
                        borderRadius: '30px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        color: 'white',
                        }}
                    >
                    {questions[currentQuestionIndex].questionNumber === '12' ? 'Fermer le test' : 'Question suivante'}
                    </Button>
                    </div>
                </div>
            ) : (
                <div>
                <p>Chargement des questions...</p>
                </div>
            )}
            </Container>
        </div>
      ) 
      :
      (
        <div>
            <Container maxWidth='md' style={{
                display: 'flex',
                justifyContent: 'center', 
                alignItems: 'center', 
                marginTop: '2rem',
                backgroundColor: '#DFD4ED',
                borderRadius: '30px',
                padding: '40px'}}>
            {questions.length > 0 && currentQuestionIndex < questions.length ? (
                <div>
                <h3>Question {questions[currentQuestionIndex].questionNumber} / 12</h3>
                {questions[currentQuestionIndex].question.split('\n').map((paragraph, index) => (
                    <Typography
                    key={index}
                    variant="body1"
                    style={{
                        fontFamily: 'Montserrat',
                        fontSize: '18px',
                        fontWeight: '600',
                        color: '#2f3036',
                        marginTop: '2rem',
                        marginBottom: '2rem',

                        width: '100%', 
                    }}
                    >
                    {paragraph}
                    </Typography>
                ))}
                <div style= {{width: 'min-content'}}>
                    <ul style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marinTop: '2rem' }}>
                        <Button
                        onClick={() => handleAnswerClick(questions[currentQuestionIndex].answer1)}
                        style={{ 
                            backgroundColor: (selectedAnswer === questions[currentQuestionIndex].answer1 || (userAnswers[currentQuestionIndex].choice === questions[currentQuestionIndex].answer1 ))  ? '#d4edeb' : 'white',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            borderRadius: '30px',   
                            width: '100%', 
                            marginBottom: '1rem',
                            color: 'black'
                            }}
                        >
                        {questions[currentQuestionIndex].answer1}
                        </Button>
                        <Button
                        onClick={() => handleAnswerClick(questions[currentQuestionIndex].answer2)}
                        style={{ 
                            backgroundColor: (selectedAnswer === questions[currentQuestionIndex].answer2 || (userAnswers[currentQuestionIndex].choice === questions[currentQuestionIndex].answer2 ))  ? '#d4edeb' : 'white',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            borderRadius: '30px',    
                            width: '100%', 
                            marginBottom: '1rem',
                            color: 'black'

                            }}                
                            >
                        {questions[currentQuestionIndex].answer2}
                        </Button>
                        <Button
                        onClick={() => handleAnswerClick(questions[currentQuestionIndex].answer3)}
                        style={{ 
                            backgroundColor: (selectedAnswer === questions[currentQuestionIndex].answer3 || (userAnswers[currentQuestionIndex].choice === questions[currentQuestionIndex].answer3 ))  ? '#d4edeb' : 'white',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            borderRadius: '30px',   
                            width: '100%', 
                            marginBottom: '1rem',
                            color: 'black'
                            }}              
                            >
                        {questions[currentQuestionIndex].answer3}
                        </Button>
                        <Button
                        onClick={() => handleAnswerClick(questions[currentQuestionIndex].answer4)}
                        style={{ 
                            backgroundColor: (selectedAnswer === questions[currentQuestionIndex].answer4 || (userAnswers[currentQuestionIndex].choice === questions[currentQuestionIndex].answer4 ))  ? '#d4edeb' : 'white',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            borderRadius: '30px',
                            width: '100%', 
                            marginBottom: '1rem',
                            color: 'black'


                            }}                
                            >
                        {questions[currentQuestionIndex].answer4}
                        </Button>
                        <Button
                        onClick={() => handleAnswerClick(questions[currentQuestionIndex].answer5)}
                        style={{ 
                            backgroundColor: (selectedAnswer === questions[currentQuestionIndex].answer5 || (userAnswers[currentQuestionIndex].choice === questions[currentQuestionIndex].answer5 ))  ? '#d4edeb' : 'white',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            borderRadius: '30px', 
                            width: '100%', 
                            marginBottom: '1rem',
                            color: 'black'
                            }}               
                            >
                        {questions[currentQuestionIndex].answer5}
                        </Button>
                    </ul>
                    </div>
                    <div style= {{ display: 'flex', justifyContent: 'space-between', marginTop: '3rem'}}>
                    <Button
                        onClick={handlePreviousQuestion}
                        style={{
                        backgroundColor: 'black',
                        borderRadius: '30px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        color: 'white',
                        }}
                    >
                        Question précédente
                    </Button>
                    <Button
                        disabled= {selectedAnswer === null  && userAnswers[currentQuestionIndex].choice === ''}
                        onClick={questions[currentQuestionIndex].questionNumber === '12' ? handleSubmit : handleNextQuestion}
                        style={{
                        backgroundColor: selectedAnswer === null && userAnswers[currentQuestionIndex].choice === '' ? 'lightgrey' : 'rgb(105, 108, 255)',
                        borderRadius: '30px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        color: 'white',
                        }}
                    >
                    {questions[currentQuestionIndex].questionNumber === '12' ? 'Terminer le test' : 'Question suivante'}
                    </Button>
                    </div>
                </div>
            ) : (
                <div>
                <p>Chargement des questions...</p>
                </div>
            )}
            </Container>
        </div>
      )}
    </div>
  );
};

export default ConsignesTest2;
