import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS  
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const PratiqueReligion = () => {

    

    useEffect(() => {
        document.title = 'Pratique de la Religion | Tsahal Connection';
    }, []);
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Annexes</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Pratique de la religion</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '3rem', fontWeight: '500', color: '#2f3036'}}>
                    Tsahal est une armée où toutes les religions sont représentées et respectées. Depuis sa création, l'armée israélienne a mis en place de nombreuses aides aux soldats et soldates souhaitant pratiquer la religion juive et ce, peut-importe le degré de pratique.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        Le Chabbat et les Fêtes Juives 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem'}}>
                        Le Chabat et les fêtes juives sont considérés comme des jours de repos dans l’armée pendant lesquels tout travail est interrompu, en dehors des activités opérationnelles nécessaires à la sécurité du pays (Pikouakh Nefesh).
                        Pendant ces jours-là, les soldats de Tsahal ne seront pas amenés à transgresser le Chabat ou la fête par un quelconque travail, étude, cours ou entrainement et activité de routine. 
                        Néanmoins, il est permis aux soldats de participer aux activités de maintenance de leur base militaire, que cela soit d’ordre sécuritaire, intervention, ou même de cuisine. 
                        La permission du Chabat/fête devra être accordée aux soldats (avec famille) afin qu’ils arrivent jusqu’à 2 heures avant le début du Chabbat/fête, et durera pendant tout le Chabat/fête, jusqu’à une heure après la sortie officielle de la fête.  Les <Link to='/hayal-boded' style={{color: '#1398aa'}}>soldats seuls</Link> arriveront chez eux pas plus tard qu'a 10h.
                        Pendant le Chabat ou les fêtes, les soldats seront libérés de toute activité (excepté celles qui sont primordiales à la sureté de l’état) au moins une heure avant l’entrée de la fête.                
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        La Chacherout
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                        D’après les lois militaires en vigueur, toute nourriture proposée aux soldats dans Tsahal est casher. 
                        L’autorité militaire compétente en matière de régulation de la cashrout pour la nourriture de l’armée est le Rav principal de l’armée, ou tout autre Rav que celui-ci aurait nommé et chargé de cette responsabilité. 
                        Dans un besoin de respect de la cacherout, chaque base devra respecter la différence entre ustensiles lait et ustensile viande, et ce dans chaque cuisine ou réfectoire militaire. 
                        La Cacherout de la nourriture et les processus de préparation de celle-ci, dans la semaine comme le Chabat, sont fixés et régulés par le Rabbinat militaire. 
                        Les soldats demandant à bénéficier de nourriture avec une cashrout spécifique s’adresseront au Rav de leur unité.<br />
                        Ci dessous, la Cacherout des aliments : 
                        <ul>
                            <li> Tous les produits alimentaires secs <strong>– Mehadrin</strong>.</li>
                            <li> Fruits et légumes <strong>– Mehadrin</strong>.</li>
                            <li> Légumes à feuilles <strong>– une croissance sans insectes</strong>.</li>
                            <li> Lait et ses produits <strong>– Beit Yossef à l’étranger, grand rabbinat d’Israël</strong>.</li>
                            <li> Bœuf <strong>– Mehadrin</strong>.</li>
                            <li> Viande transformée (kebab / hamburger) <strong>– casher ordinaire</strong>.</li>
                            <li> Viande de poulet <strong>– rabbinat casher Kiryat Shmona / Haïfa Mehadrin</strong>.</li>
                        </ul>
                    </Typography>
                    <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#EBEAFF', borderColor: '#EBEAFF', marginBottom: '3rem'}}>
                            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', color: '#2f3036', marginBottom: '1rem', fontWeight: '600', padding: '20px'}}>
                            ✅ Il existe dans Tsahal des cacherout différentes. Pour les soldats qui s’intéressent à la nourriture casher Badatz et pour les soldats des parcours ultra-orthodoxes, de la nourriture casher ultra-orthodoxe Badatz sera fournie.
                            </Typography> 
                    </div>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                    Prières et Synagogues 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem'}}>
                    D’après les lois militaires, il est obligatoire d’accorder à chaque soldat en formulant le souhait, un temps spécifique pour les prières du jour. Tous les soldats ont le droit de prier au sein des synagogues de leurs bases.<br />
                    Chaque soldate religieuse est amenée à choisir le jour de son enrôlement l’une des options suivantes : deux jupes et un pantalon, trois jupes ou bien trois pantalons. Une soldate n’ayant pas demandé de jupe lors de son enrôlement et qui souhaiterait en recevoir une pendant son service devra s’adresser au Rav de son unité afin d’obtenir une autorisation de port de la jupe Alef pour raison religieuse. Le processus est le même pour la jupe Beth. Le temps moyen du processus est d’une semaine. 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                    Ressources talmudiques
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                        Selon les lois militaires en vigueur, chaque soldat(e) religieux(se) est autorisé à participer à au moins trois journées d’étude organisées par le rabbinat militaire de Tsahal, et ce, au sein d’une période de trois mois. Tous les soldats religieux seront invités par le Rav militaire de leur base, et plusieurs programmes d’étude talmudique y seront proposés.<br />
                        Chaque soldat religieux souhaitant recevoir du matériel d’étude en matière de Torah est autorisé à contacter le Rav de son unité ou bien la branche de traitement des soldates religieuses. Pour les soldates religieuses souhaitant avoir accès à des ressources et moyens d’études complémentaires, des groupes d’études téléphoniques sont proposés et mis en place par chaque responsable d’accompagnement des soldates, présent dans toute autorité et branche militaire dans le but de veiller au bon respect de tous les droits religieux des soldates en service dans Tsahal. La ligne ouverte du Rabbinat militaire est également disponible pour toute demande ou information complémentaire. 
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        Filles religieuses
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem'}}>
                        Les filles religieuses souhaitant servir dans Tsahal peuvent bénéficier d’un accompagnement via l’organisme Aluma et le projet Beit-Hel qui travaille en étroite collaboration avec l’unité Meitav de Tsahal. Chaque année, un salon pour les filles religieuses est organisé dans lequel sont présentes de nombreuses soldates religieuses provenant de tout type de corps de Tsahal. Il est possible que plusieurs filles religieuses fassent une demande afin de servir dans la même unité pendant leurs services. Cette demande doit se faire via <Link to='https://www.aluma.org.il' style={{color: '#1398aa'}} target="_blank">Aluma</Link> mais la décision finale reviendra à Tsahal.<br />
                        Selon les consignes de la branche des ressources humaines militaires, une soldate religieuse enrôlée au sein d’un cours ou de classes militaires devra y être avec au moins une autre soldate religieuse. Cette obligation n’est plus en vigueur pendant le service opérationnel ou la suite de la formation.
                    </Typography>
                </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
};

export default PratiqueReligion;
