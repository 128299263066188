import React, { useEffect, useState } from 'react';
import Navbar from './../components/Features/Navbar';
const Contact = () => {


  useEffect(() => {
    document.title = 'Contact | Tsahal Connection';
  }, []);


  return (
    <div>
      <Navbar />
        <iframe
          src='https://tc-crm.com/formulaire-soldat'
          style={{ width: '100%', minHeight: '100vh', border: 'none', marginTop: '1em' }}
        ></iframe>
    </div>
  );
};

export default Contact;
